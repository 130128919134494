import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import {
  Row,
  Col,
  Label
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { history } from "../..";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import Loading from "../../app/components/loading/Loading";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import _ from "lodash";
import moment from "moment";
import { checkPermission, salesOrderStatusColor, returnPriceWithCurrency, newSpaceBeforeCapitalLetter, getBranchId, arraySpliceInAllValue, comparePathForPagination, comparePathForSetParams, paymentStatusColor, returnAdvancedSearchText, returnSubBrandList } from "../../app/common/function/function";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import { DateRangeInterface } from "../../app/models/common";
import CustomerSearchModal from "../CustomerPurchase/Modal/CustomerSearchModal";
import MyButton from "../../app/components/form/MyButton";
import TableActionButton from "../../app/components/table/TableActionButton";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import { PaginationRequestBody } from "../../app/models/pagination";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import { SubBrandObject } from "../../app/models/brand";
import GeneralDateRange from "../../app/components/form/GeneralDateRange";

const CustomerSalesOrder = () => {
  const intl = useIntl();
  //Use Store
  const { customerSalesOrderStore, customerStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, prevPath, windowSize, setErrorMessage, setSuccessMessage } = commonStore;
  const { customerSalesOrderList, customerSalesOrderPaginationParams, getCustomerSalesOrder, setCustomerSalesOrderPaginationParams } = customerSalesOrderStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const { getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedSalesOrderStatus, setSelectedSalesOrderStatus] = useState<string>(AllStatus);
  const [selectedSalesOrderStatusId, setSelectedSalesOrderStatusId] = useState<number | string | undefined>(" ");
  const [salesOrderStatusList, setSalesOrderStatusList] = useState<StaticReferenceDataObject[]>([]);
  const AllSubBrand = `${intl.formatMessage({ id: "AllSubBrand" })}`;
  const [selectedSubBrandName, setSelectedSubBrandName] = useState(AllSubBrand);
  const [selectedSubBrandId, setSelectedSubBrandId] = useState(" ");
  const [subBrandListFinal, setSubBrandListFinal] = useState<SubBrandObject[]>([]);
  const allCustomer = `${intl.formatMessage({ id: "AllCustomer" })}`;
  const [selectedCustomer, setSelectedCustomer] = useState<string>(allCustomer);
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState<string>(AllStatus);
  const [selectedPaymentStatusId, setSelectedPaymentStatusId] = useState<number | string | undefined>(" ");
  const [paymentStatusList, setPaymentStatusList] = useState<StaticReferenceDataObject[]>([]);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>({fromDate: "", toDate: ""});
  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [blnAdvancedSearch, setBlnAdvancedSearch] = useState(false);
  const [customerSearchModal, setCustomerSearchModal] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const pageSize = Constants.defaultPageSize;
  const [triggerSearch, setTriggerSearch] = useState(0);
  const blnPermissionManageCustomer = checkPermission([PermissionConstants.ManageCustomer]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "asc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      customerSalesOrderNo: "",
      tinNo: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  let customerSalesOrderRequestBody: PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    salesOrderStatus: selectedSalesOrderStatusId,
    branchId: blnAdvancedSearch ? "" : getBranchId(),
    customerId: selectedCustomerId,
    customerName: selectedCustomer,
    salesOrderStatusName: selectedSalesOrderStatus,
    customerSalesOrderNo: validation.values.customerSalesOrderNo,
    paymentStatus: selectedPaymentStatusId,
    paymentStatusName: selectedPaymentStatus,
    subBrandName: selectedSubBrandName,
    subBrandId: selectedSubBrandId,
    tinNo: validation.values.tinNo,
    ...selectedDateRange && {dateRange: selectedDateRange}
  }

  useEffect(() => {
    async function fetchCustomerSalesOrderListAPI() {
      setLocalLoading(true)
      if (returnSubBrandList().length > 0) {
        setSubBrandListFinal(arraySpliceInAllValue(returnSubBrandList(), AllSubBrand, false, "name", "id"));
      }
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.customerSalesOrder)
      if (blnBackNavigation) {
        if (customerSalesOrderPaginationParams) {
          setCurrentPage(customerSalesOrderPaginationParams.pageNumber);
          setSelectedDateRange(customerSalesOrderPaginationParams.dateRange || {fromDate: "", toDate: ""});
          setSelectedSalesOrderStatus(customerSalesOrderPaginationParams.salesOrderStatusName || "");
          setSelectedSalesOrderStatusId(customerSalesOrderPaginationParams.salesOrderStatus);
          setSelectedCustomer(customerSalesOrderPaginationParams.customerName || "");
          setSelectedCustomerId(customerSalesOrderPaginationParams.customerId || "");
          setSelectedPaymentStatus(customerSalesOrderPaginationParams.paymentStatusName || "");
          setSelectedPaymentStatusId(customerSalesOrderPaginationParams.paymentStatus);
          setSelectedSubBrandName(customerSalesOrderPaginationParams.subBrandName || AllSubBrand);
          setSelectedSubBrandId(customerSalesOrderPaginationParams.subBrandId || " ");
          validation.setValues((values) => ({
            ...values,
            customerSalesOrderNo: customerSalesOrderPaginationParams.customerSalesOrderNo || "",
            tinNo: customerSalesOrderPaginationParams.tinNo || ""
          }))
          customerSalesOrderRequestBody = customerSalesOrderPaginationParams;
        }
      }
      else {
        setCustomerSalesOrderPaginationParams(undefined);
      }
      let aryAPI: any = [
        fetchCustomerSalesOrderAPI(false),
        getStaticReferenceWithType(Constants.salesOrderStatus, AllStatus),
        getStaticReferenceWithType(Constants.paymentStatus, AllStatus),
      ];
      let resultAPI = await Promise.all(aryAPI);
      setSalesOrderStatusList(resultAPI[1]);
      setPaymentStatusList(resultAPI[2]);
      setLocalLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageCustomerSalesOrder], true)) {
      return;
    }

    fetchCustomerSalesOrderListAPI();

    return (() => {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.customerSalesOrder)
        if (!blnSetParams) {
          setCustomerSalesOrderPaginationParams(undefined);
        }
      }
    })
  }, [])

  useEffect(() => {
    if ((selectedSalesOrderStatusId || selectedSalesOrderStatusId === 0) && !initialCallAPI) {
      fetchCustomerSalesOrderAPI(true);
    }
  }, [selectedSalesOrderStatusId, triggerSearch, selectedSubBrandId, selectedDateRange])

  useEffect(() => {
    if (!initialCallAPI) {
      fetchCustomerSalesOrderAPI(true);
    }
  }, [selectedCustomerId])

  useEffect(() => {
    if ((selectedPaymentStatusId || selectedPaymentStatusId === 0) && !initialCallAPI) {
      fetchCustomerSalesOrderAPI(true);
    }
  }, [selectedPaymentStatusId])

  async function fetchCustomerSalesOrderAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getCustomerSalesOrder(customerSalesOrderRequestBody)
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickSelect = (row) => {
    setSelectedCustomer(row.preferredName);
    setSelectedCustomerId(row.id);
    setDisplayCustomerDropdown(false);
    setBlnAdvancedSearch(true);

    setTimeout(() => {
      setDisplayCustomerDropdown(true);
      setCustomerSearchModal(false);
    }, 100)
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value)=> value+1)
  }

  const onSelectDateRange = (objDateRange: any) => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setSelectedDateRange(objDateRange);
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(getBranchId(), {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
    }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  //Table Content UI
  const CustomerSalesOrderColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "customerSalesOrderNo",
      text: intl.formatMessage({ id: "CustomerSalesOrderNo" }).toUpperCase(),
      style: {
        width: "15%"
      },
    },
    {
      dataField: "orderDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.orderDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.orderDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{`${row.branchName}`}</div>
      ),
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        blnPermissionManageCustomer
        ?
        <div className="text-center">
          <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
            <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
          </Link>
        </div>
        :
        <div className="text-center">{row.customerName}</div>
      ),
    },
    {
      dataField: "tinDisplayName",
      text: intl.formatMessage({ id: "TinNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{`${row.tinDisplayName || Constants.emptyDataV2}`}</div>
      ),
    },
    {
      dataField: "totalAmount",
      text: intl.formatMessage({ id: "Total" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{returnPriceWithCurrency(row.totalAmount)}</div>
      ),
    },
    {
      dataField: "totalPayment",
      text: intl.formatMessage({ id: "TotalPayment" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{returnPriceWithCurrency(row.totalPayment)}</div>
      ),
    },
    {
      dataField: "balance",
      text: intl.formatMessage({ id: "Balance" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">
          {
            returnPriceWithCurrency(row.balance)
          }
        </div>
      ),
    },
    {
      dataField: "subBrandName",
      text: intl.formatMessage({ id: "SubBrand" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      hidden: returnSubBrandList().length === 0,
      formatter: (cellContent, row) => (
        <div className="text-center">
          {newSpaceBeforeCapitalLetter(row.subBrandName)}
        </div>
      )
    },
    {
      dataField: "paymentStatus",
      text: intl.formatMessage({ id: "PaymentStatus" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.paymentStatus}
          statusColorFunction={paymentStatusColor}/>
      )
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={salesOrderStatusColor}/>
      )
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              to={`/${RoutesList.customerSalesOrder}/view/${row.id}`}
              type="view"/>
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "CustomerSalesOrder" }) })}
        title={intl.formatMessage({ id: "CustomerSalesOrder" })}>
        {
          !initialCallAPI
          &&
          <>
            <Row>
              {
                subBrandListFinal.length > 0
                &&
                <Col xl={'4'} md={'6'} xs={'12'}>
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "SubBrand" })}
                    name="subBrandName"
                    options={subBrandListFinal}
                    initialLabel={selectedSubBrandName}
                    initialValue={selectedSubBrandId}
                    labelField="name"
                    valueField="id"
                    disabled={localLoading || loading}
                    setFieldLabel={setSelectedSubBrandName}
                    setFieldValue={setSelectedSubBrandId}
                    onChange={(value) => {
                      setCurrentPage(1);
                      setErrorMessage("");
                      setSuccessMessage("");
                    }}
                    validationRequired={true}
                  />
                </Col>
              }
              <Col xl={'4'} md={'6'} xs={'12'}>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "CustomerSalesOrder" }) })}
                  name="status"
                  options={salesOrderStatusList}
                  blnValueWithNewSpace={true}
                  labelField="displayValue"
                  valueField="key"
                  initialLabel={selectedSalesOrderStatus}
                  initialValue={selectedSalesOrderStatusId}
                  setFieldLabel={setSelectedSalesOrderStatus}
                  setFieldValue={setSelectedSalesOrderStatusId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                  onChange={() => {
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                />
              </Col>
              <Col xl={'4'} md={'6'} xs={'12'}>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "Payment" }) })}
                  name="paymentStatus"
                  options={paymentStatusList}
                  blnValueWithNewSpace={true}
                  labelField="displayValue"
                  valueField="key"
                  initialLabel={selectedPaymentStatus}
                  initialValue={selectedPaymentStatusId}
                  setFieldLabel={setSelectedPaymentStatus}
                  setFieldValue={setSelectedPaymentStatusId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                  onChange={() => {
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xl={'4'} md={'6'} xs={'12'}>
                <GeneralInput
                  title={intl.formatMessage({ id: "CustomerSalesOrderNo" })}
                  name="customerSalesOrderNo"
                  type="text"
                  disabled={localLoading || loading}
                  validation={validation}
                  placeholder={intl.formatMessage({ id: "EnterCustomerSalesOrderNo" })}
                  onBlurFunction={() => {
                    onClickSearch()
                  }}
                />
              </Col>
              <Col xl={'4'} md={'6'} xs={'12'}>
                {
                  displayCustomerDropdown &&
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "Customer" })}
                    name="customer"
                    placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                    options={customerDropdownList}
                    labelField="preferredName"
                    valueField="id"
                    initialLabel={selectedCustomer}
                    initialValue={selectedCustomerId}
                    setFieldLabel={setSelectedCustomer}
                    setFieldValue={setSelectedCustomerId}
                    disabled={localLoading || loading}
                    onChange={() => {
                      clearCustomerDropdownList();
                      setCurrentPage(1);
                      setErrorMessage("");
                      setSuccessMessage("");
                      setBlnAdvancedSearch(false);
                    }}
                    blnSupportCustomSearch={true}
                    onChangeCustomSearch={(value)=> {
                      fetchCustomerDropdown(value);
                    }}
                    onMenuOpenFunction={()=> {
                      clearCustomerDropdownList();
                      setLoadingCustomer(false);
                    }}
                    loadingCustomSearch={loadingCustomer}
                    setLoadingCustomSearch={setLoadingCustomer}
                  />
                }
              </Col>
              <Col xl={'4'} md={'6'} xs={'12'}>
                <MyButton
                  type="button"
                  class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-12"}`}
                  content={intl.formatMessage({ id: returnAdvancedSearchText() }).toUpperCase()}
                  loading={localLoading || loading}
                  disable={localLoading || loading}
                  onClick={() => { setCustomerSearchModal(true) }}
                />
              </Col>
            </Row>
            <Row>
              <Col xl={'4'} md={'6'} xs={'12'}>
                <GeneralInput
                  title={intl.formatMessage({ id: "TinNo" })}
                  name="tinNo"
                  type="text"
                  disabled={localLoading || loading}
                  validation={validation}
                  placeholder={intl.formatMessage({ id: "EnterTinNo" })}
                  onBlurFunction={() => {
                    onClickSearch()
                  }}
                />
              </Col>
              <Col xl={'4'} md={'6'} xs={'12'}>
                <GeneralDateRange
                  title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "SubmissionDate"})})}
                  name={"DateRange"}
                  disabled={localLoading || loading}
                  validationRequired={false}
                  onChangeFunction={onSelectDateRange}
                  initialValue={selectedDateRange} />
              </Col>
            </Row>
          </>
        }
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={CustomerSalesOrderColumns}
              data={customerSalesOrderList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "CustomerSalesOrder" }) })}
              objSorted={defaultSorted}
              salesOrderStatus={selectedSalesOrderStatusId}
              salesOrderStatusName={selectedSalesOrderStatus}
              branchId={blnAdvancedSearch ? "" : getBranchId()}
              customerId={selectedCustomerId}
              customerName={selectedCustomer}
              customerSalesOrderNo={validation.values.customerSalesOrderNo}
              paymentStatus={selectedPaymentStatusId}
              paymentStatusName={selectedPaymentStatus}
              subBrandId={selectedSubBrandId}
              subBrandName={selectedSubBrandName}
              tinNo={validation.values.tinNo}
              dateRange={selectedDateRange}
              requestAPI={getCustomerSalesOrder} />
        }
      </ListViewLayout>
      {
        customerSearchModal
        &&
        <CustomerSearchModal
          blnShow={customerSearchModal}
          setModal={setCustomerSearchModal}
          blnHideOtherBranch={false}
          blnHideAccessGranted={true}
          title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "CustomerSearch" }) })}
          onSelectFunction={onClickSelect} />
      }
    </div>
  );
}

export default observer(CustomerSalesOrder);
