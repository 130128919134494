import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import {
  Row,
  Label
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { history } from "../..";
import { UnknownPaymentListObject } from '../../app/models/unknownPayment';
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import { useIntl } from "react-intl";
import { checkPermission, comparePathForPagination, comparePathForSetParams, getBranchId, getBranchName, getBranchUser, returnPriceWithCurrency, unknownPaymentStatusColor } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";
import { PaginationRequestBody } from "../../app/models/pagination";
import moment from "moment";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import { DateRangeInterface } from "../../app/models/common";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import GeneralDateRange from "../../app/components/form/GeneralDateRange";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import { StaticReferenceDataObject } from "../../app/models/staticReference";

const UnknownPayment = () => {
  const intl = useIntl();
  //Use Store
  const { unknownPaymentStore, branchStore, customerStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, setShowDeletePrompt, prevPath, setErrorMessage, setSuccessMessage } = commonStore;
  const { branchDropDownList, getBranchDropDown } = branchStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const { unknownPaymentList, unknownPaymentPaginationParams, getUnknownPayment, deleteUnknownPayment, setUnknownPaymentPaginationParams } = unknownPaymentStore;
  const { getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<UnknownPaymentListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>({ fromDate: "", toDate: "" });
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() ? getBranchName : "");
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() ? getBranchId() : "");
  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedUnknownPaymentStatus, setSelectedUnknownPaymentStatus] = useState<string>(AllStatus);
  const [selectedUnknownPaymentStatusId, setSelectedUnknownPaymentStatusId] = useState<number | string | undefined>(" ");
  const [unknownPaymentStatusList, setUnknownPaymentStatusList] = useState<StaticReferenceDataObject[]>([]);
  const blnPermissionCreateUnknownCustomerPayment =  checkPermission([PermissionConstants.CreateUnknownCustomerPayment]);
  const blnPermissionUpdateUnknownCustomerPayment =  checkPermission([PermissionConstants.UpdateUnknownCustomerPayment]);
  const blnPermissionDeleteUnknownCustomerPayment =  checkPermission([PermissionConstants.DeleteUnknownCustomerPayment]);
  const blnPermissionManageCustomer = checkPermission([PermissionConstants.ManageCustomer]);

  let unknownPaymentRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    customerBranchId: selectedBranchId,
    branchName: selectedBranchName,
    customerId: selectedCustomerId,
    customerName: selectedCustomer,
    paymentFromDate: selectedDateRange.fromDate,
    paymentToDate: selectedDateRange.toDate,
    status: selectedUnknownPaymentStatusId,
    statusName: selectedUnknownPaymentStatus
  }

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchUnknownPaymentAPI() {
      setLoading(true);
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.unknownPayment)
      if (blnBackNavigation) {
        if (unknownPaymentPaginationParams) {
          setCurrentPage(unknownPaymentPaginationParams.pageNumber)
          setSelectedBranchId(unknownPaymentPaginationParams.customerBranchId || "");
          setSelectedBranchName(unknownPaymentPaginationParams.branchName || "");
          setSelectedUnknownPaymentStatusId(unknownPaymentPaginationParams.status)
          setSelectedUnknownPaymentStatus(unknownPaymentPaginationParams.statusName || AllStatus)
          setSelectedCustomerId(unknownPaymentPaginationParams.customerId || "")
          setSelectedCustomer(unknownPaymentPaginationParams.customerName || "")
          setSelectedDateRange({fromDate: unknownPaymentPaginationParams.paymentFromDate || "", toDate: unknownPaymentPaginationParams.paymentToDate || ""})
          unknownPaymentRequestBody = unknownPaymentPaginationParams;
        }
      }
      else {
        setUnknownPaymentPaginationParams(undefined);
      }
      
      let aryAPI: any = [
        getUnknownPayment(unknownPaymentRequestBody),
        getBranchDropDown(),
        getStaticReferenceWithType(Constants.unknownPaymentStatus, AllStatus),
      ]
      let resultAPI = await Promise.all(aryAPI);
      setUnknownPaymentStatusList(resultAPI[2]);

      setLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageUnknownCustomerPayment], true)) {
      return;
    }

    fetchUnknownPaymentAPI();

    return (()=> {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.unknownPayment)
        if (!blnSetParams) {
          setUnknownPaymentPaginationParams(undefined);
        }
      }
    })
  }, [])

  useEffect(() => {
    if ((selectedUnknownPaymentStatusId || selectedUnknownPaymentStatusId === 0) && !initialCallAPI) 
    {
      fetchUnknownPaymenAPI(true);
    }
  }, [selectedDateRange, selectedUnknownPaymentStatusId]);

  useEffect(() => {
    if (!initialCallAPI && displayCustomerDropdown) {
      fetchUnknownPaymenAPI(true);
    }
  }, [selectedCustomerId])

  useEffect(() => {
    async function getCustomerDropdownFunction () {
      setDisplayCustomerDropdown(false);
      setSelectedCustomer("");
      setSelectedCustomerId("");
      setTimeout(()=> {
        setDisplayCustomerDropdown(true);
      }, 100)
    }

    if (!initialCallAPI) {
      getCustomerDropdownFunction();
      fetchUnknownPaymenAPI(true, Constants.emptyData);
    }
  }, [selectedBranchId])

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  const onClickDelete = async (row: UnknownPaymentListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  async function fetchUnknownPaymenAPI(blnDropDown?: boolean, customerId?: string) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getUnknownPayment({
      ...unknownPaymentRequestBody,
      ...customerId === Constants.emptyData && { customerId: "", customerName: "" },
    })
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(selectedBranchId, {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
    }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  //Table Content UI
  const UnknownPaymentColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "paymentDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.paymentDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.paymentDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "customerBranchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{`${row.customerBranchName || Constants.emptyData}`}</div>
      ),
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionManageCustomer && row.customerId
            ?
            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.customerName || Constants.emptyData}</div>
          }          
        </>
      )
    },
    {
      dataField: "paymentMethodName",
      text: intl.formatMessage({ id: "PaymentMethod" }).toUpperCase()
    },
    {
      dataField: "paymentMethodOptionId",
      text: intl.formatMessage({ id: "PaymentInfo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {row.cardType ? `${row.cardType} | ${row.optionName} | ${row.last4CardDigitNo}` : row.optionName ? `${row.optionName}` : Constants.emptyData}       
        </>
      )
    },
    // {
    //   dataField: "transactionNo",
    //   text: `${intl.formatMessage({ id: "TransactionNo" })}/${intl.formatMessage({ id: "ApprovalCode" })}`.toUpperCase(),
    //   formatter: (cellContent, row) => (
    //     <>
    //       {row.transactionNo || Constants.emptyData}       
    //     </>
    //   )
    // },
    {
      dataField: "orReferenceNo",
      text: intl.formatMessage({ id: "ORReferenceNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.orReferenceNo || Constants.emptyData}    
        </div>
      )
    },
    {
      dataField: "receivedAmount",
      text: intl.formatMessage({ id: "Amount" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">
          {returnPriceWithCurrency(row.receivedAmount)}    
        </div>
      )
    },
    {
      dataField: "utilizedAmount",
      text: intl.formatMessage({ id: "Utilized" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">
          {returnPriceWithCurrency(row.utilizedAmount)}    
        </div>
      )
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={unknownPaymentStatusColor}/>
      )
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      hidden: !checkPermission([PermissionConstants.ManageUnknownCustomerPayment, PermissionConstants.UpdateUnknownCustomerPayment, PermissionConstants.DeleteUnknownCustomerPayment]),
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            {
              row.status !== Constants.pendingAssign
              &&
              <TableActionButton 
                to={`/${RoutesList.unknownPayment}/view/${row.id}`}
                type="view"/>
            }
            {
              blnPermissionUpdateUnknownCustomerPayment
              &&
              row.status !== Constants.knockOff
              &&
              row.utilizedAmount === 0
              &&
              <TableActionButton 
                to={`/${RoutesList.unknownPayment}/edit/${row.id}`}
                type="edit"/>
            }
            {
              blnPermissionDeleteUnknownCustomerPayment
              &&
              row.status !== Constants.knockOff
              &&
              row.utilizedAmount === 0
              &&
              <TableActionButton 
                type="delete"
                onClick={() => onClickDelete(row)}/>
            }
          </div>
        </>
      ),
    },
  ];
  
  const onSelectDateRange = (objDateRange: any) => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setSelectedDateRange(objDateRange);
  }
  
  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "UnknownPayment" }) })}
        title={intl.formatMessage({ id: "UnknownPayment" })}
        addButton={
          blnPermissionCreateUnknownCustomerPayment
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "UnknownPayment" }) })}
            onClick={() => { history.push(`/${RoutesList.unknownPayment}/add`) }}
            linkTo={`/${RoutesList.unknownPayment}/add`}
            disable={loading} />
        }>
        {
          !initialCallAPI
          &&
          <Row>
            <DoubleColumnRowParent>
              <GeneralDateRange
                title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "PaymentDate"})})}
                name={"DateRange"}
                disabled={localLoading || loading}
                initialValue={selectedDateRange}
                onChangeFunction={onSelectDateRange} />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Status" })}
                name="unknownPaymentStatus"
                options={unknownPaymentStatusList}
                blnValueWithNewSpace={true}
                labelField="displayValue"
                valueField="key"
                initialLabel={selectedUnknownPaymentStatus}
                initialValue={selectedUnknownPaymentStatusId}
                setFieldLabel={setSelectedUnknownPaymentStatus}
                setFieldValue={setSelectedUnknownPaymentStatusId}
                disabled={localLoading || loading}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
                validationRequired={true}
              />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Branch" })}
                name="branchName"
                options={branchDropDownList}
                labelField={"name"}
                valueField={"id"}
                initialLabel={selectedBranchName}
                initialValue={selectedBranchId}
                setFieldLabel={setSelectedBranchName}
                setFieldValue={setSelectedBranchId}
                disabled={localLoading || loading || getBranchUser()}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              {
                displayCustomerDropdown 
                &&
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Customer" })}
                  name="customer"
                  placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                  options={customerDropdownList}
                  labelField="preferredName"
                  valueField="id"
                  initialLabel={selectedCustomer}
                  initialValue={selectedCustomerId}
                  setFieldLabel={setSelectedCustomer}
                  setFieldValue={setSelectedCustomerId}
                  disabled={localLoading || loading || !selectedBranchId}
                  onChange={() => {
                    clearCustomerDropdownList();
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                  blnSupportCustomSearch={true}
                  onChangeCustomSearch={(value)=> {
                    fetchCustomerDropdown(value);
                  }}
                  onMenuOpenFunction={()=> {
                    clearCustomerDropdownList();
                    setLoadingCustomer(false);
                  }}
                  loadingCustomSearch={loadingCustomer}
                  setLoadingCustomSearch={setLoadingCustomer}
                />
              }
            </DoubleColumnRowParent>
          </Row>
        }
        <PaginationTable
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          totalItem={totalItem}
          tableColumns={UnknownPaymentColumns}
          data={unknownPaymentList}
          emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "UnknownPayment" }) })}
          objSorted={defaultSorted}
          customerBranchId={selectedBranchId}
          branchName={selectedBranchName}
          customerId={selectedCustomerId}
          customerName={selectedCustomer}
          paymentFromDate={selectedDateRange.fromDate}
          paymentToDate={selectedDateRange.toDate}
          status={selectedUnknownPaymentStatusId}
          statusName={selectedUnknownPaymentStatus}
          requestAPI={getUnknownPayment} />
        <DeleteModal
          displayName={""}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteUnknownPayment(selectedRow?.id || "", "");
            let currentPageTemp = currentPage;
            if (unknownPaymentList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getUnknownPayment({ pageNumber: currentPageTemp, pageSize: pageSize })
            setLoading(false);
          }} />
      </ListViewLayout>
    </div>
  );
}

export default observer(UnknownPayment);
