import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../breadCrumb/BreadCrumb';
import MetaTags from "react-meta-tags";
import { breadCrumbObject } from '../../models/common';
import { Constants } from '../../constants/Constants';
import ArrowTopRightBoldBoxOutlineIcon from 'mdi-react/ArrowTopRightBoldBoxOutlineIcon';
import InformationIcon from 'mdi-react/InformationIcon';

interface Props {
  title: string;
  breadCrumbList?: breadCrumbObject[];
  metaTitle: string;
  children: any;
  addButton?: any;
  addButtonXL?: string;
  hyperlink?: string;
  blnSupportStickyHeader?: boolean;
}

export default function ListViewLayout(props: Props) {
  return (
    <div className={props.blnSupportStickyHeader ? "page-content-sticky-header display-grid" : "page-content"}>
      <MetaTags>
        <title>{props.metaTitle}</title>
      </MetaTags>
      <Container fluid>
        <Row className="margin-bottom-4">
          <Col xl={props.addButtonXL ? 12-Number(props.addButtonXL) : props.title.length > 13 ? "9" : "10"}>
            <div className="h4">
              {props.title}
              {
                props.hyperlink 
                && 
                // <ArrowTopRightBoldBoxOutlineIcon 
                <InformationIcon
                  className="margin-left-4 pointer-clickable" 
                  size={18} 
                  color={Constants.tabBlue}
                  onClick={()=> {
                    window.open(`${props.hyperlink}`)
                  }}/>
              }
            </div>
          </Col>
          <Col xl={props.addButtonXL ? props.addButtonXL : props.title.length > 13  ? "3" : "2"} className="flex-right">
            {props.addButton}
          </Col>
        </Row>
        <BreadCrumb 
          breadCrumbList={props.breadCrumbList} 
          activeBreadCrumbTitle={props.title}/>
        {/* {
          ((errorMessage || successMessage) && !loading)
          &&
          <AlertMessage 
            errorMessage={errorMessage}
            successMessage={successMessage}/>
        } */}
        <div >{props.children}</div>
      </Container>
    </div>
  )
}
