import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { checkPermission, getBranchId, getBranchUser, getInventoryUser, IncludesLocationPathName, newSpaceBeforeCapitalLetter, stockRequestStatusColor } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Card, CardBody, Table, Form } from "reactstrap";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyButton from "../../app/components/form/MyButton";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import { useFormik } from "formik";
import * as Yup from "yup";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import { history } from "../..";
import ActionPromptModal from "../../app/components/modal/ActionPromptModal";
import CancelModal from "../../app/components/modal/CancelModal";
import SubdirectoryArrowRightIcon from 'mdi-react/SubdirectoryArrowRightIcon';
import { burgerMenu } from "../../app/models/common";

const StockRequestViewDetail = () => {
  //Use Params
  const { id } = useParams();
  //Use INTL
  const intl = useIntl();
  //Use Store
  const { stockRequestStore, commonStore } = useStore();
  const { errorMessage, successMessage, loading, setErrorMessage, setSuccessMessage, setLoading, setShowCancelPrompt } = commonStore;
  const { stockRequestDetail, setStockRequestDetail, getStockRequestWithId, updateStockRequest } = stockRequestStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view") || IncludesLocationPathName("print");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "StockRequest" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "StockRequest" }), urlPath: RoutesList.stockRequest }];
  const [recreateStockRequestModal, setRecreateStockRequestModal] = useState(false);
  const [burgerMenuListContent, setBurgerMenuListContent] = useState<burgerMenu[]>([]);
  const [viewType, setViewType] = useState(!getInventoryUser() ? Constants.fullVersion : Constants.supplyChainVersion);

  const stockRequestFullDetail = stockRequestDetail || {
    id: "",
    stockRequestNo: "",
    requestedDate: "",
    completedDate: "",
    year: "",
    month: "",
    fromSupplierName: "",
    requestType: "",
    requestBranchId: getBranchId(),
    fromBranchId: undefined,
    fromBranchName: "",
    requestBranchName: "",
    note: "",
    stockRequestDetails: [],
    status: "",
    workflowAction: "Save",
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: stockRequestDetail || {
      stockRequestNo: "",
      requestedDate: "",
      completedDate: "",
      requestType: "",
      requestBranchId: getBranchId(),
      fromBranchId: undefined,
      fromBranchName: "",
      requestBranchName: "",
      note: "",
      stockRequestDetails: [],
      status: "",
      workflowAction: "Save",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      try {
        if (stockRequestFullDetail.status === Constants.pendingApproval || stockRequestFullDetail.status === Constants.processing) {
          await updateStockRequest(valuesTemp);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = true;
  const blnHQRequestType = (validation.values.requestType !== "" && validation.values.requestType === Constants.hq);
  const blnPendingApproval = stockRequestFullDetail.status === Constants.pendingApproval;
  const blnProcessing = stockRequestFullDetail.status === Constants.processing;
  const blnCancelled = stockRequestFullDetail.status === Constants.cancelled;
  const blnPermissionUpdateStockRequest = checkPermission([PermissionConstants.UpdateStockRequest])
  const blnPermissionReviewStockRequest = checkPermission([PermissionConstants.ReviewStockRequest])
  const blnPermissionCreateStockRequest = checkPermission([PermissionConstants.CreateStockRequest]);
  const blnPermissionManageProductRedemptionNote = checkPermission([PermissionConstants.ManageProductRedemptionNote]);
  const blnIsRequestBranchUser = stockRequestFullDetail.requestBranchId == getBranchId();
  const blnIsFromBranchUser = stockRequestFullDetail.fromBranchId == getBranchId();
  const blnStockTransferType = stockRequestFullDetail.requestType === Constants.stockTransfer;

  async function fetchStockRequestDetailAPI() {
    setLoading(true);
    if (id && !addAction) {
      await getStockRequestWithId(id);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!checkPermission([PermissionConstants.ManageStockRequest], true)) {
      return;
    }
    
    fetchStockRequestDetailAPI();

    let indexViewAs = _.findIndex(burgerMenuListContent, {label: `${intl.formatMessage({ id: "ViewAs" })}`})
    if (indexViewAs < 0) {
      burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "ViewAs" })}`, 
        onFunction: () => {},
        blnSubTitle: true
      })
    }

    let indexViewFullVersion = _.findIndex(burgerMenuListContent, {label: `${intl.formatMessage({ id: "ViewFullVersion" })}`})
      if (indexViewFullVersion < 0) {
        burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "ViewFullVersion" })}`, 
          onFunction: () => {
            setViewType(Constants.fullVersion);
          },
          blnSubView: true
        })
      }

      let indexViewSupplyChainVersion = _.findIndex(burgerMenuListContent, {label: `${intl.formatMessage({ id: "ViewSupplyChainVersion" })}`})
      if (indexViewSupplyChainVersion < 0) {
        burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "ViewSupplyChainVersion" })}`, 
          onFunction: () => { 
            setViewType(Constants.supplyChainVersion)
          },
          blnSubView: true
        })
      }

    return (() => {
      if (id && !addAction) {
        setStockRequestDetail(undefined);
      }
    })
  }, []);


  const displayHeaderRowData = (label, value) => {
    return (
      <Row>
        <h6 className="m-0 text-muted">
          {label}
        </h6>
        <h6 className="text-muted">
          {value}
        </h6>
      </Row>
    )
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        blnSupportStickyHeader={true}
        blnShowPrint={true}
        burgerMenuList={burgerMenuListContent}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className=""
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <div>
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <div className="invoice-title">
                        <Col>
                          <div className="logo-lg" style={{ lineHeight: '20px', marginBottom: '16px' }}>
                            <span style={{ fontSize: Constants.documentTitleFontSize, fontWeight: 'bold', lineHeight: '20px' }}>
                              {intl.formatMessage({ id: "StockRequest" })}
                            </span>
                          </div>
                        </Col>
                        <Row>
                          <Col>
                            {displayHeaderRowData(`${intl.formatMessage({ id: "StockRequestNo" })}:`, stockRequestFullDetail.stockRequestNo)}
                            {displayHeaderRowData(`${intl.formatMessage({ id: "From" })}:`, `${blnHQRequestType ? stockRequestFullDetail.fromSupplierName : stockRequestFullDetail.fromBranchName} ${blnHQRequestType ? "" : `(${intl.formatMessage({ id: "Branch" })})`}`)}
                            {displayHeaderRowData(`${intl.formatMessage({ id: blnStockTransferType ? "Year" : "RequestDate" })}:`, blnStockTransferType ? stockRequestFullDetail.year : moment(stockRequestFullDetail.requestedDate).format(Constants.displayDateFormat))}
                            {displayHeaderRowData(`${intl.formatMessage({ id: blnStockTransferType ? "Month" : "RequestTime" })}:`, blnStockTransferType ? stockRequestFullDetail.month : moment(stockRequestFullDetail.requestedDate).format(Constants.displayTimeFormat))}
                            {displayHeaderRowData(`${intl.formatMessage({ id: "RequestType" })}:`, newSpaceBeforeCapitalLetter(stockRequestFullDetail.requestType))}
                          </Col>
                          <Col>
                            <br/>
                            <br/>
                            {displayHeaderRowData(`${intl.formatMessage({ id: "To" })}:`, `${stockRequestFullDetail.requestBranchName}`)}
                            {displayHeaderRowData(`${intl.formatMessage({ id: "CompletedDate" })}:`, stockRequestFullDetail.completedDate !== Constants.invalidDate ? moment(stockRequestFullDetail.completedDate).format(Constants.displayDateFormat) : Constants.emptyData)}
                            {displayHeaderRowData(`${intl.formatMessage({ id: "CompletedTime" })}:`, stockRequestFullDetail.completedDate !== Constants.invalidDate ? moment(stockRequestFullDetail.completedDate).format(Constants.displayTimeFormat) : Constants.emptyData)}
                          </Col>
                        </Row>
                      </div>
                      <hr/>
                      <address style={{ lineHeight: '20px', marginTop: '20px' }}>
                        <strong>{intl.formatMessage({ id: "Status" })}:</strong>
                        <br />
                        <div style={{ marginTop: '8px' }}>
                          <GeneralStatus
                            status={stockRequestFullDetail.status}
                            statusColorFunction={stockRequestStatusColor}
                            blnDisableCenter/>
                        </div>
                      </address>
                      <div className="py-2 mt-3">
                        <h3 className="font-size-15 font-weight-bold">
                          {intl.formatMessage({ id: "Product(s)" })}
                        </h3>
                      </div>
                      <div className="">
                        <Table className="table-fixed" style={{ width: '100%' }}>
                          <thead className="table-light bootstrap-table-sticky-header" id="bootstrap-table-sticky-header">
                            <tr>
                              <th className="">{intl.formatMessage({ id: "No." })}</th>
                              <th className="">{intl.formatMessage({ id: "ProductName" })}</th>
                              <th className="text-center">{intl.formatMessage({ id: "CurrentQty" })}</th>
                              <th className="text-center">{intl.formatMessage({ id: "ProcessingQty" })}</th>
                              {
                                !getBranchUser()
                                &&
                                <>
                                  <th className="text-center">{intl.formatMessage({ id: "MinimumThreshold" })}</th>
                                  <th className="text-center">{intl.formatMessage({ id: "MaximumThreshold" })}</th>
                                </>
                              }
                              <th className="text-center">{intl.formatMessage({ id: "UOMAndQuantity" })}</th>
                              {
                                viewType === Constants.fullVersion
                                &&
                                <>
                                  <th className="text-center">{intl.formatMessage({ id: "OrderQty" })}</th>
                                  <th className="text-center">{intl.formatMessage({ id: "TotalOrderQtyUnit" })}</th>
                                </>
                              }
                              <th className="text-center">{intl.formatMessage({ id: "ApprovedQty" })}</th>
                              <th className="text-center">{intl.formatMessage({ id: "TotalApprovedQtyUnit" })}</th>
                              <th className="">{intl.formatMessage({ id: "Remarks" })}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              stockRequestFullDetail.stockRequestDetails.map((item, key) => (
                                <Fragment key={`fragment_${key}`}>
                                  <tr key={key} className={`${item.isExceedMinStockLevel ? "table-row-exceed-min-stock-level": ""}`}>
                                    <td className="">{`${key+1}. `}</td>
                                    <td className="">{item.productName}</td>
                                    <td className="text-center">{item.currentQuantity}</td>
                                    <td className="text-center">{item.processingOrderQuantity}</td>
                                    {
                                      !getBranchUser()
                                      &&
                                      <>
                                        <td className="text-center">{item.productMinimumThreshold}</td>
                                        <td className="text-center">{item.productMaximumThreshold}</td>
                                      </>
                                    }
                                    <td className="text-center">{`${item.measurementName}`}</td>
                                    {
                                      viewType === Constants.fullVersion
                                      &&
                                      <>
                                        <td className="text-center">{item.orderQuantity}</td>
                                        <td className="text-center">{item.totalOrderUnit}</td>
                                      </>
                                    }
                                    <td className="text-center">{item.approvedQuantity || Constants.emptyData}</td>
                                    <td className="text-center">{item.totalApprovedQuantity || Constants.emptyData}</td>
                                    <td className="">{item.remark || Constants.emptyData}</td>
                                  </tr>
                                  {
                                    item.productRedemptionStockRequestDetails
                                    ?
                                    item.productRedemptionStockRequestDetails.map((valueProductRedemptionStockRequestDetailTemp, index)=> (
                                      <tr key={`productRedemptionStockRequestDetail_${key}_${index}`} style={{backgroundColor: '#e6ede9'}}>
                                        <td className={blnPermissionManageProductRedemptionNote ? "pointer-clickable text-primary" : ""}
                                          onClick={()=> {
                                            if (blnPermissionManageProductRedemptionNote) {
                                              history.push(`/${RoutesList.productRedemptionNote}/view/${valueProductRedemptionStockRequestDetailTemp.productRedemptionNoteId}`)
                                            }
                                          }}>
                                          <SubdirectoryArrowRightIcon className="mb-1 margin-right-12" size={16}/>
                                          {valueProductRedemptionStockRequestDetailTemp.productRedemptionNoteNo || Constants.emptyData}
                                        </td>
                                        <td colSpan={5}/>
                                        <td className="text-center">
                                          {valueProductRedemptionStockRequestDetailTemp.quantity}
                                        </td>
                                        <td colSpan={2}/>
                                      </tr>
                                    ))
                                    :
                                    null
                                  }
                                </Fragment>
                              ))
                            }
                          </tbody>
                        </Table>
                      </div>
                      <div className={'py-2 mt-3'}>
                        <h3 className="font-size-15 font-weight-bold">
                          {intl.formatMessage({ id: "Note" })} :
                        </h3>
                      </div>
                      <GeneralTextArea
                        title=""
                        name="note"
                        className="margin-top-0"
                        row={5}
                        disabled={disabledFieldInput}
                        validation={validation}
                      />
                      <div className="mt-4" />
                      <div className="flex-direction-row d-print-none">
                        {
                          (!Boolean(successMessage) && blnPendingApproval && blnPermissionReviewStockRequest && ((blnIsRequestBranchUser && !getBranchUser() && blnHQRequestType) || (blnIsFromBranchUser && !blnHQRequestType)))
                          &&
                          <>
                            <MyButton
                              type="submit"
                              class="btn btn-success margin-right-8"
                              content={intl.formatMessage({ id: "Approve" })}
                              onClick={() => {
                                validation.setFieldValue("workflowAction", Constants.approve)
                              }}
                              disable={
                                validation.isSubmitting
                              }
                              loading={
                                validation.isSubmitting
                              }
                            />
                            <MyButton
                              type="submit"
                              class={`btn btn-danger margin-right-8`}
                              content={intl.formatMessage({ id: "Reject" })}
                              disable={
                                validation.isSubmitting
                              }
                              loading={
                                validation.isSubmitting
                              }
                              onClick={() => {
                                validation.setFieldValue("workflowAction", Constants.reject)
                              }}
                            />
                          </>
                        }
                        {
                          blnIsRequestBranchUser &&
                          blnPermissionCreateStockRequest &&
                          blnCancelled &&
                          !Boolean(successMessage) &&
                            <MyButton
                              type="button"
                              class="btn btn-warning margin-right-8"
                              style={{ width: '300px' }}
                              content={intl.formatMessage({ id: "RecreateStockRequest" }).toUpperCase()}
                              onClick={async () => { setRecreateStockRequestModal(true) }}
                            />
                        }
                        {
                          blnIsRequestBranchUser && !Boolean(successMessage) && blnPendingApproval && blnPermissionUpdateStockRequest && 
                          <MyButton
                            type="button"
                            class={`btn btn-warning margin-right-8`}
                            content={intl.formatMessage({ id: "Cancel" })}
                            disable={
                              validation.isSubmitting
                            }
                            loading={
                              validation.isSubmitting
                            }
                            onClick={() => {
                              setShowCancelPrompt(true);
                            }}
                          />
                        }
                        <GeneralSubmitAndCancelBtn
                          successMessage={successMessage}
                          className="width-100-percentage"
                          viewAction={false}
                          validation={validation}
                          hidePrimaryButton={true}
                          showPrint={true}
                          cancelButtonTitle={intl.formatMessage({ id: "Back" })}
                          cancelButtonBesidePrint={false}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Form>
        )}
        {
          recreateStockRequestModal
          &&
          <ActionPromptModal
            title={`${intl.formatMessage({ id: "RecreateStockRequestAlert" })}`}
            primaryActionTitle={`Yes`}
            showPrompt={recreateStockRequestModal}
            setShowPrompt={setRecreateStockRequestModal}
            onPrimaryClick={() => { 
                history.push({
                pathname: `/${RoutesList.stockRequest}/add`,
                state: { detail: { recreateStockRequestId: stockRequestFullDetail.id } }
              }); 
            }}
          />
        }
        <CancelModal
          onCancelClick={async () => {
            validation.setFieldValue("workflowAction", Constants.cancel);
            validation.submitForm();
          }} />
      </DetailViewLayout>
    </div>
  );
};

export default observer(StockRequestViewDetail);
