import {
  Modal,
  Label
} from "reactstrap";
import _ from 'lodash';
import { Constants } from "../../../app/constants/Constants";
import { isTablet } from "react-device-detect";
import PaginationTableWithoutApi from "../../../app/components/table/PaginationTableWithoutApi";
import AlertCircleOutlineIcon from 'mdi-react/AlertCircleOutlineIcon';
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { returnPendingSignOrderLinkPath } from "../../../app/common/function/function";
import moment from "moment";
import MyButton from "../../../app/components/form/MyButton";
import { RoutesList } from "../../../app/constants/RoutesList";
import { history } from "../../..";

interface Props {
  blnShow: boolean;
  weeklyPendingOrdersChart: any;
}

export default function CustomerOrderPendingSignModal(props: Props) {
  const intl = useIntl();
  const WeeklyPendingOrdersChartColumns = [
    {
      dataField: "documentNo",
      text: intl.formatMessage({ id: "DocumentNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <Link
          to={returnPendingSignOrderLinkPath(row.pendingSignType, row.id, row.documentNo)}
          target={Constants.blank}>
          {row.documentNo}
        </Link>
      )
    },
    {
      dataField: "documentDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.documentDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.documentDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "customerPreferredName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase()
    },
    // {
    //   dataField: "branchName",
    //   text: intl.formatMessage({ id: "Branch" }).toUpperCase()
    // },
    // {
    //   dataField: "details",
    //   text: intl.formatMessage({ id: "Detail" }).toUpperCase()
    // }
  ]

  const hideModal = () => {
    history.push(`/${RoutesList.dashboard}`)
  }

  return (
    <Modal
      isOpen={props.blnShow}
      style={{...isTablet && {width: '100%', maxWidth: '75%'}}}
      size={"lg"}
      centered>
      <div className="standard-layout">
        <button
          type="button"
          onClick={() => {
            hideModal();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="flex-align-items-center-without-height margin-top-28 mb-4">
          <AlertCircleOutlineIcon size={"3em"} color={"orange"} className={"margin-right-8"}/>
          <Label className="mb-0">{intl.formatMessage({ id: "POSPendingOrderFound" })}</Label>
        </div>
        <PaginationTableWithoutApi 
          title={""}
          options={props.weeklyPendingOrdersChart}
          columns={WeeklyPendingOrdersChartColumns}
          pageSize={5}
          keyField={"id"}
          blnDisplayForMainListing={true}
        />
        <MyButton
          type="button"
          content={intl.formatMessage({ id: "BackToDashoard" }).toUpperCase()}
          onClick={()=> {hideModal()}}
          class="btn btn-primary mt-4 flex-right"
        />
      </div>
    </Modal>
  )
}