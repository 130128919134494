import React, { useEffect, useState } from 'react'
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import {
  Row,
  Col,
  Label
} from "reactstrap";
import Loading from "../../components/loading/Loading";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import { Constants } from '../../constants/Constants';
import { DateRangeInterface } from '../../models/common';
import { useIntl } from "react-intl";
import _ from 'lodash';
import { OrderByPropertiesObject } from '../../models/pagination';
import { isObjectEmpty } from '../../common/function/function';

interface Props {
  currentPage: number;
  setCurrentPage: any;
  pageSize: number;
  totalItem: number;
  tableColumns: any;
  expandRow?: any;
  rowStyle?: any;
  data: any;
  emptyTextDisplay: string;
  objSorted: any;
  isModal?: boolean;
  blnDisabledScroll?: boolean;
  requestAPI: Function;
  rowEvents?: Function;
  selectRow?: any;
  selectedYear?: number;
  selectedMonth? :number;
  localLoading?: boolean;
  setLocalLoading?: Function;
  loadingClassName?: string;
  blnSupportColumnFilter?: boolean;
  tableColumnsFromStore?: any[];
  setTableColumnsFromStore?: Function;
  paymentStatus?: number | string;
  deliverOrderStatus?: number | string;
  stockRequestStatus?: number | string;
  purchaseOrderStatus?: number | string;
  purchasePaymentMethod?: number | string;
  purchaseOrderType?: number | string;
  inboundNoticeStatus?: number | string;
  gRNStatus?: number | string;
  purchaseDeliverOrderStatus?: number | string;
  stockAdjustmentStatus?: number | string;
  branchId?: string;
  productId?: string;
  keyField?: string;
  bordered?: boolean;
  striped?: boolean;
  consultantId?: string;
  warehouseId?: string;
  stockInOutStatus?: number | string;
  customerId?: number | string;
  hideSearchBar?: boolean;
  title?: string;
  notificationType?: number | string;
  to?: string;
  stockTakeStatus?: number | string;
  entityId?: number | string;
  entityName?: number | string;
  auditType?: number | string;
  searchType?: number | string;
  searchValue?: number | string;
  sourceId?: string;
  dateRange?: DateRangeInterface;
  salesOrderStatus?: number | string;
  exchangeToCreditStatus?: number | string;
  exchangeToCreditStatusName?: number | string;
  leadStatus?: number | string;
  marcomConsultantId?: number | string;
  creditAdjustmentType?: number | string;
  creditAdjustmentTypeName?: number | string;
  appointmentStatus?: number | string;
  customerName?: number | string;
  customerMobileNo?: number | string;
  customerEmail?: number | string;
  employeeId?: number | string;
  employeeIds?: string[];
  leaveStatus?: number | string;
  leaveType?: number | string;
  paymentMethodId?: number | string;
  branchIds?: string[];
  consultantIds?: string[];
  consultants?: string[];
  customerIds?: string[];
  salesCategoryIds?: string[];
  productIds?: string[];
  serviceIds?: string[];
  packageIds?: string[];
  statuses?: string[];
  includeOtherSalesDetails?: boolean;
  days?: number;
  requestBranchId?: string;
  requestBranchName?: string;
  stockReceiveStatus?: number | string;
  stockRequestPurchaserType?: number | string;
  stockMovementType?: number | string;
  movementType?: number | string;
  businessEntityId?: number | string;
  expired?: boolean;
  paymentAdjustmentStatus?: number | string;
  customerPaymentAdjustmentNo?: number | string;
  customerSalesOrderNo?: string;
  promoCode?: number | string;
  customerProductReturnStatus?: number | string;
  stockReturnToHQStatus?: number | string;
  extendPackageStatus?: number | string;
  leadName?: string;
  leadMobileNo?: string;
  leadEmail?: string;
  optionType?: number | string;
  salesAdjustmentType?: number | string;
  salesAdjustmentTypes?: string[];
  commissionSchemeType?: number | string;
  salesOrderId?: string;
  salesOrderDetailId?: string;
  customerAccessRequestStatus?: number | string;
  customerNo?: string;
  originalBranchId?: number | string;
  isFilterClosedLeads?: boolean;
  isFilterExistingCustomer?: boolean;
  isFilterHQStaff?: boolean;
  productName?: string;
  packageName?: string;
  serviceName?: string;
  sKU?: number | string;
  salesOrderStatusName?: string;
  selectedMonthDisplay?: string;
  deliverOrderStatusName?: string;
  branchName?: string;
  customerProductReturnStatusName?: string;
  notificationTypeName?: string;
  isViewAll?: boolean;
  stockTakeStatusName?: string;
  stockRequestStatusName?: string;
  stockReceiveStatusName?: string;
  stockRequestPurchaserTypeName?: string;
  stockReturnToHQStatusName?: string;
  stockAdjustmentStatusName?: string;
  leaveStatusName?: string;
  employeeName?: string;
  leaveTypeName?: string;
  sourceName?: string;
  leadStatusName?: string;
  marcomConsultantName?: string;
  optionTypeName?: string;
  name?: string;
  isBranchStaff?: string | boolean;
  employeeNo?: string;
  leadId?: string;
  sourceType?: number | string;
  sourceTypeName?: string;
  serviceTreatmentIds?: string[];
  serviceTreatmentNames?: string[];
  contactNo?: string;
  mediaSourceId?: string;
  mediaSourceName?: string;
  appointmentDateRange?: DateRangeInterface;
  submissionDateRange?: DateRangeInterface;
  customerTransferRequestStatus?: number | string;
  toBranchId?: string;
  fromBranchId?: string;
  identityType?: number | string;
  identityTypeName?: string;
  identityNo?: string;
  customerTransferRequestStatusName?: string;
  status?: number | string;
  statusName?: string;
  appointmentStatusName?: string;
  appointmentStatusNames?: string[];
  leadNo?: string;
  productTypeName?: string;
  productType?: number | string;
  sourceTypes?: string[];
  sourceTypeNames?: string[];
  paymentStatusName?: string;
  madeById?: string;
  madeByName?: string;
  blnRemoveBackground?: boolean;
  stockRequestNo?: string;
  stockReceiveNo?: string;
  stockLevelStatus?: number | string;
  additionBonusType?: number | string;
  additionBonusTypeName?: string;
  positionId?: string;
  positionName?: string;
  virtualWarehouseStatus?: number | string;
  virtualWarehouseStatusName?: string;
  deliverOrderNo?: string;
  virtualWarehouseNo?: string;
  virtualWarehouseId?: string;
  productRedemptionStatus?: number | string;
  productRedemptionStatusName?: string;
  productRedemptionNo?: string;
  virtualWarehouseCreditExchangeStatus?: number | string;
  virtualWarehouseCreditExchangeStatusName?: string;
  virtualWarehouseCreditExchangeNo?: string;
  productRedemptionNoteReturnNo?: string;
  productRedemptionNoteReturnStatus?: number | string;
  productRedemptionNoteReturnStatusName?: string;
  subBrandName?: string;
  subBrandId?: string;
  subBrandIds?: string[];
  type?: number | string;
  types?: string[];
  referenceNo?: string;
  redemptionId?: string;
  batchJobId?: string;
  requestType?: number | string;
  requestTypeName?: string;
  fromSubmissionDate?: string;
  toSubmissionDate?: string;
  hasStockReceive?: boolean;
  customerNoOrName?: string;
  allBranchAccess?: boolean;
  salesOrderNo?: string;
  salesCategoryName?: string;
  invoiceNo?: string;
  detailItemName?: string;
  purchaseType?: number | string;
  purchaseTypeName?: string;
  brandName?: string;
  businessEntityName?: string;
  isActive?: boolean;
  packageId?: string;
  customerBranchId?: string;
  customerInvoiceNo?: string;
  categoryIds?: string[];
  productTypes?: string[];
  isFilterActiveStaff?: boolean;
  isIncludeCreditPayment?: boolean;
  isAppolousPayment?: boolean;
  orderByProperties?: OrderByPropertiesObject[];
  categoryId?: string;
  categoryName?: string;
  salesCategoryId?: string;
  subBranchId?: string;
  serviceTreatmentId?: string;
  serviceTreatmentName?: string;
  isOptionalProduct?: boolean;
  genderName?: string;
  gender?: number | string;
  raceId?: string;
  raceName?: string;
  isProductOnly?: boolean;
  showCompleted?: boolean;
  departmentId?: string;
  departmentName?: string;
  modelNumber?: string;
  consumptionType?: number | string;
  consumptionTypeName?: string;
  serialNumber?: string;
  machineId?: string;
  machineUsageType?: number | string;
  promotionCode?: string;
  closingDate?: string;
  groupCodeId?: string;
  groupCodeName?: string;
  isConfirmed?: boolean;
  purchaseBranchId?: string;
  salesOrderNos?: string;
  isExcludeProduct?: boolean;
  isIncludeEarnSales?: boolean;
  redemptionFromDate?: string;
  redemptionToDate?: string;
  salesOrderFromDate?: string;
  salesOrderToDate?: string;
  salesType?: number | string;
  cLType?: number | string;
  exchangeType?: number | string;
  joinedFromDate?: string;
  joinedToDate?: string;
  categoryType?: number | string;
  categoryTypeName?: string;
  paymentFromDate?: string;
  paymentToDate?: string;
  submissionFromDate?: string;
  submissionToDate?: string;
  changePaymentModeNo?: string;
  requestFromDate?: string;
  requestToDate?: string;
  preparedFromDate?: string;
  preparedToDate?: string;
  submissionDate?: string;
  firstAppointmentDateRange?: DateRangeInterface;
  lastAppointmentDateRange?: DateRangeInterface;
  isFirstAppointmentOnly?: boolean;
  lastVisitFromDate?: string;
  lastVisitToDate?: string;
  contractLiabilityNo?: string;
  currentBranchId?: string;
  creditType?: number | string;
  purchasedCreditId?: string;
  documentNo?: string;
  referenceDocumentNo?: string;
  documentId?: string;
  documentType?: string;
  fromDate?: string;
  toDate?: string;
  customerDeliverOrderNo?: string;
  redemptionCutOffDate?: string;
  tinNo?: string;
  stockTakeNo?: string;
}

interface pageObject {
  page: number;
}

export default observer(function PaginationTable(props: Props) {
  //Use Store
  const { commonStore } = useStore();
  const { loading, setLoading, successMessage, setSuccessMessage, setTotalItem } = commonStore
  const intl = useIntl();
  const [localTableColumns, setLocalTableColumns] = useState(props.tableColumnsFromStore ? props.tableColumnsFromStore.length > 0 ? props.tableColumnsFromStore : props.tableColumns : props.tableColumns);
  const blnLocalHost = window.location.hostname === "localhost"

  //Convert Search framework into SearchBar for naming.
  const { SearchBar } = Search;
  
  const pageSize = props.pageSize;

  useEffect(()=> {
    if (successMessage) {
      setTimeout(()=> {
        setSuccessMessage("");
      }, Constants.dismissSuccessMessage)
    }
  }, [successMessage])

  const pageOptions = {
    sizePerPage: pageSize,
    totalSize: props.totalItem, // replace later with size(orders),
    hideSizePerPage: true,
    pageListRenderer: props => PageListRenderer(),
  };

  const onPageChange = async (page, sizePerPage) => {
    if (props.currentPage !== page || blnLocalHost) {
      if (props.setLocalLoading) {
        props.setLocalLoading(true);
      } 
      else { 
        setLoading(true);
      }

      let objTemp : any = { pageNumber: page, pageSize: sizePerPage };
      if (props.consultantId) {
        objTemp = { ...objTemp, consultantId: props.consultantId}
      }
      if (props.branchId) {
        objTemp = { ...objTemp, branchId: props.branchId}
      }
      if (props.productId) {
        objTemp = { ...objTemp, productId: props.productId}
      }
      if (props.selectedYear) {
        objTemp = { ...objTemp, year: props.selectedYear}
      }
      if (props.selectedMonth) {
        objTemp = { ...objTemp, month: props.selectedMonth}
      }
      if (props.warehouseId) {
        objTemp = { ...objTemp, warehouseId: props.warehouseId}
      }
      if (props.paymentStatus || props.paymentStatus === 0) {
        objTemp = { ...objTemp, paymentStatus: props.paymentStatus}
      }
      if (props.deliverOrderStatus || props.deliverOrderStatus === 0) {
        objTemp = { ...objTemp, deliverOrderStatus: props.deliverOrderStatus}
      }
      if (props.stockRequestStatus || props.stockRequestStatus === 0) {
        objTemp = { ...objTemp, stockRequestStatus: props.stockRequestStatus}
      }
      if (props.purchaseOrderStatus || props.purchaseOrderStatus === 0) {
        objTemp = { ...objTemp, purchaseOrderStatus: props.purchaseOrderStatus}
      }
      if (props.purchaseOrderType || props.purchaseOrderType === 0) {
        objTemp = { ...objTemp, purchaseOrderType: props.purchaseOrderType}
      }
      if (props.purchasePaymentMethod || props.purchasePaymentMethod === 0) {
        objTemp = { ...objTemp, purchasePaymentMethod: props.purchasePaymentMethod}
      }
      if (props.inboundNoticeStatus || props.inboundNoticeStatus === 0) {
        objTemp = { ...objTemp, inboundNoticeStatus: props.inboundNoticeStatus}
      }
      if (props.gRNStatus || props.gRNStatus === 0) {
        objTemp = { ...objTemp, gRNStatus: props.gRNStatus}
      }
      if (props.purchaseDeliverOrderStatus || props.purchaseDeliverOrderStatus === 0) {
        objTemp = { ...objTemp, purchaseDeliverOrderStatus: props.purchaseDeliverOrderStatus}
      }
      if (props.stockAdjustmentStatus || props.stockAdjustmentStatus === 0) {
        objTemp = { ...objTemp, stockAdjustmentStatus: props.stockAdjustmentStatus}
      }
      if (props.stockInOutStatus || props.stockInOutStatus === 0) {
        objTemp = { ...objTemp, stockInOutStatus: props.stockInOutStatus}
      }
      if (props.notificationType || props.notificationType === 0) {
        objTemp = { ...objTemp, notificationType: props.notificationType}
      }
      if (props.exchangeToCreditStatus || props.exchangeToCreditStatus === 0) {
        objTemp = { ...objTemp, exchangeToCreditStatus: props.exchangeToCreditStatus}
      }
      if (props.to) {
        objTemp = { ...objTemp, to: props.to}
      }
      if (props.stockTakeStatus || props.stockTakeStatus === 0) {
        objTemp = { ...objTemp, stockTakeStatus: props.stockTakeStatus}
      }
      if (props.entityId) {
        objTemp = { ...objTemp, entityId: props.entityId}
      }
      if (props.entityName) {
        objTemp = { ...objTemp, entityName: props.entityName}
      }
      if (props.auditType || props.auditType === 0) {
        objTemp = { ...objTemp, auditType: props.auditType}
      }
      if (props.searchType || props.searchType === 0) {
        objTemp = { ...objTemp, searchType: props.searchType}
      }
      if (props.searchValue) {
        objTemp = { ...objTemp, searchValue: props.searchValue}
      }
      if (props.sourceId) {
        objTemp = { ...objTemp, sourceId: props.sourceId}
      }
      if (props.dateRange) {
        objTemp = { ...objTemp, dateRange: props.dateRange}
      }
      if (props.salesOrderStatus || props.salesOrderStatus === 0) {
        objTemp = { ...objTemp, salesOrderStatus: props.salesOrderStatus}
      }
      if (props.customerId) {
        objTemp = { ...objTemp, customerId: props.customerId}
      }
      if (props.leadStatus || props.leadStatus === 0) {
        objTemp = { ...objTemp, leadStatus: props.leadStatus}
      }
      if (props.marcomConsultantId) {
        objTemp = { ...objTemp, marcomConsultantId: props.marcomConsultantId}
      }
      if (props.creditAdjustmentType || props.creditAdjustmentType === 0) {
        objTemp = { ...objTemp, creditAdjustmentType: props.creditAdjustmentType}
      }
      if (props.creditAdjustmentTypeName) {
        objTemp = { ...objTemp, creditAdjustmentTypeName: props.creditAdjustmentTypeName}
      }
      if (props.appointmentStatus || props.appointmentStatus === 0) {
        objTemp = { ...objTemp, appointmentStatus: props.appointmentStatus}
      }
      if (props.customerName) {
        objTemp = { ...objTemp, customerName: props.customerName}
      } 
      if (props.customerMobileNo) {
        objTemp = { ...objTemp, customerMobileNo: props.customerMobileNo}
      } 
      if (props.customerEmail) {
        objTemp = { ...objTemp, customerEmail: props.customerEmail}
      } 
      if (props.employeeId) {
        objTemp = { ...objTemp, employeeId: props.employeeId}
      }
      if (props.employeeIds) {
        objTemp = { ...objTemp, employeeIds: props.employeeIds}
      }
      if (props.leaveStatus || props.leaveStatus === 0) {
        objTemp = { ...objTemp, leaveStatus: props.leaveStatus}
      }
      if (props.leaveType || props.leaveType === 0) {
        objTemp = { ...objTemp, leaveType: props.leaveType}
      }
      if (props.paymentMethodId) {
        objTemp = { ...objTemp, paymentMethodId: props.paymentMethodId}
      }
      if (props.branchIds) {
        objTemp = { ...objTemp, branchIds: props.branchIds}
      }
      if (props.consultantIds) {
        objTemp = { ...objTemp, consultantIds: props.consultantIds}
      }
      if (props.customerIds) {
        objTemp = { ...objTemp, customerIds: props.customerIds}
      }
      if (props.salesCategoryIds) {
        objTemp = { ...objTemp, salesCategoryIds: props.salesCategoryIds}
      }
      if (props.productIds) {
        objTemp = { ...objTemp, productIds: props.productIds}
      }
      if (props.serviceIds) {
        objTemp = { ...objTemp, serviceIds: props.serviceIds}
      }
      if (props.packageIds) {
        objTemp = { ...objTemp, packageIds: props.packageIds}
      }
      if (props.statuses) {
        objTemp = { ...objTemp, statuses: props.statuses}
      }
      if (props.includeOtherSalesDetails || props.includeOtherSalesDetails === false) {
        objTemp = { ...objTemp, includeOtherSalesDetails: props.includeOtherSalesDetails}
      }
      if (props.days || props.days === 0) {
        objTemp = { ...objTemp, days: props.days}
      }
      if (props.requestBranchId) {
        objTemp = { ...objTemp, requestBranchId: props.requestBranchId}
      }
      if (props.requestBranchName) {
        objTemp = { ...objTemp, requestBranchName: props.requestBranchName}
      }
      if (props.stockReceiveStatus || props.stockReceiveStatus === 0) {
        objTemp = { ...objTemp, stockReceiveStatus: props.stockReceiveStatus}
      }
      if (props.stockRequestPurchaserType || props.stockRequestPurchaserType === 0) {
        objTemp = { ...objTemp, stockRequestPurchaserType: props.stockRequestPurchaserType}
      }
      if (props.stockMovementType || props.stockMovementType === 0) {
        objTemp = { ...objTemp, stockMovementType: props.stockMovementType}
      }
      if (props.movementType || props.movementType === 0) {
        objTemp = { ...objTemp, movementType: props.movementType}
      }
      if (props.businessEntityId) {
        objTemp = { ...objTemp, businessEntityId: props.businessEntityId}
      }
      if (props.expired || props.expired === false) {
        objTemp = { ...objTemp, expired: props.expired}
      }
      if (props.paymentAdjustmentStatus || props.paymentAdjustmentStatus === 0) {
        objTemp = { ...objTemp, paymentAdjustmentStatus: props.paymentAdjustmentStatus}
      } 
      if (props.customerPaymentAdjustmentNo) {
        objTemp = { ...objTemp, customerPaymentAdjustmentNo: props.customerPaymentAdjustmentNo}
      }
      if (props.customerSalesOrderNo) {
        objTemp = { ...objTemp, customerSalesOrderNo: props.customerSalesOrderNo}
      }
      if (props.promoCode) {
        objTemp = { ...objTemp, promoCode: props.promoCode}
      }
      if (props.customerProductReturnStatus || props.customerProductReturnStatus === 0) {
        objTemp = { ...objTemp, customerProductReturnStatus: props.customerProductReturnStatus}
      }
      if (props.stockReturnToHQStatus || props.stockReturnToHQStatus === 0) {
        objTemp = { ...objTemp, stockReturnToHQStatus: props.stockReturnToHQStatus}
      }
      if(props.extendPackageStatus){
        objTemp = { ...objTemp, extendPackageStatus: props.extendPackageStatus}
      }
      if(props.leadName){
        objTemp = { ...objTemp, leadName: props.leadName}
      }
      if(props.leadMobileNo){
        objTemp = { ...objTemp, leadMobileNo: props.leadMobileNo}
      }
      if(props.leadEmail){
        objTemp = { ...objTemp, leadEmail: props.leadEmail}
      }
      if (props.optionType || props.optionType === 0) {
        objTemp = { ...objTemp, optionType: props.optionType}
      }
      if (props.salesAdjustmentType || props.salesAdjustmentType === 0) {
        objTemp = { ...objTemp, salesAdjustmentType: props.salesAdjustmentType}
      }
      if (props.salesAdjustmentTypes) {
        objTemp = { ...objTemp, salesAdjustmentTypes: props.salesAdjustmentTypes}
      }
      if (props.commissionSchemeType || props.commissionSchemeType === 0) {
        objTemp = { ...objTemp, commissionSchemeType: props.commissionSchemeType}
      }
      if(props.salesOrderId){
        objTemp = { ...objTemp, salesOrderId: props.salesOrderId}
      }
      if(props.salesOrderDetailId){
        objTemp = { ...objTemp, salesOrderDetailId: props.salesOrderDetailId}
      }
      if (props.customerAccessRequestStatus || props.customerAccessRequestStatus === 0) {
        objTemp = { ...objTemp, customerAccessRequestStatus: props.customerAccessRequestStatus}
      }
      if(props.customerNo){
        objTemp = { ...objTemp, customerNo: props.customerNo}
      }
      if (props.originalBranchId) {
        objTemp = { ...objTemp, originalBranchId: props.originalBranchId}
      }
      if (props.isFilterClosedLeads || props.isFilterClosedLeads === false) {
        objTemp = { ...objTemp, isFilterClosedLeads: props.isFilterClosedLeads}
      }
      if (props.isFilterExistingCustomer || props.isFilterExistingCustomer === false) {
        objTemp = { ...objTemp, isFilterExistingCustomer: props.isFilterExistingCustomer}
      }
      if (props.isFilterHQStaff || props.isFilterHQStaff === false) {
        objTemp = { ...objTemp, isFilterHQStaff: props.isFilterHQStaff}
      }
      if(props.productName){
        objTemp = { ...objTemp, productName: props.productName}
      }
      if(props.packageName){
        objTemp = { ...objTemp, packageName: props.packageName}
      }
      if(props.serviceName){
        objTemp = { ...objTemp, serviceName: props.serviceName}
      }
      if(props.sKU){
        objTemp = { ...objTemp, sKU: props.sKU}
      }
      if (props.salesOrderStatusName) {
        objTemp = { ...objTemp, salesOrderStatusName: props.salesOrderStatusName}
      }
      if (props.selectedMonthDisplay) {
        objTemp = { ...objTemp, selectedMonthDisplay: props.selectedMonthDisplay}
      }
      if (props.deliverOrderStatusName) {
        objTemp = { ...objTemp, deliverOrderStatusName: props.deliverOrderStatusName}
      }
      if (props.branchName) {
        objTemp = { ...objTemp, branchName: props.branchName}
      }
      if (props.customerProductReturnStatusName) {
        objTemp = { ...objTemp, customerProductReturnStatusName: props.customerProductReturnStatusName}
      }
      if (props.notificationTypeName) {
        objTemp = { ...objTemp, notificationTypeName: props.notificationTypeName}
      }
      if (props.isViewAll || props.isViewAll === false) {
        objTemp = { ...objTemp, isViewAll: props.isViewAll}
      }
      if (props.stockTakeStatusName) {
        objTemp = { ...objTemp, stockTakeStatusName: props.stockTakeStatusName}
      }
      if (props.stockRequestStatusName) {
        objTemp = { ...objTemp, stockRequestStatusName: props.stockRequestStatusName}
      }
      if (props.stockReceiveStatusName) {
        objTemp = { ...objTemp, stockReceiveStatusName: props.stockReceiveStatusName}
      }
      if (props.stockRequestPurchaserTypeName) {
        objTemp = { ...objTemp, stockRequestPurchaserTypeName: props.stockRequestPurchaserTypeName}
      }
      if (props.stockReturnToHQStatusName) {
        objTemp = { ...objTemp, stockReturnToHQStatusName: props.stockReturnToHQStatusName}
      }
      if (props.stockAdjustmentStatusName) {
        objTemp = { ...objTemp, stockAdjustmentStatusName: props.stockAdjustmentStatusName}
      }
      if (props.leaveStatusName) {
        objTemp = { ...objTemp, leaveStatusName: props.leaveStatusName}
      }
      if (props.employeeName) {
        objTemp = { ...objTemp, employeeName: props.employeeName}
      }
      if (props.leaveTypeName) {
        objTemp = { ...objTemp, leaveTypeName: props.leaveTypeName}
      }
      if (props.sourceName) {
        objTemp = { ...objTemp, sourceName: props.sourceName}
      }
      if (props.leadStatusName) {
        objTemp = { ...objTemp, leadStatusName: props.leadStatusName}
      }
      if (props.marcomConsultantName) {
        objTemp = { ...objTemp, marcomConsultantName: props.marcomConsultantName}
      }
      if (props.optionTypeName) {
        objTemp = { ...objTemp, optionTypeName: props.optionTypeName}
      }
      if (props.name) {
        objTemp = { ...objTemp, name: props.name}
      }
      if(props.isBranchStaff !== "" && (props.isBranchStaff || props.isBranchStaff === false) ){
        objTemp = { ...objTemp, isBranchStaff: props.isBranchStaff}
      }
      if (props.employeeNo) {
        objTemp = { ...objTemp, employeeNo: props.employeeNo}
      }
      if (props.leadId) {
        objTemp = { ...objTemp, leadId: props.leadId}
      }
      if (props.sourceType || props.sourceType === 0){
        objTemp = { ...objTemp, sourceType: props.sourceType}
      }
      if (props.sourceTypeName) {
        objTemp = { ...objTemp, sourceTypeName: props.sourceTypeName}
      }
      if (props.serviceTreatmentIds) {
        objTemp = { ...objTemp, serviceTreatmentIds: props.serviceTreatmentIds}
      }
      if (props.serviceTreatmentNames) {
        objTemp = { ...objTemp, serviceTreatmentNames: props.serviceTreatmentNames}
      }
      if (props.contactNo) {
        objTemp = { ...objTemp, contactNo: props.contactNo}
      }
      if (props.mediaSourceId) {
        objTemp = { ...objTemp, mediaSourceId: props.mediaSourceId}
      }
      if (props.mediaSourceName) {
        objTemp = { ...objTemp, mediaSourceName: props.mediaSourceName}
      }
      if (props.appointmentDateRange) {
        objTemp = { ...objTemp, appointmentDateRange: props.appointmentDateRange}
      }
      if (props.submissionDateRange) {
        objTemp = { ...objTemp, submissionDateRange: props.submissionDateRange}
      }
      if (props.customerTransferRequestStatus || props.customerTransferRequestStatus === 0){
        objTemp = { ...objTemp, customerTransferRequestStatus: props.customerTransferRequestStatus}
      }
      if (props.customerTransferRequestStatusName) {
        objTemp = { ...objTemp, customerTransferRequestStatusName: props.customerTransferRequestStatusName}
      }
      if (props.toBranchId) {
        objTemp = { ...objTemp, toBranchId: props.toBranchId}
      }
      if (props.fromBranchId) {
        objTemp = { ...objTemp, fromBranchId: props.fromBranchId}
      }
      if (props.identityType || props.identityType === 0) {
        objTemp = { ...objTemp, identityType: props.identityType}
      }
      if (props.identityTypeName) {
        objTemp = { ...objTemp, identityTypeName: props.identityTypeName}
      }
      if (props.identityNo) {
        objTemp = { ...objTemp, identityNo: props.identityNo}
      }
      if (props.appointmentStatusName) {
        objTemp = { ...objTemp, appointmentStatusName: props.appointmentStatusName}
      }
      if (props.appointmentStatusNames) {
        objTemp = { ...objTemp, appointmentStatusNames: props.appointmentStatusNames}
      }
      if (props.status || props.status === 0){
        objTemp = { ...objTemp, status: props.status}
      }
      if (props.statusName){
        objTemp = { ...objTemp, statusName: props.statusName}
      }
      if (props.leadNo) {
        objTemp = { ...objTemp, leadNo: props.leadNo}
      }
      if (props.productType || props.productType === 0) {
        objTemp = { ...objTemp, productType: props.productType}
      }
      if (props.productTypeName) {
        objTemp = { ...objTemp, productTypeName: props.productTypeName}
      }
      if (props.sourceTypes) {
        objTemp = { ...objTemp, sourceTypes: props.sourceTypes}
      }
      if (props.sourceTypeNames) {
        objTemp = { ...objTemp, sourceTypeNames: props.sourceTypeNames}
      }
      if (props.paymentStatusName) {
        objTemp = { ...objTemp, paymentStatusName: props.paymentStatusName}
      }
      if (props.madeById) {
        objTemp = { ...objTemp, madeById: props.madeById}
      }
      if (props.madeByName) {
        objTemp = { ...objTemp, madeByName: props.madeByName}
      }
      if (props.stockRequestNo) {
        objTemp = { ...objTemp, stockRequestNo: props.stockRequestNo}
      }
      if (props.stockReceiveNo) {
        objTemp = { ...objTemp, stockReceiveNo: props.stockReceiveNo}
      }
      if (props.stockLevelStatus || props.stockLevelStatus === 0) {
        objTemp = { ...objTemp, stockLevelStatus: props.stockLevelStatus}
      }
      if (props.additionBonusType || props.additionBonusType === 0) {
        objTemp = { ...objTemp, additionBonusType: props.additionBonusType}
      }
      if (props.additionBonusTypeName) {
        objTemp = { ...objTemp, additionBonusTypeName: props.additionBonusTypeName}
      }
      if (props.positionId) {
        objTemp = { ...objTemp, positionId: props.positionId}
      }
      if (props.positionName) {
        objTemp = { ...objTemp, positionName: props.positionName}
      }
      if (props.virtualWarehouseStatus || props.virtualWarehouseStatus === 0) {
        objTemp = { ...objTemp, virtualWarehouseStatus: props.virtualWarehouseStatus}
      }
      if (props.virtualWarehouseStatusName) {
        objTemp = { ...objTemp, virtualWarehouseStatusName: props.virtualWarehouseStatusName}
      }
      if (props.deliverOrderNo) {
        objTemp = { ...objTemp, deliverOrderNo: props.deliverOrderNo}
      }
      if (props.virtualWarehouseNo) {
        objTemp =  { ...objTemp, virtualWarehouseNo: props.virtualWarehouseNo}
      }
      if (props.virtualWarehouseId) {
        objTemp = { ...objTemp, virtualWarehouseId: props.virtualWarehouseId}
      }
      if (props.productRedemptionStatus) {
        objTemp = { ...objTemp, productRedemptionStatus: props.productRedemptionStatus}
      }
      if (props.productRedemptionStatusName) {
        objTemp = { ...objTemp, productRedemptionStatusName: props.productRedemptionStatusName}
      }
      if (props.productRedemptionNo) {
        objTemp = { ...objTemp, productRedemptionNo: props.productRedemptionNo}
      }
      if (props.virtualWarehouseCreditExchangeStatus) {
        objTemp = { ...objTemp, virtualWarehouseCreditExchangeStatus: props.virtualWarehouseCreditExchangeStatus}
      }
      if (props.virtualWarehouseCreditExchangeStatusName) {
        objTemp = { ...objTemp, virtualWarehouseCreditExchangeStatusName: props.virtualWarehouseCreditExchangeStatusName}
      }
      if (props.virtualWarehouseCreditExchangeNo) {
        objTemp = { ...objTemp, virtualWarehouseCreditExchangeNo: props.virtualWarehouseCreditExchangeNo}
      }
      if (props.productRedemptionNoteReturnNo) {
        objTemp = { ...objTemp, productRedemptionNoteReturnNo: props.productRedemptionNoteReturnNo}
      }
      if (props.productRedemptionNoteReturnStatus || props.productRedemptionNoteReturnStatus === 0) {
        objTemp = { ...objTemp, productRedemptionNoteReturnStatus: props.productRedemptionNoteReturnStatus}
      }
      if (props.productRedemptionNoteReturnStatusName) {
        objTemp = { ...objTemp, productRedemptionNoteReturnStatusName: props.productRedemptionNoteReturnStatusName}
      }
      if (props.subBrandId) {
        objTemp = { ...objTemp, subBrandId: props.subBrandId}
      }
      if (props.subBrandName) {
        objTemp = { ...objTemp, subBrandName: props.subBrandName}
      }
      if (props.subBrandIds) {
        objTemp = { ...objTemp, subBrandIds: props.subBrandIds}
      }
      if (props.type || props.type === 0) {
        objTemp = { ...objTemp, type: props.type}
	    }
      if (props.types) {
        objTemp = { ...objTemp, types: props.types}
      }
      if (props.referenceNo) {
        objTemp = { ...objTemp, referenceNo: props.referenceNo}
      }
      if (props.redemptionId) {
        objTemp = { ...objTemp, redemptionId: props.redemptionId}
      }
      if (props.batchJobId) {
        objTemp = { ...objTemp, batchJobId: props.batchJobId}
      }
      if (props.requestType || props.requestType === 0) {
        objTemp = { ...objTemp, requestType: props.requestType}
      }
      if (props.requestTypeName) {
        objTemp = { ...objTemp, requestTypeName: props.requestTypeName}
      }
      if (props.fromSubmissionDate) {
        objTemp = { ...objTemp, fromSubmissionDate: props.fromSubmissionDate}
      }
      if (props.toSubmissionDate) {
        objTemp = { ...objTemp, toSubmissionDate: props.toSubmissionDate}
      }
      if (props.hasStockReceive || props.hasStockReceive === false) {
        objTemp = { ...objTemp, hasStockReceive: props.hasStockReceive}
      }
      if (props.customerNoOrName) {
        objTemp = { ...objTemp, customerNoOrName: props.customerNoOrName}
      }
      if (props.allBranchAccess) {
        objTemp = { ...objTemp, allBranchAccess: props.allBranchAccess}
      }
      if (props.salesOrderNo) {
        objTemp = { ...objTemp, salesOrderNo: props.salesOrderNo}
      }
      if (props.salesCategoryName) {
        objTemp = { ...objTemp, salesCategoryName: props.salesCategoryName}
      }
      if (props.invoiceNo) {
        objTemp = { ...objTemp, invoiceNo: props.invoiceNo}
      }
      if (props.detailItemName) {
        objTemp = { ...objTemp, detailItemName: props.detailItemName}
      }
      if (props.purchaseType || props.purchaseType === 0) {
        objTemp = { ...objTemp, purchaseType: props.purchaseType}
      }
      if (props.purchaseTypeName) {
        objTemp = { ...objTemp, purchaseTypeName: props.purchaseTypeName}
      }
      if(props.brandName){
        objTemp = { ...objTemp, brandName: props.brandName}
      }
      if(props.businessEntityName){
        objTemp = { ...objTemp, businessEntityName: props.businessEntityName}
      }
      if (props.isActive || props.isActive === false) {
        objTemp = { ...objTemp, isActive: props.isActive}
      }
      if (props.packageId) {
        objTemp = { ...objTemp, packageId: props.packageId}
      }
      if (props.customerBranchId) {
        objTemp = { ...objTemp, customerBranchId: props.customerBranchId}
	  }
      if (props.modelNumber) {
        objTemp = { ...objTemp, modelNumber: props.modelNumber}
      }
      if (props.consumptionType || props.consumptionType === 0) {
        objTemp = { ...objTemp, consumptionType: props.consumptionType}
      }
      if (props.consumptionTypeName) {
        objTemp = { ...objTemp, consumptionTypeName: props.consumptionTypeName}
      }
      if (props.serialNumber) {
        objTemp = { ...objTemp, serialNumber: props.serialNumber}
      }
      if (props.machineId) {
        objTemp = { ...objTemp, machineId: props.machineId}
      }
      if (props.machineUsageType || props.machineUsageType === 0) {
        objTemp = { ...objTemp, machineUsageType: props.machineUsageType}
      }
      if (props.customerInvoiceNo) {
        objTemp = { ...objTemp, customerInvoiceNo: props.customerInvoiceNo}
      }
      if (props.categoryIds) {
        objTemp = { ...objTemp, categoryIds: props.categoryIds}
      }
      if (props.productTypes) {
        objTemp = { ...objTemp, productTypes: props.productTypes}
      }
      if (props.isFilterActiveStaff || props.isFilterActiveStaff === false) {
        objTemp = { ...objTemp, isFilterActiveStaff: props.isFilterActiveStaff}
      }
      if (props.isIncludeCreditPayment || props.isIncludeCreditPayment === false) {
        objTemp = { ...objTemp, isIncludeCreditPayment: props.isIncludeCreditPayment}
      }
      if (props.isAppolousPayment || props.isAppolousPayment === false) {
        objTemp = { ...objTemp, isAppolousPayment: props.isAppolousPayment}
      }
      if (props.orderByProperties ? props.orderByProperties.length > 0 : false) {
        objTemp = { ...objTemp, orderByProperties: props.orderByProperties}
      }
      if (props.categoryId) {
        objTemp = { ...objTemp, categoryId: props.categoryId}
      }
      if (props.categoryName) {
        objTemp = { ...objTemp, categoryName: props.categoryName}
      }
      if (props.salesCategoryId) {
        objTemp = { ...objTemp, salesCategoryId: props.salesCategoryId}
      }
      if (props.subBranchId) {
        objTemp = { ...objTemp, subBranchId: props.subBranchId}
      }
      if (props.serviceTreatmentId) {
        objTemp = { ...objTemp, serviceTreatmentId: props.serviceTreatmentId}
      }
      if (props.serviceTreatmentName) {
        objTemp = { ...objTemp, serviceTreatmentName: props.serviceTreatmentName}
      }
      if (props.isOptionalProduct || props.isOptionalProduct === false) {
        objTemp = { ...objTemp, isOptionalProduct: props.isOptionalProduct}
      }
      if (props.gender || props.gender === 0) {
        objTemp = { ...objTemp, gender: props.gender}
      }
      if (props.genderName) {
        objTemp = { ...objTemp, genderName: props.genderName}
      }
      if (props.raceId) {
        objTemp = { ...objTemp, raceId: props.raceId}
      }
      if (props.raceName) {
        objTemp = { ...objTemp, raceName: props.raceName}
      }
      if (props.isProductOnly || props.isProductOnly === false) {
        objTemp = { ...objTemp, isProductOnly: props.isProductOnly}
      }
      if (props.showCompleted) {
        objTemp = { ...objTemp, showCompleted: props.showCompleted}
      }
      if (props.departmentId) {
        objTemp = { ...objTemp, departmentId: props.departmentId}
      }
      if (props.departmentName) {
        objTemp = { ...objTemp, departmentName: props.departmentName}
      }
      if (props.promotionCode) {
        objTemp = { ...objTemp, promotionCode: props.promotionCode}
      }
      if (props.closingDate) {
        objTemp = { ...objTemp, closingDate: props.closingDate}
      }
      if (props.groupCodeId) {
        objTemp = { ...objTemp, groupCodeId: props.groupCodeId}
      }
      if (props.groupCodeName) {
        objTemp = { ...objTemp, groupCodeName: props.groupCodeName}
      }
      if(props.isConfirmed || props.isConfirmed === false){
        objTemp = { ...objTemp, isConfirmed: props.isConfirmed}
      }
      if (props.purchaseBranchId) {
        objTemp = { ...objTemp, purchaseBranchId: props.purchaseBranchId}
      }
      if (props.salesOrderNos) {
        objTemp = { ...objTemp, salesOrderNos: props.salesOrderNos}
      }
      if(props.isExcludeProduct || props.isExcludeProduct === false){
        objTemp = { ...objTemp, isExcludeProduct: props.isExcludeProduct}
      }
      if(props.isIncludeEarnSales || props.isIncludeEarnSales === false){
        objTemp = { ...objTemp, isIncludeEarnSales: props.isIncludeEarnSales}
      }
      if (props.redemptionFromDate) {
        objTemp = { ...objTemp, redemptionFromDate: props.redemptionFromDate}
      }
      if (props.redemptionToDate) {
        objTemp = { ...objTemp, redemptionToDate: props.redemptionToDate}
      }
      if (props.salesOrderFromDate) {
        objTemp = { ...objTemp, salesOrderFromDate: props.salesOrderFromDate}
      }
      if (props.salesOrderToDate) {
        objTemp = { ...objTemp, salesOrderToDate: props.salesOrderToDate}
      }
      if (props.salesType || props.salesType === 0) {
        objTemp = { ...objTemp, salesType: props.salesType}
      }
      if (props.cLType || props.cLType === 0) {
        objTemp = { ...objTemp, cLType: props.cLType}
      }
      if (props.exchangeToCreditStatusName) {
        objTemp = { ...objTemp, exchangeToCreditStatusName: props.exchangeToCreditStatusName}
      }
      if (props.exchangeType || props.exchangeType === 0) {
        objTemp = { ...objTemp, exchangeType: props.exchangeType}
      }
      if (props.joinedFromDate) {
        objTemp = { ...objTemp, joinedFromDate: props.joinedFromDate}
      }
      if (props.joinedToDate) {
        objTemp = { ...objTemp, joinedToDate: props.joinedToDate}
      }
      if (props.categoryType) {
        objTemp = { ...objTemp, categoryType: props.categoryType}
      }//second page able to pass 
      if (props.categoryTypeName) {
        objTemp = { ...objTemp, categoryTypeName: props.categoryTypeName}
      }
      if (props.paymentFromDate) {
        objTemp = { ...objTemp, paymentFromDate: props.paymentFromDate}
      }
      if (props.paymentToDate) {
        objTemp = { ...objTemp, paymentToDate: props.paymentToDate}
      }
      if (props.submissionFromDate) {
        objTemp = { ...objTemp, submissionFromDate: props.submissionFromDate}
      }
      if (props.submissionToDate) {
        objTemp = { ...objTemp, submissionToDate: props.submissionToDate}
      }
      if (props.changePaymentModeNo) {
        objTemp = { ...objTemp, changePaymentModeNo: props.changePaymentModeNo}
      }
      if (props.requestFromDate) {
        objTemp = { ...objTemp, requestFromDate: props.requestFromDate}
      }
      if (props.requestToDate) {
        objTemp = { ...objTemp, requestToDate: props.requestToDate}
      }
      if (props.submissionDate) {
        objTemp = { ...objTemp, submissionDate: props.submissionDate}
      }
      if (props.preparedFromDate) {
        objTemp = { ...objTemp, preparedFromDate: props.preparedFromDate}
      }
      if (props.preparedToDate) {
        objTemp = { ...objTemp, preparedToDate: props.preparedToDate}
      }
      if (props.firstAppointmentDateRange) {
        objTemp = { ...objTemp, firstAppointmentDateRange: props.firstAppointmentDateRange}
      }
      if (props.lastAppointmentDateRange) {
        objTemp = { ...objTemp, lastAppointmentDateRange: props.lastAppointmentDateRange}
      }
      if (props.isFirstAppointmentOnly || props.isFirstAppointmentOnly === false) {
        objTemp = { ...objTemp, isFirstAppointmentOnly: props.isFirstAppointmentOnly}
      }
      if (props.lastVisitFromDate) {
        objTemp = { ...objTemp, lastVisitFromDate: props.lastVisitFromDate}
      }
      if (props.lastVisitToDate) {
        objTemp = { ...objTemp, lastVisitToDate: props.lastVisitToDate}
      }
      if (props.contractLiabilityNo) {
        objTemp = { ...objTemp, contractLiabilityNo: props.contractLiabilityNo}
      }
      if (props.currentBranchId) {
        objTemp = { ...objTemp, currentBranchId: props.currentBranchId}
      }
      if (props.creditType || props.creditType === 0) {
        objTemp = { ...objTemp, creditType: props.creditType}
      }
      if (props.purchasedCreditId) {
        objTemp = { ...objTemp, purchasedCreditId: props.purchasedCreditId}
      }
      if (props.documentNo) {
        objTemp = { ...objTemp, documentNo: props.documentNo}
      }
      if (props.referenceDocumentNo) {
        objTemp = { ...objTemp, referenceDocumentNo: props.referenceDocumentNo}
      }
      if (props.documentId) {
        objTemp = { ...objTemp, documentId: props.documentId}
      }
      if (props.documentType) {
        objTemp = { ...objTemp, documentType: props.documentType}
      }
      if (props.fromDate) {
        objTemp = { ...objTemp, fromDate: props.fromDate}
      }
      if (props.toDate) {
        objTemp = { ...objTemp, toDate: props.toDate}
      }
      if (props.customerDeliverOrderNo) {
        objTemp = { ...objTemp, customerDeliverOrderNo: props.customerDeliverOrderNo}
      }
      if (props.redemptionCutOffDate) {
        objTemp = { ...objTemp, redemptionCutOffDate: props.redemptionCutOffDate}
      }
      if (props.tinNo) {
        objTemp = { ...objTemp, tinNo: props.tinNo}
      }
      if (props.stockTakeNo) {
        objTemp = { ...objTemp, stockTakeNo: props.stockTakeNo}
      }
      
      await props.requestAPI(objTemp);
      if (props.setLocalLoading) {
        props.setLocalLoading(false);
      }
      else {
        setLoading(false);
      }
      props.setCurrentPage(page);
    }
  }
  
  const displayPaginationButton = (displayText, pageNumber, blnArrow = false) => {
    return (
      <button
        type="button"
        key={`button_key_${pageNumber+(blnArrow ? 8888 : 0)}`}
        className={`btn ${props.currentPage==pageNumber && "btn-primary"}`}
        style={{marginLeft: '4px', minWidth: '35px', width: 'auto', paddingLeft: '8px', paddingRight: '8px', height: '35px', borderRadius:'17.5px'}}
        onClick={() => {onPageChange(pageNumber, pageSize)}}
      >
        {`${displayText}`}
      </button>
    )
  }

  const PageListRenderer = () => {
    let finalDataTemp = props.data || [];
    let totalPage = Math.ceil(props.totalItem/props.pageSize); 
    let fromPage = 0;
    let upToPage = 3;
    let blnAddLastPage = false;

    while (props.currentPage > upToPage) {
      upToPage += 3;
    }

    if (totalPage > upToPage) {
      fromPage = (upToPage - 3)+1
    }
    else {
      fromPage = upToPage - 2
      upToPage = totalPage;
    }

    let aryButton : pageObject[] = [];
    if (totalPage <= 1) {
      aryButton = [{page : 1}]
    }
    else {
      for (var p=fromPage; p<=upToPage; p++) {
        if (p !== 1 && p !==totalPage) {
          aryButton.push({
            page: p
          })
        }
      }
      blnAddLastPage = true;
    }

    return (
      <Row className="align-items-md-center mt-30">
        {
          props.totalItem > 0
          &&
          <Col>
            {intl.formatMessage({ id: "ShowNumbersOfPaginationTable" }, 
              { 
                fromNumber: (props.currentPage * props.pageSize)+1-props.pageSize, 
                toNumber: props.currentPage * props.pageSize < props.totalItem ? props.currentPage * props.pageSize : props.totalItem, 
                totalNumber: props.totalItem
              }
            )}
          </Col>
        }
        {
          finalDataTemp.length > 0 && props.pageSize !== Constants.maxPageSize
          ?          
          <Col className="pagination pagination-rounded justify-content-end margin-bottom-2 inner-custom-pagination">
            {
              blnAddLastPage && displayPaginationButton(`1`, 1)
            }
            {
              upToPage > 3 && displayPaginationButton(`<`, fromPage-1, true)
            }
            {aryButton.map(p => (
              displayPaginationButton(p.page, p.page)
            ))}
            {
              upToPage !== totalPage && displayPaginationButton(`>`, upToPage+1, true)
            }
            {
              blnAddLastPage && displayPaginationButton(totalPage, totalPage)
            }
          </Col>
          :
          null
        }
      </Row>
    );
  };

  const displayTabUI = (valueLocalTableColumn: any, index) => {
    if (valueLocalTableColumn?.dataField === 'id' || isObjectEmpty(valueLocalTableColumn)) 
      return (<div style={{display: "contents"}} key={`empty_field_${index}`}/>)
      
    return (
      <div style={{display: "contents"}} key={`${valueLocalTableColumn.text}_${index}`}>
        <input 
          key={valueLocalTableColumn.text}
          type="checkbox" 
          className="btn-check" 
          name={valueLocalTableColumn.text}
          id={valueLocalTableColumn.text}
          autoComplete="off" 
          defaultChecked={!valueLocalTableColumn.hidden}
          onClick={() => {
            let localTableColumnsTemp = _.cloneDeep(localTableColumns);
            let indexTemp = _.findIndex(localTableColumnsTemp, { dataField: valueLocalTableColumn.dataField})
            if (indexTemp > -1) {
              localTableColumnsTemp[indexTemp].hidden = !localTableColumnsTemp[indexTemp].hidden;
              setLocalTableColumns(localTableColumnsTemp)
              if (props.setTableColumnsFromStore) {
                props.setTableColumnsFromStore(localTableColumnsTemp)
              }
            }
        }}/>
        <label className="btn btn-outline-primary" style={{zIndex: 0, marginRight: '12px', width: "max-content" }} htmlFor={valueLocalTableColumn.text}>
          {valueLocalTableColumn.text}
        </label>
      </div>
    )
  }

  return (
    (loading || props.localLoading) && !props.isModal
    ?
    <Loading className={props.loadingClassName ? props.loadingClassName : ""}/>
    :
    <PaginationProvider
      pagination={paginationFactory(pageOptions)}
      keyField={props.keyField || "id"}
      columns={props.blnSupportColumnFilter ? localTableColumns : props.tableColumns}
      data={props.data}
    >
      {({ paginationProps, paginationTableProps }) => (
        <ToolkitProvider
          keyField={props.keyField || "id"}
          data={props.data || []}
          columns={props.blnSupportColumnFilter ? localTableColumns : props.tableColumns}
          bootstrap4
          search
        >
          {toolkitProps => (
            <div 
              className= {!props.blnRemoveBackground ? "standard-layout" : ""}>
              {/* {!props.hideSearchBar &&
                <Col className="flex-right">
                  <div className="search-box ms-2 margin-bottom-2 d-inline-block">
                    <div className="position-relative">
                      <SearchBar {...toolkitProps.searchProps} />
                      <i className="bx bx-search-alt search-icon" />
                    </div>
                  </div>
                </Col>
              } */}
              {props.title && 
                <Label className="h5 margin-bottom-0">{props.title}</Label>
              }
              <Row>
                <Col xl="12">
                  <div className={`${!props.blnDisabledScroll ? "table-responsive" : ""}`}>
                    {
                      props.blnSupportColumnFilter
                      ?
                      <Row style={{paddingLeft: "12px"}}>
                        {
                          localTableColumns.map((valueLocalTableColumn, index)=> {
                            return displayTabUI(valueLocalTableColumn, index)
                          })
                        }
                      </Row>
                      :
                      null
                    }
                    <BootstrapTable
                      responsive
                      bordered={props.bordered || false}
                      striped={props.striped || false}
                      defaultSorted={props.objSorted}
                      classes={"table align-middle margin-top-16"}
                      keyField={props.keyField || "id"}
                      noDataIndication={props.emptyTextDisplay}
                      expandRow={props.expandRow}
                      rowEvents={props.rowEvents}
                      rowStyle={props.rowStyle}
                      selectRow={props.selectRow}
                      headerClasses="table-light"
                      {...toolkitProps.baseProps}
                      {...paginationTableProps}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </ToolkitProvider>
      )}
    </PaginationProvider>
  )
})
