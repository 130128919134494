import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { Row } from "reactstrap";
import PaginationTable from "../../app/components/table/PaginationTable";
import SortCaret from "../../app/components/table/SortCaret";
import { history } from "../..";
import { StockTakeListObject } from "../../app/models/stockTake";
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import moment from "moment";
import { stockTakeStatusColor, checkPermission, getBranchUser, getBranchName, getBranchId, arraySpliceInAllValue, comparePathForPagination, comparePathForSetParams } from "../../app/common/function/function";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import Loading from "../../app/components/loading/Loading";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import _ from "lodash";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { ObjectForDropdown } from "../../app/models/common";
import TableActionButton from "../../app/components/table/TableActionButton";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import { PaginationRequestBody } from "../../app/models/pagination";
import ContrastIcon from "mdi-react/ContrastIcon";
import { useLocation } from "react-router-dom";
import GeneralInput from "../../app/components/form/GeneralInput";
import * as Yup from "yup";
import { useFormik } from "formik";

const StockTake = () => {
  const intl = useIntl();
  //Use Store
  const { stockTakeStore, branchStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, prevPath, setErrorMessage, setSuccessMessage, setShowDeletePrompt, } = commonStore;
  const { stockTakeList, stockTakePaginationParams, getStockTake, deleteStockTake, setStockTakePaginationParams } = stockTakeStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<StockTakeListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [localLoading, setLocalLoading] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const pageSize = Constants.defaultPageSize;

  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedStockTakeStatus, setSelectedStockTakeStatus] = useState<string>(AllStatus);
  const [selectedStockTakeStatusId, setSelectedStockTakeStatusId] = useState<number | string | undefined>(" ");
  const [stockTakeStatusList, setStockTakeStatusList] = useState<StaticReferenceDataObject[]>([]);
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [triggerSearch, setTriggerSearch] = useState(0);
  const blnHQStaff = !getBranchUser();
  const blnPermissionCreateStockTake = checkPermission([PermissionConstants.CreateStockTake]);
  const blnPermissionUpdateStockTake = checkPermission([PermissionConstants.UpdateStockTake]);
  const blnPermissionDeleteStockTake = checkPermission([PermissionConstants.DeleteStockTake]);
  const blnPermissionUpdateStockAdjustment = checkPermission([PermissionConstants.UpdateStockAdjustment]);
  
  const paramsSearch = useLocation().search;

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      stockTakeNo: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });
  
  let stockTakeRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    stockTakeStatus: selectedStockTakeStatusId,
    stockTakeStatusName: selectedStockTakeStatus,
    branchId: selectedBranchId,
    branchName: selectedBranchName,
    stockTakeNo: validation.values.stockTakeNo
  }

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchStockTakeListAPI() {
      setLocalLoading(true)
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.stockTake)
      if (blnBackNavigation) {
        if (stockTakePaginationParams) {
          setCurrentPage(stockTakePaginationParams.pageNumber)
          setSelectedBranchId(stockTakePaginationParams.branchId || "")
          setSelectedBranchName(stockTakePaginationParams.branchName || "")
          setSelectedStockTakeStatus(stockTakePaginationParams.stockTakeStatusName || "");
          setSelectedStockTakeStatusId(stockTakePaginationParams.stockTakeStatus)
          validation.setFieldValue("stockTakeNo", stockTakePaginationParams.stockTakeNo || "")
          stockTakeRequestBody = stockTakePaginationParams;
        }
      }
      else {
        setStockTakePaginationParams(undefined);
      }
      const documentNoParams = new URLSearchParams(paramsSearch).get('documentNo');
      if (documentNoParams) {
        validation.setFieldValue("stockTakeNo", documentNoParams)
        stockTakeRequestBody.stockTakeNo = documentNoParams;
        history.replace({ pathname: `/${RoutesList.stockTake}` });
      }

      let aryAPI: any = [
        fetchStockTakeAPI(false),
        getBranchDropDown(),
        getStaticReferenceWithType(Constants.stockTakeStatus, AllStatus)
      ];
      let resultAPI = await Promise.all(aryAPI);
      setBranchListFinal(arraySpliceInAllValue(resultAPI[1], isEligibleAllBranch ? AllBranch : "", true))
      setStockTakeStatusList(resultAPI[2]);
      setLocalLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageStockTake], true)) {
      return;
    }

    fetchStockTakeListAPI();

    return (()=> {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.stockTake)
        if (!blnSetParams) {
          setStockTakePaginationParams(undefined);
        }
      }
    })
  }, []);

  useEffect(() => {
    if (
      (selectedStockTakeStatusId || selectedStockTakeStatusId === 0) &&
      !initialCallAPI
    ) {
      fetchStockTakeAPI(true);
    }
  }, [selectedStockTakeStatusId, triggerSearch]);

  useEffect(() => {
    if (
      (selectedBranchId) && !initialCallAPI) {
        fetchStockTakeAPI(true);
    }
  }, [selectedBranchId]);

  async function fetchStockTakeAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getStockTake(stockTakeRequestBody);
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickDelete = async (row: StockTakeListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  };

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value) => value + 1)
  }

  //Table Content UI
  const StockTakeColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "stockTakeNo",
      text: `${intl.formatMessage({ id: "StockTakeNo" }).toUpperCase()}.`,
      sort: true,
      sortCaret: (order, column) => <SortCaret order={order} />,
    },
    {
      dataField: "stockTakeDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.stockTakeDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.stockTakeDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "BranchName" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{row.branchName || Constants.emptyData}</div>,
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={stockTakeStatusColor}/>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            {
              row.status !== Constants.pending
              &&
              <TableActionButton 
                to={`/${RoutesList.stockTake}/view/${row.id}`}
                type={`view`}/>
            }
            {
              blnPermissionUpdateStockTake
              &&
              row.status !== Constants.completed
              &&
              <TableActionButton 
                to={`/${RoutesList.stockTake}/edit/${row.id}`}
                type={`edit`}/>
            }
            {
              blnPermissionUpdateStockAdjustment
              &&
              row.isAllowAdjustStockTakeVariance
              &&
              <TableActionButton
                to={`/${RoutesList.stockAdjustment}/add?stockTakeId=${row.id}`}
                type="edit"
                iconReplaceUI={<ContrastIcon size={18} id="adjustStockTakeVarianceToolTip" />}
                iconId="adjustStockTakeVarianceToolTip"
                iconToolTipText={`${intl.formatMessage({ id: "AdjustStockTakeVariance" })}`} />
            }
            {
              blnPermissionDeleteStockTake
              &&
              row.status !== Constants.completed
              &&
              <TableActionButton 
                type={`delete`}
                onClick={() => onClickDelete(row)}/>
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "StockTake" }) })}
        title={intl.formatMessage({ id: "StockTake" })}
        addButton={
          blnPermissionCreateStockTake
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "StockTake" }) })}
            onClick={() => { history.push(`/${RoutesList.stockTake}/add`) }}
            linkTo={`/${RoutesList.stockTake}/add`}
            disable={localLoading || loading} />
        }>
        {
          !initialCallAPI
          &&
          <Row>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Branch" })}
                  name="branchName"
                  options={branchListFinal}
                  initialLabel={selectedBranchName}
                  initialValue={selectedBranchId}
                  setFieldLabel={setSelectedBranchName}
                  setFieldValue={setSelectedBranchId}
                  disabled={localLoading || loading || !blnHQStaff}
                  validationRequired={true}
                  onChange={(value) => {
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                    // getCustomerWithLoading(value);
                  }}
                />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "StockTake" }) })}
                name="stockTakeStatus"
                options={stockTakeStatusList}
                blnValueWithNewSpace={true}
                labelField="displayValue"
                valueField="key"
                initialLabel={selectedStockTakeStatus}
                initialValue={selectedStockTakeStatusId}
                setFieldLabel={setSelectedStockTakeStatus}
                setFieldValue={setSelectedStockTakeStatusId}
                disabled={localLoading || loading}
                validationRequired={true}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralInput
                title={intl.formatMessage({ id: "StockTakeNo" })}
                name="stockTakeNo"
                type="text"
                disabled={localLoading || loading}
                validation={validation}
                placeholder={intl.formatMessage({ id: "EnterStockTakeNo" })}
                onBlurFunction={()=> {
                  onClickSearch()
                }}
              />
            </DoubleColumnRowParent>
          </Row>
        }
        {localLoading ? (
          <Loading />
        ) : (
          <>
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={StockTakeColumns}
              data={stockTakeList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "StockTake" }) })}
              objSorted={defaultSorted}
              stockTakeStatus={selectedStockTakeStatusId}
              stockTakeStatusName={selectedStockTakeStatus}
              branchId={selectedBranchId}
              branchName={selectedBranchName}
              stockTakeNo={validation.values.stockTakeNo}
              requestAPI={getStockTake}
            />
            <DeleteModal
              displayName={selectedRow?.stockTakeNo}
              onDeleteClick={async () => {
                setLoading(true);
                await deleteStockTake(
                  selectedRow?.id || "",
                  selectedRow?.stockTakeNo || ""
                );
                let currentPageTemp = currentPage;
                if (stockTakeList.length === 1 && currentPage !== 1) {
                  setCurrentPage((value) => value - 1);
                  currentPageTemp = currentPageTemp - 1;
                }
                await getStockTake({
                  pageNumber: currentPageTemp,
                  pageSize: pageSize,
                });
                setLoading(false);
              }}
            />
          </>
        )}
      </ListViewLayout>
    </div>
  );
};

export default observer(StockTake);