import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  checkPermission,
  stockRequestStatusColor,
  IncludesLocationPathName,
  getBranchId,
  contructValidationErrorMessage
} from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Form, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import TableWithEditableInputFields from "../../app/components/table/TableWithEditableInputFields";
import { ObjectForTableWithDynamicInputAndCheckBox } from "../../app/models/common";
import MyButton from "../../app/components/form/MyButton";
import classNames from "classnames";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import moment from "moment";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import GeneralInput from "../../app/components/form/GeneralInput";
import SelectWithTableListModal from "../../app/components/modal/SelectWithTableListModal";
import { burgerMenu } from "../../app/models/common";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import ActionPromptModal from "../../app/components/modal/ActionPromptModal";

const StockReceiveDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { stockReceiveStore, stockRequestStore, commonStore } = useStore();
  const { errorMessage, successMessage, loading, windowSize, setErrorMessage, setSuccessMessage, setLoading} = commonStore;
  const { addStockReceive, updateStockReceive, stockReceiveDetail, setStockReceiveDetail, getStockReceiveWithId } = stockReceiveStore;
  const { stockRequestList, getStockRequest, getStockRequestWithId, setStockRequestList } = stockRequestStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "StockReceive" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "StockReceive" }), urlPath: RoutesList.stockReceive }];
  const pageSize = Constants.maxPageSize;
  const [activeTab, setActiveTab] = useState(1);
  const [stockRequestModal, setStockRequestModal] = useState(false);
  const [loadingStockRequest, setLoadingStockRequest] = useState(false); 
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const blnPermissionViewStockRequest = checkPermission([PermissionConstants.ManageStockRequest]);
  const [stockReceiveZeroQuantityAlertModal, setStockReceiveZeroQuantityAlertModal] = useState(false);

  const aryDynamicInputAndCheckBoxTab: ObjectForTableWithDynamicInputAndCheckBox[] =
    [
      {
        label: intl.formatMessage({ id: "No." }).toUpperCase(),
        type: "label",
        xl: "0.5",
        inputName: "",
        inputType: "text",
        displayIndexNumber: true
      },
      {
        label: intl.formatMessage({ id: "ProductName" }).toUpperCase(),
        type: "label",
        xl: "1.91",
        inputName: "productName",
        inputType: "text"
      },
      {
        label: intl.formatMessage({ id: "UOM" }).toUpperCase(),
        type: "input",
        xl: "1.91",
        inputName: "measurementName",
        inputType: "text",
        disabled: true
      },
      {
        label: intl.formatMessage({ id: "RequestedQuantity" }).toUpperCase(),
        type: "input",
        xl: "1.91",
        inputName: "requestedQuantity",
        inputType: "number",
        disabled: true
      },
      {
        label: intl.formatMessage({ id: "ApprovedQty" }).toUpperCase(),
        type: "input",
        xl: "1.91",
        inputName: "approvedQuantity",
        inputType: "number",
        disabled: true
      },
      {
        label: intl.formatMessage({ id: "Quantity" }).toUpperCase(),
        type: "input",
        xl: "1.91",
        inputName: "quantity",
        inputType: "number",
        numberMaxFieldName: "requestedQuantity",
      },
      {
        label: intl.formatMessage({ id: "Remark" }).toUpperCase(),
        type: "input",
        xl: "1.91",
        inputName: "remark",
        inputType: "text",
      }
    ];
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: stockReceiveDetail || {
      id: "",
      isDelete: false,
      stockReceiveNo: "",
      receivedDate: "",
      requestBranchId: "",
      requestBranchName: "",
      requestType: "",
      fromSupplierId: "",
      fromSupplierName: "",
      fromBranchId: "",
      fromBranchName: "",
      note: "",
      status: "",
      stockRequestId: "",
      stockRequestNo: "",
      isAllowUpdateStatus: false,
      stockReceiveDetails: []
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      try {
        if (stockReceiveDetailsValidation()) {
          return;
        }

        if(!stockReceiveZeroQuantityAlertModal){
          if (addAction) {
            await addStockReceive(valuesTemp);
          } else {
            await updateStockReceive(valuesTemp);
          }
        }
      
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);
  const blnTab1 = activeTab === 1;
  const blnTab2 = activeTab === 2;
  const blnProcessingStatus = validation.values.status == Constants.processing;

  //Table Content UI
  const StockRequestColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "stockRequestNo",
      text: intl.formatMessage({ id: "StockRequestNo" }).toUpperCase(),
      sort: true,
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionViewStockRequest
            ?
            <Link className="d-block text-primary mb-2 h6" 
              to={`/${RoutesList.stockRequest}/view/${row.id}`}
              target={Constants.blank} 
              rel="noopener noreferrer">
              {row.stockRequestNo}
            </Link>
            :
            <div>{row.stockRequestNo}</div>
          }
        </>
      ),
    },
    {
      dataField: "requestedDate",
      text: intl.formatMessage({ id: "RequestedDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {moment(row.requestedDate).format(
            Constants.displayDateAndTimeFormat
          )}
        </div>
      ),
    },
    {
      dataField: "requestType",
      text: intl.formatMessage({ id: "From" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.requestType === Constants.hq ? row.fromSupplierName : row.fromBranchName}
        </div>
      ),
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={stockRequestStatusColor}/>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.actionButtonWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <MyButton
              type="button"
              class="btn btn-primary"
              content={intl.formatMessage({id: "Select"})}/>
          </div>
        </>
      ),
    },
  ];

  useEffect(() => {
    async function fetchStockReceiveDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getStockRequest({ pageNumber: 1, pageSize: pageSize, stockRequestStatus: 3, requestBranchId: getBranchId(), hasStockReceive: false }), //Processing
      ];
      if (id && !addAction) {
        aryAPI.push(getStockReceiveWithId(id));
      }
      await Promise.all(aryAPI);
      setLoading(false);
    }

    if (addAction) {
      setStockReceiveDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateStockReceive], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateStockReceive], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageStockReceive], true)) {
        return;
      }
    }

    fetchStockReceiveDetailAPI();

    return (() => {
      if (id && !addAction) {
        setStockReceiveDetail(undefined);
      }
      setStockRequestList([]);
    })
  }, []);

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 3) {
        setActiveTab(tab)
      }
    }
  }

  const nextButtonDisableTab = () => {
    if (blnTab1) {
      if (!validation.values.stockRequestId) {
        return true;
      }
    }

    return false;
  }

  const stockReceiveDetailsValidation = () => {
    let blnEmptyData = false;
    
    validation.values.stockReceiveDetails = validation.values.stockReceiveDetails.map((stockReceiveDetailsTemp) => {
      if (!stockReceiveDetailsTemp.quantity && (stockReceiveDetailsTemp.quantity !== 0 || stockReceiveDetailsTemp.quantity === 0)) {
        blnEmptyData = true;
        return ({...stockReceiveDetailsTemp, backgroundColor: Constants.lightorange})
      }
      return ({...stockReceiveDetailsTemp, backgroundColor: ""})
    })

    if (blnEmptyData) {
      setStockReceiveZeroQuantityAlertModal(true);
      //setErrorMessage(`${intl.formatMessage({ id: "EmptyQuantity" }, {field: "Receive"})}`) 
    }
    
    return blnEmptyData;
  }

  const onChangeStockRequestModal = (row) => {
    validation.setFieldValue("stockRequestId", row.id);
    validation.setFieldValue("stockRequestNo", row.stockRequestNo);
    setStockRequestModal(false);
    onChangeStockRequest(row.id);
  }

  const onChangeStockRequest = async (id: string) => {
    setLoadingStockRequest(true);
    try {
      let stockRequestDetailObject = await getStockRequestWithId(id);
      if (stockRequestDetailObject) {
        let stockRequestDetailsTemp = stockRequestDetailObject.stockRequestDetails.map((value) => {
          let stockRequestDetailContent: any = {
            productId: value.productId,
            productName: value.productName,
            measurementName: value.measurementName,
            requestedQuantity: value.orderQuantity,
            approvedQuantity: value.approvedQuantity,
            quantity: 0,
            remark: ""
          }

          return stockRequestDetailContent;
        })
        validation.setFieldValue("stockReceiveDetails", stockRequestDetailsTemp)
      }
    }
    catch (e) {
      setErrorMessage(String(e));
    }
    setLoadingStockRequest(false);
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        blnScroll={true}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <div className="wizard clearfix">
              <div className="steps clearfix">
                <ul>
                  <NavItem
                    className={classNames({ current: blnTab1 })}
                  >
                    <NavLink
                      className={classNames({ current: blnTab1 })}
                      onClick={() => {
                        setActiveTab(1)
                      }}
                      disabled={true}
                    >
                      <span className="number">1.</span> {`${intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "StockReceive" }) })}`}
                    </NavLink>
                  </NavItem>
                  <NavItem
                    className={classNames({ current: blnTab2 })}
                  >
                    <NavLink
                      className={classNames({ active: blnTab2 })}
                      onClick={() => {
                        setActiveTab(2)
                      }}
                      disabled={true}
                    >
                      <span className="number">2.</span> {`${intl.formatMessage({ id: "ModuleWithDetails" }, { moduleName: intl.formatMessage({ id: "StockReceive" }) })}`}
                    </NavLink>
                  </NavItem>
                </ul>
              </div>
              <div className="content clearfix">
                <TabContent
                  activeTab={activeTab}
                  className="body"
                >
                  <TabPane tabId={1}>
                    <Row form={+true}>
                      {" "}
                      <Col xl={12}>
                        <LineBreakWithTittle
                          paddingBottom="0px"
                          title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "StockReceive" }) })}
                          h4Title
                        />
                        <SingleColumnRowParent >
                          <GeneralInput
                            name={"stockRequestNo"}
                            title={intl.formatMessage({ id: "StockRequest" })}
                            className="mb-3 mt-4"
                            classNameInput="width-100-percentage"
                            type="text"
                            disabled={
                              true
                            }
                            validation={validation}
                            childrenUI={
                              addAction
                              &&
                              <MyButton
                                type="button"
                                content={"Select"}
                                class="btn btn-primary margin-left-8"
                                onClick={() => { setStockRequestModal(true) }}
                                loading={loadingStockRequest}
                                disable={disabledFieldInput || viewAction || loadingStockRequest || !addAction}
                              />
                            }/>
                        </SingleColumnRowParent>
                        <SingleColumnRowParent>
                          <GeneralTextArea
                            title="Note"
                            name="note"
                            row={5}
                            disabled={disabledFieldInput || viewAction}
                            validation={validation}
                          />
                        </SingleColumnRowParent>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId={2}>
                    <div className="flex-right">
                      <MyButton
                        type="button"
                        class={`btn btn-warning margin-right-8`}
                        content={intl.formatMessage({ id: "ClearQuantity" })}
                        disable={
                          validation.isSubmitting
                        }
                        loading={
                          validation.isSubmitting
                        }
                        onClick={() => {
                          let stockReceiveDetailsTemp : any = _.cloneDeep(validation.values.stockReceiveDetails);
                          stockReceiveDetailsTemp = stockReceiveDetailsTemp.map((valueStockReceiveDetailsTemp)=> ({
                            ...valueStockReceiveDetailsTemp,
                            quantity: 0
                          }));
                          validation.setFieldValue("stockReceiveDetails", stockReceiveDetailsTemp);
                        }}
                      />
                      <MyButton
                        type="button"
                        class={`btn btn-warning margin-right-8`}
                        content={intl.formatMessage({ id: "FillRequestedQuantity" })}
                        disable={
                          validation.isSubmitting
                        }
                        loading={
                          validation.isSubmitting
                        }
                        onClick={() => {
                          let stockReceiveDetailsTemp : any = _.cloneDeep(validation.values.stockReceiveDetails);
                          stockReceiveDetailsTemp = stockReceiveDetailsTemp.map((valueStockReceiveDetailsTemp)=> ({
                            ...valueStockReceiveDetailsTemp,
                            quantity: valueStockReceiveDetailsTemp.requestedQuantity
                          }));
                          validation.setFieldValue("stockReceiveDetails", stockReceiveDetailsTemp);
                        }}
                      />
                    </div>
                    <Col>
                      <LineBreakWithTittle
                        paddingBottom="0px"
                        title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Products" }) })}
                        h4Title
                      />
                      {
                        loadingStockRequest
                        ?
                        <Loading/>
                        :
                        <TableWithEditableInputFields
                          name="stockReceiveDetails"
                          title=""
                          buttonTitle={""}
                          blnEditable={!viewAction}
                          hideAddButton={true}
                          hideDeleteButton={true}
                          fixedHeader={true}
                          windowSizeHeight={windowSize.innerHeight/2.5}
                          supportBackgroundColor={true}
                          aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTab}
                          data={validation.values.stockReceiveDetails}
                          validation={validation}
                          disabled={disabledFieldInput} />
                      }
                    </Col>
                  </TabPane>
                </TabContent>
              </div>
              <div className="actions clearfix">
                <ul>
                  <li>
                    {
                      blnTab2 && !Boolean(successMessage) &&
                      <MyButton
                        type="button"
                        class="btn btn-primary"
                        style={{ width: '100%' }}
                        content={intl.formatMessage({ id: "Previous" })}
                        loading={
                          validation.isSubmitting
                        }
                        onClick={() => {
                          toggleTab(activeTab - 1)
                        }}
                      />
                    }
                  </li>
                  <li>
                    {
                      blnTab1 &&
                      <MyButton
                        type="button"
                        class="btn btn-primary"
                        style={{ width: '100%' }}
                        content={intl.formatMessage({ id: "Next" })}
                        disable={
                          nextButtonDisableTab()
                        }
                        loading={
                          validation.isSubmitting || loadingStockRequest
                        }
                        onClick={() => {
                          toggleTab(activeTab + 1)
                        }}
                      />
                    }
                  </li>
                </ul>
              </div>
            </div>
            <div className="mt-3" />
            <div className="flex-direction-row">
              <GeneralSubmitAndCancelBtn
                successMessage={successMessage}
                viewAction={viewAction}
                validation={validation}
                dirtyDisabled={true}
                showExtraLeftButton={blnProcessingStatus ? false : true}
                extraLeftButtonTitle={intl.formatMessage({ id: "Save" })}
                extraLeftButtonColor="btn-primary"
                extraLeftButtonFunction={() => {
                  validation.setFieldValue("workflowAction", Constants.save)
                }}
                hidePrimaryButton={blnTab1}
                submitButtonTitle={Constants.submit}
                onClickFunction={() => {
                  validation.setFieldValue("workflowAction", Constants.submit)
                }}
              />
            </div>
          </Form>
        )}
        <SelectWithTableListModal
          blnShow={stockRequestModal}
          setModal={setStockRequestModal}
          title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "StockRequest" }) })}
          options={stockRequestList}
          keyField={"id"}
          fieldName={"stockRequestId"}
          onSelectFunction={onChangeStockRequestModal}
          columns={StockRequestColumns}
          validation={validation}/>
        {
          stockReceiveZeroQuantityAlertModal
          &&
          <ActionPromptModal
            title={`${intl.formatMessage({ id: "StockReceiveZeroStockAlert" })}`}
            primaryActionTitle={`Yes`}
            showPrompt={stockReceiveZeroQuantityAlertModal}
            setShowPrompt={setStockReceiveZeroQuantityAlertModal}
            onPrimaryClick={async () => {
              validation.setSubmitting(true);
              let valuesTemp :any = _.cloneDeep(validation.values)
              if (addAction) {
                await addStockReceive(valuesTemp);
              } else {
                await updateStockReceive(valuesTemp);
              }
              validation.setSubmitting(false);
            }}
          />
        }
      </DetailViewLayout>
    </div >
  );
};

export default observer(StockReceiveDetail);
