import { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { checkPermission, stockTakeStatusColor } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Card, CardBody, Table } from "reactstrap";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyButton from "../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import { history } from "../..";
import GeneralTextAreaForModal from "../../app/components/form/GeneralTextAreaForModal";

const StockTakeViewDetail = () => {
  //Use Params
  const { id } = useParams();
  //Use INTL
  const intl = useIntl();
  //Use Store
  const { stockTakeStore, commonStore } = useStore();
  const { loading, setLoading } = commonStore;
  const { stockTakeDetail, setStockTakeDetail, getStockTakeWithId } = stockTakeStore;
  const displayTitle = `${intl.formatMessage({ id: "View" })} ${intl.formatMessage({ id: "StockTake" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "StockTake" }), urlPath: RoutesList.stockTake }];

  const stockTakeFullDetail = stockTakeDetail || {
    id: "",
    stockTakeNo: "",
    stockTakeDate: "",
    branchId: "",
    branchName: "",
    note: "",
    status: "",
    stockTakeDetails: [],
    productType: "",
    productTypeName: "",
    categoryId: "",
    categoryName: "",
    salesCategoryId: "",
    salesCategoryName: ""
  }

  async function fetchStockTakeDetailAPI() {
    setLoading(true);
    if (id) {
      let resultStockTakeAPI = await getStockTakeWithId(id);
      if (resultStockTakeAPI) {
        if (resultStockTakeAPI.status === Constants.pending) {
          history.push(`/${RoutesList.notFound}`)
        }
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!checkPermission([PermissionConstants.ManageStockTake], true)) {
      return;
    }
    
    fetchStockTakeDetailAPI();

    return (() => {
      if (id) {
        setStockTakeDetail(undefined);
      }
    })
  }, []);


  const displayHeaderRowData = (label, value) => {
    return (
      <Row>
        <h6 className="m-0 text-muted">
          {label}
        </h6>
        <h6 className="text-muted">
          {value}
        </h6>
      </Row>
    )
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        blnSupportStickyHeader={true}
        blnShowPrint={true}
        burgerMenuList={[]}>
        {loading ? (
          <Loading />
        ) : (
          <div>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="invoice-title">
                      <Col>
                        <div className="logo-lg" style={{ lineHeight: '20px', marginBottom: '16px' }}>
                          <span style={{ fontSize: Constants.documentTitleFontSize, fontWeight: 'bold', lineHeight: '20px' }}>
                            {intl.formatMessage({ id: "StockTake" })}
                          </span>
                        </div>
                      </Col>
                      <Row>
                        <Col>
                          {displayHeaderRowData(`${intl.formatMessage({ id: "StockTakeNo" })}:`, stockTakeFullDetail.stockTakeNo)}
                          {displayHeaderRowData(`${intl.formatMessage({id: "BranchName"})}:`, stockTakeFullDetail.branchName)}
                          {displayHeaderRowData(`${intl.formatMessage({id: "Date"})}:`, stockTakeFullDetail.stockTakeDate !== Constants.invalidDate ? moment(stockTakeFullDetail.stockTakeDate).format(Constants.displayDateFormat) : Constants.emptyData)}
                          {displayHeaderRowData(`${intl.formatMessage({id: "Time"})}:`, stockTakeFullDetail.stockTakeDate !== Constants.invalidDate ? moment(stockTakeFullDetail.stockTakeDate).format(Constants.displayTimeFormat) : Constants.emptyData)}
                        </Col>
                      </Row>
                    </div>
                    <hr/>
                    <address style={{ lineHeight: '20px', marginTop: '20px' }}>
                      <strong>{intl.formatMessage({ id: "Status" })}:</strong>
                      <br />
                      <div style={{ marginTop: '8px' }}>
                        <GeneralStatus
                          status={stockTakeFullDetail.status}
                          statusColorFunction={stockTakeStatusColor}
                          blnDisableCenter/>
                      </div>
                    </address>
                    <div className="py-2 mt-3">
                      <h3 className="font-size-15 font-weight-bold">
                        {intl.formatMessage({ id: "Product(s)" })}
                      </h3>
                    </div>
                    <div className="">
                      <Table className="table-fixed" style={{ width: '100%' }}>
                        <thead className="table-light bootstrap-table-sticky-header" id="bootstrap-table-sticky-header">
                          <tr>
                            <th className="">{intl.formatMessage({ id: "No." })}</th>
                            <th className="">{intl.formatMessage({ id: "Category" })}</th>
                            <th className="">{intl.formatMessage({ id: "ProductName" })}</th>
                            <th className="text-center">{intl.formatMessage({ id: "CurrentQuantity" })}</th>
                            <th className="text-center">{intl.formatMessage({ id: "PhysicalQuantity" })}</th>
                            <th className="">{intl.formatMessage({ id: "Remarks" })}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            stockTakeFullDetail.stockTakeDetails.map((item, key) => (
                              <Fragment key={`fragment_${key}`}>
                                <tr key={key}>
                                  <td className="">{`${key+1}. `}</td>
                                  <td className="">{item.categoryName || Constants.emptyData}</td>
                                  <td className="">{item.productName}</td>
                                  <td className="text-center">{item.quantity}</td>
                                  <td className="text-center">{item.actualQuantity}</td>
                                  <td className="">{item.remark || Constants.emptyData}</td>
                                </tr>
                              </Fragment>
                            ))
                          }
                        </tbody>
                      </Table>
                    </div>
                    <div className={'py-2 mt-3'}>
                      <h3 className="font-size-15 font-weight-bold">
                        {intl.formatMessage({ id: "Note" })} :
                      </h3>
                    </div>
                    <GeneralTextAreaForModal
                      title=""
                      name="note"
                      className="margin-top-0"
                      row={5}
                      field={stockTakeFullDetail}
                      setFieldValue={()=> {}}
                      disabled={true}
                    />
                    <div className="mt-4" />
                    <div className="flex-direction-row d-print-none">
                      <MyButton
                        type="button"
                        class={`btn btn-back`}
                        content={intl.formatMessage({id: "Back"})}
                        onClick={() => {
                          history.goBack()
                        }}
                        disable={false}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </DetailViewLayout>
    </div>
  );
};

export default observer(StockTakeViewDetail);
