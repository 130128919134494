import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { Link } from "react-router-dom";
import { checkPermission, IncludesLocationPathName, returnPriceWithCurrency, unknownPaymentStatusColor } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Card, CardBody, Table, Label } from "reactstrap";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import GeneralTextAreaForModal from "../../app/components/form/GeneralTextAreaForModal";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import { useFormik } from "formik";
import * as Yup from "yup";
import GeneralAttachment from "../../app/components/form/GeneralAttachment";
import PaginationTableWithoutApi from "../../app/components/table/PaginationTableWithoutApi";
import GeneralInputForModal from "../../app/components/form/GeneralInputForModal";

const UnknownPaymentDetail = () => {
  //Use Params
  const { id } = useParams();
  const intl = useIntl();

  //Use Store
  const { unknownPaymentStore, commonStore } = useStore();
  const { successMessage, loading, setLoading } = commonStore;
  const { unknownPaymentDetail, setUnknownPaymentDetail, getUnknownPaymentWithId } = unknownPaymentStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "UnknownPayment" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "UnknownPayment" }), urlPath: RoutesList.unknownPayment }];
  const blnPermissionManageCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionManageCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);

  const unknownPaymentFullDetail = unknownPaymentDetail || {
    id: "",
    paymentDate: "",
    customerBranchId: "",
    customerBranchName: "",
    customerId: "",
    customerName: "",
    paymentMethodId: "",
    paymentMethodName: "",
    paymentMethodOptionId: "",
    optionName: "",
    receivedAmount: 0,
    utilizedAmount: 0,
    balanceAmount: 0,
    last4CardDigit: 0,
    last4CardDigitNo: "",
    cardType: "",
    transactionNo: "",
    status: "",
    knockOffDate: "",
    remark: "",
    attachmentFileId: "",
    attachmentName: "",
    attachmentWebUrl: "",
    paymentIssuerBankName: "",
    orReferenceNo: "",
    salesOrderPayments: []
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      aryAttachments: []
    },
    validationSchema: Yup.object({}),
    onSubmit: async () => {
    },
  });

  const SalesOrderPaymentColumns = [
    {
      dataField: "salesOrderNo",
      text: intl.formatMessage({ id: "SalesOrderNo" }),
      formatter: (cellContent, row) => (
        <div>
          {
            blnPermissionManageCustomerSalesOrder 
            ?
            <Link to={row.salesOrderId ? `/${RoutesList.customerSalesOrder}/view/${row.salesOrderId}` : ""} target={Constants.blank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 pointer-clickable">{row.salesOrderNo || Constants.emptyData}</Label>
            </Link>
            :
            `${row.salesOrderNo}`
          }
        </div>
      )
    },
    {
      dataField: "utilizedAmount",
      text: intl.formatMessage({ id: "Utilized" }),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">
          {returnPriceWithCurrency(row.utilizedAmount)}
        </div>
      ),
    }
  ]

  async function fetchUnknownPaymentDetailAPI() {
    setLoading(true);
    if (id && !addAction) {
      let aryAPI: any = [
        getUnknownPaymentWithId(id),
      ];
      let resultAPI = await Promise.all(aryAPI);
      
      if (resultAPI[0].attachmentWebUrl && resultAPI[0].attachmentName && resultAPI[0].attachmentFileId) {
        validation.setFieldValue("aryAttachments", [{
          preview: "",
          path: resultAPI[0].attachmentName,
          webUrl: resultAPI[0].attachmentWebUrl,
        }]);
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!checkPermission([PermissionConstants.ManageUnknownCustomerPayment], true)) {
      return;
    }

    fetchUnknownPaymentDetailAPI();
    
    return (() => {
      if (id && !addAction) {
        setUnknownPaymentDetail(undefined);
      }
    })
  }, []);

  const displayHeaderRowData = (label, value) => {
    return (
      <Row>
        <div>
          <h6 className="m-0 text-muted">
            {label}
          </h6>
        </div>
        <div>
          <h6 className="text-muted" style={{whiteSpace: "pre-wrap"}}>
            {value}
          </h6>
        </div>
      </Row>
    )
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={[]}>
        {loading ? (
          <Loading />
        ) : (
          <div>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="unknownPayment-title margin-bottom-16 ">
                      <Row>
                        <Col xs="6">
                          <div className="logo-lg" style={{ lineHeight: '20px' }}>
                            <span style={{ fontSize: Constants.documentTitleFontSize, fontWeight: 'bold', lineHeight: '20px' }}>
                              {intl.formatMessage({ id: "UnknownPayment" })}
                            </span>
                            <br />
                            <h3 className="font-weight-bold text-muted margin-top-16" style={{ fontSize: '14px' }}>
                              {unknownPaymentFullDetail.customerBranchName}
                            </h3>
                            {displayHeaderRowData(`${intl.formatMessage({ id: "PaymentDate" })}:`, `${moment(unknownPaymentFullDetail.paymentDate).format(Constants.displayDateFormat)}\n${moment(unknownPaymentFullDetail.paymentDate).format(Constants.displayTimeFormat)}`)}
                            {displayHeaderRowData(`${intl.formatMessage({ id: "KnockOffDate" })}:`, unknownPaymentFullDetail.knockOffDate ? `${moment(unknownPaymentFullDetail.knockOffDate).format(Constants.displayDateFormat)}\n${moment(unknownPaymentFullDetail.knockOffDate).format(Constants.displayTimeFormat)}` : Constants.emptyData)}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <hr/>
                    <Col xs="6">
                      <address>
                        <strong>{intl.formatMessage({ id: "Customer" })}:</strong>
                        <div style={{fontSize: "14px"}}>
                          {
                            blnPermissionManageCustomer 
                            ? 
                            <Link to={unknownPaymentFullDetail.customerId ? `/${RoutesList.customer}/view/${unknownPaymentFullDetail.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                             <Label className="margin-bottom-0 pointer-clickable">{unknownPaymentFullDetail.customerName || Constants.emptyData}</Label>
                            </Link>
                            :  
                            unknownPaymentFullDetail.customerName
                          }
                        </div>
                        <div className="margin-top-8">
                          <strong>{intl.formatMessage({ id: "Status" })}:</strong>
                          <br />
                          <div style={{ marginTop: '8px' }}>
                            <GeneralStatus 
                              status={unknownPaymentFullDetail.status}
                              statusColorFunction={unknownPaymentStatusColor}
                              blnDisableCenter/>
                          </div>
                        </div>
                      </address>
                    </Col>
                    <div className="py-2 mt-4">
                      <h3 className="font-size-15 font-weight-bold">
                        {intl.formatMessage({ id: "UnknownPaymentDetails" })}
                      </h3>
                    </div>
                    <div className="table-responsive">
                      <Table className="table-fixed">
                        <thead className="table-light">
                          <tr>
                            <th>{intl.formatMessage({ id: "PaymentMethod" })}</th>
                            <th>{intl.formatMessage({ id: "TransactionNo" })}/{intl.formatMessage({ id: "ApprovalCode" })}</th>
                            <th className="text-center">{intl.formatMessage({ id: "Amount" })}</th>
                            <th className="text-center">{intl.formatMessage({ id: "Utilized" })}</th>
                            <th className="text-center">{intl.formatMessage({ id: "Balance" })}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <p className={`margin-bottom-0`}>{`${unknownPaymentFullDetail.paymentMethodName}${unknownPaymentFullDetail.cardType ? ` (${unknownPaymentFullDetail.cardType} | ${unknownPaymentFullDetail.last4CardDigitNo})` : ""}`}</p>
                              <p className="payment-method-sub-text">{`${unknownPaymentFullDetail?.optionName ? `${unknownPaymentFullDetail.optionName}` : ""}${unknownPaymentFullDetail.paymentIssuerBankName ? ` - ${unknownPaymentFullDetail.paymentIssuerBankName}` : ""}`}</p>
                            </td>
                            <td>{unknownPaymentFullDetail.transactionNo || Constants.emptyData}</td>
                            <td className="text-center">{returnPriceWithCurrency(unknownPaymentFullDetail.receivedAmount)}</td>
                            <td className="text-center">{returnPriceWithCurrency(unknownPaymentFullDetail.utilizedAmount)}</td>
                            <td className="text-center">{returnPriceWithCurrency(unknownPaymentFullDetail.balanceAmount)}</td>
                          </tr>
                          {/* {displayTableRowData(`${intl.formatMessage({ id: "SubTotalAdjustmentAmount" })}`, returnPriceWithCurrency(unknownPaymentFullDetail.subTotalAdjustmentAmount))}
                          {displayTableRowData(`${intl.formatMessage({ id: "ProcessingFees" })}`, returnPriceWithCurrency(unknownPaymentFullDetail.processingFees))}
                          {displayTableRowData(`${intl.formatMessage({ id: "Rounding" })}`, returnPriceWithCurrency(unknownPaymentFullDetail.rounding))}
                          {displayTableRowData(`${intl.formatMessage({ id: "TotalAdjustmentAmount" })}`, returnPriceWithCurrency(unknownPaymentFullDetail.totalAdjustmentAmount))} */}
                        </tbody>
                      </Table>
                    </div>
                    <GeneralTextAreaForModal
                      title={intl.formatMessage({ id: "Remark" })}
                      name="remark"
                      className={`mt-4`}
                      row={4}
                      field={unknownPaymentFullDetail}
                      setFieldValue={()=> {}}
                      disabled={true} />
                    <GeneralInputForModal
                      title={intl.formatMessage({ id: "ORReferenceNo" })}
                      name="orReferenceNo"
                      className={"mt-4"}
                      type="text"
                      field={unknownPaymentFullDetail}
                      setFieldValue={()=> {}}
                      disabled={true}/>
                    <GeneralAttachment
                      title={intl.formatMessage({ id: "Attachment" })}
                      name="aryAttachments"
                      className="mt-4"
                      validation={validation}
                      disabled={true}/>
                    <div className="d-print-none flex-direction-row mb-2" style={{ marginTop: "3rem" }}>
                      <GeneralSubmitAndCancelBtn
                        className="width-100-percentage"
                        successMessage={successMessage}
                        viewAction={false}
                        hidePrimaryButton={true}
                        showPrint={true}
                        cancelButtonTitle={intl.formatMessage({ id: "Back" })}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {
              unknownPaymentFullDetail.salesOrderPayments.length > 0 
              &&
              <Row className="d-print-none">
                <div>
                  <Col className="standard-layout">
                    <h5 className="mb-4">{intl.formatMessage({ id: "SalesOrderPayments" })}</h5>
                    <PaginationTableWithoutApi 
                      title={intl.formatMessage({ id: "SalesOrderPayments" })}
                      headerClassName={"table-light"}
                      options={unknownPaymentFullDetail.salesOrderPayments}
                      columns={SalesOrderPaymentColumns}
                      pageSize={Constants.maxPageSize}
                      keyField={"salesOrderId"}/>
                  </Col>
                </div>
              </Row>
            }
          </div>
        )}
      </DetailViewLayout>
    </div>
  );
};

export default observer(UnknownPaymentDetail);
