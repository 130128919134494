import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Label,
  Row,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { arraySpliceInAllValue, checkPermission, firstDayOfMonthToCurrentDay, getBranchId, getBranchName, getBranchUser, returnTableCellUIForMultiLayer, stockAdjustmentStatusColor } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { DateRangeInterface, ObjectForDropdown } from "../../../app/models/common";
import Loading from "../../../app/components/loading/Loading";
import _ from "lodash";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import MyButton from "../../../app/components/form/MyButton";
import moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../../app/components/form/GeneralInput";
import GeneralStatus from "../../../app/components/form/GeneralStatus";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import { StockTakeSummaryGetAndExportObject } from "../../../app/models/reportStock";
import DropDownWithTitleMultiSelect from "../../../app/components/dropdown/DropDownWithTitleMultiSelect";

const StockTakeSummary = () => {
  const intl = useIntl();
  //Use Store
  const { reportStockStore, branchStore, productStore, commonStore } = useStore();
  const { loading, setLoading, windowSize, setErrorMessage } = commonStore;
  const { stockTakeSummaryList, getStockTakeSummaryList, setStockTakeSummaryList, exportStockTakeSummary } = reportStockStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { productDropdownList, getProductDropdown } = productStore;

  //Data List
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.maxPageSize;
  const blnPermissionManageStockTake = checkPermission([PermissionConstants.ManageStockTake]);
  const blnPermissionManageStockAdjustment = checkPermission([PermissionConstants.ManageStockAdjustment]);
  const blnPermissionExportInventoryReport = checkPermission([PermissionConstants.ExportInventoryReport]);
  const tableColumnWidth = "200px";
  const heightTemp = 65;

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      sku: "",
      stockTakeNo: "",
      stockAdjustmentNo: "",
      productIds: []
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  let stockTakeRequestBody : StockTakeSummaryGetAndExportObject = {
    branchId: selectedBranchId,
    productIds: validation.values.productIds,
    sku: validation.values.sku,
    stockTakeNo: validation.values.stockTakeNo,
    stockAdjustmentNo: validation.values.stockAdjustmentNo,
    fromDate: selectedDateRange.fromDate,
    toDate: selectedDateRange.toDate
  }

  useEffect(() => {
    async function fetchStockTakeSummaryListApi() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
        getProductDropdown()
      ];
      let resultAPI = await Promise.all(aryAPI);

      setBranchListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageInventoryReport], true)) {
      return;
    }

    fetchStockTakeSummaryListApi();

    return (() => {
      setStockTakeSummaryList([]);
    })
  }, [])

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  async function fetchStockTakeSummaryApi(blnDropDown: boolean = false) {
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }

    blnDropDown ? setLocalLoading(true) : setLoading(true);
    setCurrentPage(1);
    await getStockTakeSummaryList(stockTakeRequestBody);
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  async function exportStockTakeSummaryApi() {
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }

    setLocalLoading(true)
    await exportStockTakeSummary(stockTakeRequestBody);
    setLocalLoading(false)
  }

  //Table Content UI
  const StockTakeSummaryListColumns = [
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
      style: { alignContent: 'baseline', minWidth: tableColumnWidth }
    },
    {
      dataField: "stockTakeNo",
      text: intl.formatMessage({ id: "StockTakeNo" }).toUpperCase(),
      formatter: (cellContent, row, index) => {
        let aryViews : any = [];
        row.summaryDetails.forEach((valueSummaryDetailTemp, indexSummaryDetailTemp) => {
          aryViews.push(
            <div 
              style={{ 
                minWidth: tableColumnWidth
              }} 
              key={`div_stock_take_summary_detail_stock_take_no_${index}_${indexSummaryDetailTemp}`}>
              {returnTableCellUIForMultiLayer(`stock_take_no_${index}_${indexSummaryDetailTemp}`, indexSummaryDetailTemp, row.summaryDetails.length, 
                  blnPermissionManageStockTake
                  ?
                  <Link to={valueSummaryDetailTemp.stockTakeId ? `/${RoutesList.stockTake}/view/${valueSummaryDetailTemp.stockTakeId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
                    <Label className={`margin-bottom-0 pointer-clickable ${indexSummaryDetailTemp !== 0 ? "mt-3" : ""}`}>{valueSummaryDetailTemp.stockTakeNo}</Label>
                  </Link>
                  :
                  <div className={indexSummaryDetailTemp !== 0 ? "mt-3" : ""}>
                    {valueSummaryDetailTemp.stockTakeNo}
                  </div>
                , `${heightTemp}px`, true)}
            </div>
          )
        })
        return aryViews;
      }
    },
    {
      dataField: "stockTakeDate",
      text: intl.formatMessage({ id: "StockTakeDate" }).toUpperCase(),
      formatter: (cellContent, row, index) => (
        displayTableRow(row, index, "stockTakeDate", Constants.date)
      )
    },
    {
      dataField: "productName",
      text: intl.formatMessage({ id: "ProductName" }).toUpperCase(),
      formatter: (cellContent, row, index) => (
        displayTableRow(row, index, "productName")
      )
    },
    {
      dataField: "sku",
      text: intl.formatMessage({ id: "SKU" }).toUpperCase(),
      formatter: (cellContent, row, index) => (
        displayTableRow(row, index, "sku")
      )
    },
    {
      dataField: "currentQuantity",
      text: intl.formatMessage({ id: "CurrentQuantity" }).toUpperCase(),
      formatter: (cellContent, row, index) => (
        displayTableRow(row, index, "currentQuantity")
      )
    },
    {
      dataField: "physicalQuantity",
      text: intl.formatMessage({ id: "PhysicalQuantity" }).toUpperCase(),
      formatter: (cellContent, row, index) => (
        displayTableRow(row, index, "physicalQuantity")
      )
    },
    {
      dataField: "variance",
      text: intl.formatMessage({ id: "Variance" }).toUpperCase(),
      formatter: (cellContent, row, index) => (
        displayTableRow(row, index, "variance")
      )
    },
    {
      dataField: "stockAdjustmentNo",
      text: intl.formatMessage({ id: "StockAdjustmentNo" }).toUpperCase(),
      formatter: (cellContent, row, index) => {
        let aryViews : any = [];
        row.summaryDetails.forEach((valueSummaryDetailTemp, indexSummaryDetailTemp) => {
          aryViews.push(
            <div 
              style={{ 
                minWidth: tableColumnWidth
              }} 
              key={`div_stock_take_summary_detail_stock_adjustment_no_${index}_${indexSummaryDetailTemp}`}>
              {returnTableCellUIForMultiLayer(`stock_adjustment_no_${index}_${indexSummaryDetailTemp}`, indexSummaryDetailTemp, row.summaryDetails.length, 
                  blnPermissionManageStockAdjustment
                  ?
                  <Link to={valueSummaryDetailTemp.stockAdjustmentId ? `/${RoutesList.stockAdjustment}/view/${valueSummaryDetailTemp.stockAdjustmentId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
                    <Label className={`margin-bottom-0 pointer-clickable ${indexSummaryDetailTemp !== 0 ? "mt-3" : ""}`}>{valueSummaryDetailTemp.stockAdjustmentNo}</Label>
                  </Link>
                  :
                  <div className={indexSummaryDetailTemp !== 0 ? "mt-3" : ""}>
                    {valueSummaryDetailTemp.stockAdjustmentNo}
                  </div>
                , `${heightTemp}px`, true)}
            </div>
          )
        })
        return aryViews;
      }
    },
    {
      dataField: "stockAdjustmentStatus",
      text: intl.formatMessage({ id: "StockAdjustmentStatus" }).toUpperCase(),
      formatter: (cellContent, row, index) => (
        displayTableRow(row, index, "stockAdjustmentStatus", Constants.status)
      )
    },
    {
      dataField: "stockAdjustmentReason",
      text: intl.formatMessage({ id: "StockAdjustmentReason" }).toUpperCase(),
      formatter: (cellContent, row, index) => (
        displayTableRow(row, index, "stockAdjustmentReason")
      )
    }
  ];

  const displayTableRow = (row, index, fieldName, type = "") => {
    let aryViews : any = [];
    row.summaryDetails.forEach((valueSummaryDetailTemp, indexSummaryDetailTemp) => {
      aryViews.push(
        <div 
          style={{ 
            minWidth: tableColumnWidth
          }} 
          key={`div_stock_take_summary_detail_${fieldName}_${index}_${indexSummaryDetailTemp}`}>
          {returnTableCellUIForMultiLayer(`${fieldName}_${index}_${indexSummaryDetailTemp}`, indexSummaryDetailTemp, row.summaryDetails.length, 
            <div className={indexSummaryDetailTemp !== 0 ? "mt-3" : ""}>
              {
                type === Constants.date 
                ? 
                moment(valueSummaryDetailTemp[fieldName]).format(Constants.displayDateAndTimeFormat) 
                : 
                type === Constants.status && valueSummaryDetailTemp[fieldName] !== Constants.emptyDataV2
                ?
                <GeneralStatus
                  status={valueSummaryDetailTemp[fieldName]}
                  statusColorFunction={stockAdjustmentStatusColor}
                  blnDisableCenter={true} />
                :
                valueSummaryDetailTemp[fieldName]
              }
            </div>, `${heightTemp}px`, true)}
        </div>
      )
    })
    return aryViews;
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "StockTakeSummary" }) })}
        title={intl.formatMessage({ id: "StockTakeSummary" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportInventoryReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              exportStockTakeSummaryApi()
            }}
          />
        }
        hyperlink={``}>
        <Row>
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              options={branchListFinal}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              validationRequired={true}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralDateRange
              title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "StockTake"})})}
              name={"DateRange"}
              disabled={localLoading || loading}
              onChangeFunction={onSelectDateRange}
              validationRequired={true}
              initialValue={selectedDateRange} />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <DropDownWithTitleMultiSelect
              name={"productIds"}
              className={"flex-1"}
              title={intl.formatMessage({ id: "Product" })}
              specifyReturnFieldName={[{ "field": "productIds", "value": "id" }]}
              returnFieldWithLabel={false}
              labelField={"name"}
              valueField={"id"}
              options={productDropdownList}
              disabled={localLoading || loading}
              validationRequired={false}
              validation={validation} />
          </DoubleColumnRowParent>
        </Row>
        <Row>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "SKU" })}
              name="sku"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "SKU" })}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "StockTakeNo" })}
              name="stockTakeNo"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "StockTakeNo" })}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "StockAdjustmentNo" })}
              name="stockAdjustmentNo"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "StockAdjustmentNo" })}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading}
              disable={localLoading || loading}
              onClick={() => {
                fetchStockTakeSummaryApi(true)
              }}
            />
          </DoubleColumnRowParent>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <>
              <PaginationTable
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                totalItem={stockTakeSummaryList.length}
                tableColumns={StockTakeSummaryListColumns}
                bordered={true}
                data={stockTakeSummaryList}
                emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "StockTakeSummary" }) })}
                objSorted={[]}
                keyField={"randomId"}
                requestAPI={getStockTakeSummaryList}
                hideSearchBar={true} />
            </>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(StockTakeSummary);
