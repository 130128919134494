import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { getYears, checkPermission, returnTableCellUI, returnPriceWithCurrency } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { ObjectForDropdown } from "../../../app/models/common";
import moment from "moment";
import Loading from "../../../app/components/loading/Loading";
import MyButton from "../../../app/components/form/MyButton";
import _ from "lodash";
import { Link } from "react-router-dom";
import { isMobileOnly, isTablet } from "react-device-detect";

const EarnSalesSummary = () => {
  const intl = useIntl();
  //Use Store
  const { reportAccountStore, commonStore } = useStore();
  const { loading, totalItem, windowSize } = commonStore;
  const { earnSalesSummaryList, setEarnSalesSummaryList, getEarnSalesSummaryList, exportEarnSalesSummary } = reportAccountStore; 
  
  //Data List
  const defaultColumns = {
    dataField: "monthDate",
    text: intl.formatMessage({ id: "Month" }).toUpperCase(),
    headerStyle: { textAlign: "center" },
    style: { backgroundColor: "#FAFAFC" },
    formatter: (cellContent, row, index) => (
      <div style={{ width: tableDataWidth, textAlign: "center" }}>
        {row.monthDate}
      </div>
    )
  };
  const [earnSalesSummaryColumns, setEarnSalesSummaryColumns] = useState<any>([defaultColumns]);
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(Number(moment().format('yyyy')));
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.maxPageSize;
  const tableDataWidth = "140px";
  const blnPermissionExportAccountReport = checkPermission([PermissionConstants.ExportAccountReport]);
  const blnLaptopOrPCOnly = !isTablet && !isMobileOnly;

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];
  
  useEffect(() => {    
    setYearList(getYears());
    
    if (!checkPermission([PermissionConstants.ManageAccountReport], true)) {
      return;
    }
    
    return (()=> {
      setEarnSalesSummaryList([]);
    })
  }, [])

  const fetchEarnSalesSummaryWithLoading = async (blnExport: boolean) => {
    setCurrentPage(1);
    setLocalLoading(true);
    if (blnExport) {
      await exportEarnSalesSummary({ year: selectedYear })
    } 
    else {
      let resultEarnSalesSummary = await getEarnSalesSummaryList({ year: selectedYear });
      if (resultEarnSalesSummary) {
        if (resultEarnSalesSummary.status === Constants.success) {
          let earnSalesSummaryListTemp = _.cloneDeep(resultEarnSalesSummary.data);
          let branchKeyList : string[] = [];
          if (earnSalesSummaryListTemp.length > 0) {
            if (earnSalesSummaryListTemp[0].branchData) {
              branchKeyList = Object.keys(earnSalesSummaryListTemp[0].branchData);
            }
          }

          let earnSalesSummaryColumnsTemp: any = [defaultColumns];
          earnSalesSummaryColumnsTemp.push({
            dataField: "entryData",
            text: intl.formatMessage({ id: "Action" }).toUpperCase(),
            style: { backgroundColor: "#FAFAFC" },
            formatter: (cellContent, row, index) => {
              let aryViews : any = [];
              row.entryData.Action.map((valueAction, indexAction) => {
                aryViews.push(
                  <div style={{ minWidth: "200px" }} key={`div_action_${index}_${indexAction}`}>
                   {returnTableCellUI(`action_${index}_${indexAction}`, indexAction, row.entryData.Action.length, valueAction, indexAction === (row.entryData.Action.length-1))}
                  </div>
                )
              })
              return aryViews;
            },
          })

          branchKeyList.map((valueBranchKey)=> {
            earnSalesSummaryColumnsTemp.push({
              dataField: valueBranchKey,
              text: valueBranchKey,
              formatter: (cellContent, row, index) => {
                let aryViews : any = [];
                if (row.branchData[valueBranchKey]) {
                  row.branchData[valueBranchKey].map((valueBranchData, indexBranchData) => {
                    let amountDisplay = valueBranchData.amount != null ? valueBranchData.isShowCurrency ? returnPriceWithCurrency(valueBranchData.amount) : valueBranchData.amount : "";
                    let hyperlinkUI : any = amountDisplay;
                    if (valueBranchData.routeUrl) {
                      hyperlinkUI = <Link to={valueBranchData.routeUrl} target={Constants.supportBlank} rel="noopener noreferrer">
                        <p className="mb-1">{amountDisplay}</p>
                      </Link>
                    }
                    aryViews.push(
                      <div style={{ minWidth: tableDataWidth }} key={`div_branch_data_${index}_${indexBranchData}`}>
                        {returnTableCellUI(`branch_data_${index}_${indexBranchData}`, indexBranchData, row.branchData[valueBranchKey].length, hyperlinkUI, indexBranchData === (row.branchData[valueBranchKey].length-1))}
                      </div>
                    )
                  })
                }
                return aryViews;
              },
            })
          })
          
          setEarnSalesSummaryColumns(earnSalesSummaryColumnsTemp);
        }
      }
    }
    setLocalLoading(false);
	};

  const displayExportButton = () => {
    return (
      <MyButton
        type="button"
        class={`btn btn-success margin-left-16 ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
        content={intl.formatMessage({ id: "Export" }).toUpperCase()}
        disable={localLoading || loading}
        onClick={()=> {
          fetchEarnSalesSummaryWithLoading(true)
        }}
      />
    )
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({id: "EarnSalesSummary"})})}
        title={intl.formatMessage({ id: "EarnSalesSummary" })}
        breadCrumbList={breadCrumbList}
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Earn-Sales-and-CL-Summary.aspx`}
        blnSupportStickyHeader={true}
        addButton={
          blnPermissionExportAccountReport
          &&
          !blnLaptopOrPCOnly
          &&
          displayExportButton()
        }>
        <Row>
          <Col xl="2" style={{zIndex: 4}}>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Years" })}
              name="years"
              options={yearList}
              initialLabel={selectedYear}
              initialValue={selectedYear}
              setFieldValue={setSelectedYear}
              disabled={localLoading || loading}
              validationRequired={true}
            />
          </Col>
          <Col xl="2">
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading}
              disable={localLoading || loading}
              onClick={()=> {
                fetchEarnSalesSummaryWithLoading(false)
              }}
            />
            {
              blnPermissionExportAccountReport
              &&
              blnLaptopOrPCOnly
              &&
              displayExportButton()
            }
          </Col>
        </Row>
        {
          localLoading
          ?
          <Loading/>
          :
          <div>
            {
              blnLaptopOrPCOnly
              &&
              <style type="text/css">{`
                /* Sticky styles for the first and second columns */
                .table thead th {
                  position: sticky;
                  top: 0;
                  z-index: 1;
                }

                .table th:first-child {
                  position: sticky;
                  left: 0;
                  z-index: 3;
                }

                .table th:nth-child(2) {
                  position: sticky;
                  left: ${tableDataWidth}; /* Adjust to the width of the first column */
                  z-index: 3;
                }

                .table td:first-child {
                  position: sticky;
                  left: 0;
                  z-index: 2;
                }

                .table td:nth-child(2) {
                  position: sticky;
                  left: ${tableDataWidth}; /* Adjust to the width of the first column */
                  z-index: 2;
                }
                  
                .vertical-menu .navbar-brand-box {
                  position: absolute !important;
                  z-index: 1 !important; 
                }
              `}</style>
            }
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              bordered={true}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={earnSalesSummaryColumns}
              data={earnSalesSummaryList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({id: "EarnSalesSummary"})})}
              objSorted={defaultSorted}
              blnDisabledScroll={true}
              keyField={"monthDate"}
              requestAPI={getEarnSalesSummaryList}
              selectedYear={selectedYear}
              hideSearchBar={true}/>
          </div>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(EarnSalesSummary);
