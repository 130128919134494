import _ from "lodash";
import React from "react";
import { Label } from "reactstrap";

interface Props {
	title: string;
	name: string;
	className?: string;
	placeholder?: string;
	row: number;
	disabled?: boolean;
	validationRequired?: boolean;
	field: any;
  setFieldValue: Function;
	onChangeFunction?: Function;
	maxLength?: number;
}

export default function GeneralTextAreaForModal(props: Props) {
	
	return (
		<div>
			<div className={props.className || "mb-3"}>
				{props.title && (
					<Label>
					{!props.disabled && props.validationRequired && <Label style={{color: 'red'}} className="margin-bottom-0">{`*`}</Label>}
						<Label className="margin-bottom-0">{props.title}</Label>
					</Label>
				)}
				{props.title && ( 
					<br /> 
				)}
				<textarea
					className="form-control"
					id={props.name}
					placeholder={props.placeholder}
					rows={props.row}
					onChange={(event) => {
						if (props.setFieldValue) {
						let fieldTemp = _.cloneDeep(props.field);
						fieldTemp[props.name] = event.target.value
						props.setFieldValue(fieldTemp);
							if (props.onChangeFunction) {
								props.onChangeFunction();
							}
						}
					}}
					disabled={props.disabled}
					maxLength={props.maxLength}
					value={props.field[props.name] || ""}
				/>
			</div>
		</div>
	);
}
