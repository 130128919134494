import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import {
  Input,
  Form,
  Table,
  Label
} from "reactstrap";
import BreadCrumb from '../../app/components/breadCrumb/BreadCrumb';
import { PermissionListObject } from '../../app/models/permission';
import { useIntl } from "react-intl";
import _ from "lodash";
import { useFormik } from "formik";
import Loading from "../../app/components/loading/Loading";
import MyButton from "../../app/components/form/MyButton";
import { checkPermission, newSpaceBeforeCapitalLetter, replaceAllStringWithArray } from "../../app/common/function/function";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";

const Permission = () => {
  const intl = useIntl();
  //Use Store
  const { permissionStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, setErrorMessage, setSuccessMessage } = commonStore;
  const { permissionList, getPermission, updatePermission } = permissionStore; 

  //Data List
  const [permissionListDisplay, setPermissionListDisplay] = useState<any>([]);
  const [permissionColumns, setPermissionColumns] = useState<any>([]);

  // validation
	const validation = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,
		initialValues: permissionList || [],
		onSubmit: async (values) => {
			const valuesTemp: any = values;
      try {
				await updatePermission(valuesTemp);
			} finally {
        validation.setSubmitting(false);
			}
		},
	});

  //Check Permission
  const blnPermissionUpdatePermission = checkPermission([PermissionConstants.UpdatePermission]);
  
  useEffect(() => {
    async function fetchPermissionAPI() {
      setLoading(true);
      let resultPermission : PermissionListObject[] = await getPermission() || [];
      if (resultPermission) {
        let aryPermissionTemp: any = [];
        let permissionColumnsTemp: any = [];
        resultPermission.map((valuePermissionTemp: any) => {
          let objPermissionTemp : any = {
            Name: valuePermissionTemp.name,
          }
          if (valuePermissionTemp.roles) {
            valuePermissionTemp.roles.map((valueRolesTemp: any) => {
              objPermissionTemp = {
                ...objPermissionTemp,
                [valueRolesTemp.name]: valueRolesTemp.isActive
              }
            })
          }
          let categoryName = replaceAllStringWithArray(valuePermissionTemp.name, ["Manage ", "Create ", "Update ", "Delete ", "Convert ", "Request Closed ", "Request ", "Review ", "Complete ", "Backdate ", "Manual ", " First Approval", " Second Approval", "Extend ", "Status ", "Search All Branch ", "Revise Closed ", " Final Approval", "Export ", "Use ", "Print All ", "Override ", " Approval", " Complete", " Verify"], "");
          if (categoryName === 'Reassign Employee Customer') {
            categoryName = "Employee";
          }
          if (categoryName === 'Edit Customer Joined Date' || categoryName === 'Edit Customer Media Source') {
            categoryName = "Customer";
          }
          if (categoryName === 'Appolous Payment') {
            categoryName = "Appolous Sales";
          }
          if (categoryName === 'Stock Receive Status') {
            categoryName = "Stock Receive";
          }
          if (categoryName === 'Customer Sales Order Status' || categoryName === 'Customer Sales Order' || categoryName === 'Customer Sales Order Sub Brand') {
            categoryName = "Sales Order";
          }
          if (categoryName === 'Customer Redemption Detail') {
            categoryName = "Customer Redemption";
          }
          if (categoryName === 'Appolous Redemption Detail') {
            categoryName = "Appolous Redemption";
          }
          if (categoryName === 'Credit Redemption Note with Refund') {
            categoryName = "Credit Redemption Note Request"
          }
          if (valuePermissionTemp.name.includes(Constants.report)) {
            let aryTemp = valuePermissionTemp.name.split(" ");
            if (aryTemp.length > 0) {
              if (aryTemp[aryTemp.length-1] === Constants.report) {
                categoryName = Constants.report;
              }
            }
          }
          let indexCategory = _.findIndex(aryPermissionTemp, {categoryName: categoryName})
          if (indexCategory > -1) {
            aryPermissionTemp[indexCategory].data.push(objPermissionTemp);
          }
          else {
            aryPermissionTemp.push({
              categoryName: categoryName,
              data: [objPermissionTemp]
            })
          }
        })
        if (aryPermissionTemp.length > 0) {
          if (aryPermissionTemp[0].data){
            if (aryPermissionTemp[0].data.length > 0) {
              for ( var key in aryPermissionTemp[0].data[0] ) {
                let keyTemp = key;
                if(keyTemp !== Constants.owner){
                  permissionColumnsTemp.push(keyTemp)
                }
              }
            }
          }
        }
        setPermissionListDisplay(aryPermissionTemp);
        setPermissionColumns(permissionColumnsTemp);
      }
      setLoading(false);
    }

    fetchPermissionAPI();
  }, [])

  const displayRow = (value) => {
    let aryViews : any= [];
    for (var key in value ) {
      let keyTemp = key;
      if(keyTemp !== Constants.owner){
        aryViews.push(
          <td key={`row_key_${keyTemp}`}>
            {
              keyTemp === 'Name'
              ?
              newSpaceBeforeCapitalLetter(value[keyTemp])
              :
              displayCheckbox(value, keyTemp)
            }
          </td>
        )
      }
    }
    return aryViews;
  }

  const displayCheckbox = (row, key) => {
    return (
      <span className="flex-justfity-content-center">
        <Input 
          type="checkbox" 
          style={{ width: '20px', height: '20px' }} 
          defaultChecked={row[key]}
          disabled={key === Constants.owner || !blnPermissionUpdatePermission ? true : false}
          onChange={(event)=> {handleChange(event, row, key)}}>
        </Input>
      </span>
    )
  }

  const handleChange = (event, row, key) => {
    let permissionListCloneTemp = _.cloneDeep(validation.values);
    let indexPermission = _.findIndex(permissionListCloneTemp, {name: row.Name})
    if (indexPermission > -1) {
      if (permissionListCloneTemp[indexPermission].roles) {
        if (permissionListCloneTemp[indexPermission].roles.length > 0) {
          let indexRole = _.findIndex(permissionListCloneTemp[indexPermission].roles, {name : key})
          if (indexRole > -1) {
            permissionListCloneTemp[indexPermission].roles[indexRole].isActive = event.target.checked;
          }
        }
      }
    }
    validation.setValues(permissionListCloneTemp) 
  }

  return (
    <div>
      <DetailViewLayout
				title={intl.formatMessage({id: "Permission"})}
				breadCrumbList={[]}
        blnScroll={true}
        auditTrailId={undefined}
        blnSupportStickyHeader={true}>
        {
          loading 
          ? 
          (
            <Loading />
          ) 
          : 
          (
            <Form
              className="standard-layout"
              style={{display: "inline-block"}}
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              onBlur={()=> {
                if (errorMessage || successMessage) {
                  setErrorMessage("");
                  setSuccessMessage("");
                }
              }}
            >
              <div className="">
                {
                  permissionListDisplay.map((value, index) => {
                    if (value.data) {
                      return (
                        <div key={`${value.categoryName}_${index}`}>
                          <Label className={`${index !== 0 ? "margin-top-24" : ""}`} style={{fontSize: '15px', fontWeight: '600px'}}><strong>{value.categoryName}</strong></Label>
                          <Table className="table table-bordered mb-0">
                            <thead className={`table-light bootstrap-table-sticky-header`} id="bootstrap-table-sticky-header">
                              <tr>
                                {
                                  permissionColumns.map((valuePermissionColumns, index)=> {
                                    return (
                                      <th key={valuePermissionColumns} style={{width: index === 0 ? '20%' : "0px", textAlign: 'center'}}>{newSpaceBeforeCapitalLetter(valuePermissionColumns)}</th>
                                    )
                                  })
                                }
                              </tr>
                            </thead>
                            <tbody>
                              {
                                value.data.map((valueData, indexValueData) => {
                                  return (
                                    <tr key={`data_${index}_${indexValueData}`}>
                                      {
                                        displayRow(valueData)
                                      }
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                          </Table>
                        </div>
                      )
                    }
                  })
                }
              </div>
              {
                blnPermissionUpdatePermission
                &&
                <MyButton
                  type="submit"
                  class="btn btn-primary margin-top-24"
                  content={intl.formatMessage({ id: "Submit" }).toUpperCase()}
                  disable={validation.isSubmitting || !validation.dirty}
                  loading={validation.isSubmitting}
                />
              }
            </Form>
          )}
      </DetailViewLayout>
    </div>
  );
}

export default observer(Permission);
