import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  AddOptionsValueField,
  checkPermission,
  compareParamsArrayWithDefaultArray,
  contructValidationErrorMessage,
  displayTypeWithColor,
  getBranchId,
  IncludesLocationPathName,
  removeAllSpace
} from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Form, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import TableWithEditableInputFields from "../../app/components/table/TableWithEditableInputFields";
import SelectDropDownModal from "../../app/components/modal/SelectDropDownModal";
import { StockRequestForProductDetailsObject } from "../../app/models/stockRequest";
import { ObjectForTableWithDynamicInputAndCheckBox } from "../../app/models/common";
import MyButton from "../../app/components/form/MyButton";
import classNames from "classnames";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";
import GeneralInput from "../../app/components/form/GeneralInput";
import { useLocation } from "react-router-dom";
import CancelModal from "../../app/components/modal/CancelModal";
import DropDownWithTitleMultiSelect from "../../app/components/dropdown/DropDownWithTitleMultiSelect";
import { StaticReferenceDataObject } from "../../app/models/staticReference";

const StockRequestDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { staticReferenceStore, stockRequestStore, branchStore, commonStore, } = useStore();
  const { errorMessage, successMessage, loading, windowSize, setErrorMessage, setSuccessMessage, setLoading, setShowCancelPrompt} = commonStore;
  const { addStockRequest, updateStockRequest, stockRequestDetail, setStockRequestDetail, setStockRequestProductList, getStockRequestWithId, stockRequestProductList, getStockRequestProductList } = stockRequestStore;
  const { branchDropDownList, getBranchDropDown } = branchStore;
  const { productType, getStaticReferenceWithType } = staticReferenceStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "StockRequest" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "StockRequest" }), urlPath: RoutesList.stockRequest }];
  const pageSize = Constants.maxPageSize;
  const [activeTab, setActiveTab] = useState(1);
  const [loadingStockRequest, setStockRequestLoading] = useState(false);

  const [productStockRequestlist, setProductStockRequestlist] = useState<StockRequestForProductDetailsObject[]>([]);
  const [productModal, setProductModal] = useState(false);
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const location = useLocation();
  const [isRecreateStockRequest, setIsRecreateStockRequest] = useState(false);
  const [isRecreateWithProductTypeChanges, setIsRecreateWithProductTypeChanges] = useState(false); //Product Type Chnages
  const [isStockRequestPreloadProductRedemption, setIsStockRequestPreloadProductRedemption] = useState(false);
  const [selectedInitialProductTypes, setSelectedInitialProductTypes] = useState([]);
  const [localStockRequestPurchaserType, setLocalStockRequestPurchaserType] = useState<StaticReferenceDataObject[]>([]);
  const blnRemarkMandatoryColor = Constants.lightpink;

  const prefillReorderAdviceQuantityClickFunction = (index) => {
    let stockRequestDetailsTemp = _.cloneDeep(validation.values.stockRequestDetails);
    if (stockRequestDetailsTemp[index]) {
      stockRequestDetailsTemp[index].orderQuantity = stockRequestDetailsTemp[index].adviseOrderQuantity || 0;
    }
    validation.setFieldValue("stockRequestDetails", stockRequestDetailsTemp)
  }

  const aryDynamicInputAndCheckBoxTab: ObjectForTableWithDynamicInputAndCheckBox[] =
    [
      {
        label: intl.formatMessage({ id: "No." }).toUpperCase(),
        type: "label",
        xl: "0.5",
        inputName: "",
        inputType: "text",
        displayIndexNumber: true
      },
      {
        label: intl.formatMessage({ id: "Name" }).toUpperCase(),
        type: "label",
        xl: "1.5",
        inputName: "productName",
        inputType: "text"
      },
      // {
      //   label: intl.formatMessage({ id: "UOM" }).toUpperCase(),
      //   type: "label",
      //   xl: "1.5",
      //   inputName: "measurementName",
      //   inputType: "text",
      // },
      {
        label: intl.formatMessage({ id: "CurrentQtyUnit" }).toUpperCase(),
        type: "label",
        xl: "1.25",
        inputName: "currentQuantity",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "ProcessingQtyUnit" }).toUpperCase(),
        type: "label",
        xl: "1.25",
        inputName: "processingOrderQuantity",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "IdealQtyUnit" }).toUpperCase(),
        type: "label",
        xl: "1.5",
        inputName: "minimumThreshold",
        inputType: "text",
      },
      // {
      //   label: intl.formatMessage({ id: "AdviceOrderQtyUnit" }).toUpperCase(),
      //   type: "input",
      //   xl: "1.5",
      //   inputName: "adviseOrderQuantity",
      //   inputType: "text",
      //   disabled: true,
      //   onClickForIndex: prefillReorderAdviceQuantityClickFunction
      // },
      {
        label: intl.formatMessage({ id: "UOMAndQuantity" }).toUpperCase(),
        type: "select",
        xl: "1.5",
        inputName: "measurementName",
        inputType: "select",
        optionsLocalField: { fieldName: "availableMeasurements", label: "name", value: "id"},
        displayLabel: "measurementName",
        displayValue: "measurementId",
        specifyReturnFieldName: [{ field: "measurementName", value: "name" }, { field: "measurementId", value: "id" }],
        validationRequired: true,
        disabled: true
      },
      {
        label: intl.formatMessage({ id: "OrderQty" }).toUpperCase(),
        type: "input",
        xl: "1.5",
        inputName: "orderQuantity",
        inputType: "number",
        numberMaxFieldName: "maxReorderQuantity",
        disabled: isStockRequestPreloadProductRedemption,
        toolTipTitle: `${intl.formatMessage({ id: "AdviceOrderQuantity" })}`
      },
      {
        label: intl.formatMessage({ id: "TotalOrderQtyUnit" }).toUpperCase(),
        type: "label",
        xl: "1.5",
        inputName: "totalOrderQuantity",
        inputType: "text",
        totalDisplayFormula: [
          { fieldName: "orderQuantity", type: "field" },
          { fieldName: "*", type: "method" },
          { fieldName: "unitConvertionRate", type: "field" },
        ],
      },
      {
        label: intl.formatMessage({ id: "Remarks" }).toUpperCase(),
        type: "input",
        xl: "1.5",
        inputName: "remark",
        inputType: "text",
      },
    ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: stockRequestDetail || {
      requestType: "",
      requestBranchId: getBranchId(),
      fromBranchId: undefined,
      fromBranchName: "",
      note: "",
      stockRequestDetails: [],
      status: "",
      workflowAction: "Save",
      productTypes: []
    },
    validationSchema: Yup.object({
      requestType: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "RequestType" }) })),
      fromBranchId: Yup.string().test(
        "fromBranchId",
        intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) }),
        (value: any, context) => {
          if (!blnHQRequestType) {
            return value;
          }
          return true;
        }
      ).nullable(),
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      try {
        if (stockRequestDetailsValidation() && valuesTemp.workflowAction !== Constants.cancel) {
          return;
        }
        if (addAction) {
          if (isRecreateStockRequest) {
            valuesTemp.stockRequestDetails = valuesTemp.stockRequestDetails.map((valueAddStockRequestDetail) => {
              if (valueAddStockRequestDetail.id) {
                delete valueAddStockRequestDetail.id
              }
              return(
                valueAddStockRequestDetail
              )
            })
          }
          await addStockRequest(valuesTemp);
        }
        else {
          await updateStockRequest(valuesTemp);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);
  const blnHQRequestType = (validation.values.requestType !== "" && validation.values.requestType === Constants.hq);
  const blnOutletRequestType = (validation.values.requestType !== "" && validation.values.requestType === Constants.outlet);
  const blnStockTransferType = (validation.values.requestType !== "" && validation.values.requestType === Constants.stockTransfer);
  const blnStatusProcessing = (validation.values.status === Constants.processing);
  const blnStatusPending = (validation.values.status === Constants.pending);
  const blnTab1 = activeTab === 1;
  const blnTab2 = activeTab === 2;

  useEffect(() => {
    async function fetchStockRequestDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getStaticReferenceWithType(Constants.stockRequestPurchaserType),
        getStaticReferenceWithType(Constants.productType),
        getBranchDropDown({}, false, true),
      ];

      if (location) {
        if (location.state) {
          if(location.state.detail.recreateStockRequestId){
            aryAPI.push(getStockRequestWithId(location.state.detail.recreateStockRequestId));
            setIsRecreateStockRequest(true);
          }
        }
      } 

      if (id && !addAction) {
        aryAPI.push(getStockRequestWithId(id));
      }
      let resultAryApi = await Promise.all(aryAPI);

      if (addAction) {
        let stockRequestPurchaserTypeTemp = _.cloneDeep(resultAryApi[0]);
        _.remove(stockRequestPurchaserTypeTemp, {
          displayValue: Constants.stockTransfer
        });
        setLocalStockRequestPurchaserType(stockRequestPurchaserTypeTemp)

        let initialProductTypesTemp = AddOptionsValueField(resultAryApi[1], "displayValue", "key");;
        let initialProductTypesKeyOnly = resultAryApi[1].map((valueProductType)=> valueProductType.key);
        setSelectedInitialProductTypes(initialProductTypesTemp);
        validation.setFieldValue("productTypes", initialProductTypesKeyOnly);
      }
      else {
        setLocalStockRequestPurchaserType(resultAryApi[0])
      }

      if (resultAryApi.length > 3) {
        if (resultAryApi[3]) {
          if (resultAryApi[3].requestType === Constants.stockTransfer) {
            setIsStockRequestPreloadProductRedemption(true);
          }
          else {
            let productTypesTemp = compareParamsArrayWithDefaultArray(resultAryApi[3].productTypes, resultAryApi[1], "key", "displayValue", "key");
            setSelectedInitialProductTypes(productTypesTemp);
            let productTypesIdsTemp = productTypesTemp.map((valueProductType)=> (valueProductType.key));
            await getStockRequestProductList(resultAryApi[3].requestBranchId, resultAryApi[3].requestType, productTypesIdsTemp);
          }
        }
      }
      setLoading(false);
    }

    if (addAction) {
      setStockRequestDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateStockRequest], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateStockRequest], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageStockRequest], true)) {
        return;
      }
    }

    if(viewAction && checkPermission([PermissionConstants.UpdateStockRequest])){
      burgerMenuListContent.push({ label: `${intl.formatMessage({id: "EditAction"})}`, onFunction: () => { history.push(`/${RoutesList.stockRequest}/edit/${id}`)} })
    }

    fetchStockRequestDetailAPI();

    return (() => {
      if (id && !addAction) {
        setStockRequestDetail(undefined);
        setStockRequestProductList([]);
      }
    })
  }, []);

  useEffect(() => {
    if ((id && !addAction) || isRecreateStockRequest) {
      if (stockRequestProductList.length > 0 && stockRequestDetail) {
        let stockRequestProductListTemp: StockRequestForProductDetailsObject[] = stockRequestProductList.map((value: StockRequestForProductDetailsObject) => {
          let productIdFound = false;
          let currentQuantity = 0;
          let processingOrderQuantity = 0;
          let orderQuantity = 0;
          let minimumThreshold = 0;
          let maxReorderQuantityDisplay = 0;
          let maxReorderQuantity = 0;
          let adviseOrderQuantity = 0;
          let unitConvertionRate = value.unitConvertionRate;
          let measurementId = value.measurementId;
          let measurementName = value.measurementName;

          stockRequestDetail?.stockRequestDetails.map((valueStockRequestDetail) => {
            if (valueStockRequestDetail.productId === value.productId) {
              productIdFound = true;
              currentQuantity = value.currentQuantity;
              processingOrderQuantity = value.processingOrderQuantity;
              orderQuantity = value.orderQuantity; 
              minimumThreshold = value.minimumThreshold; 
              maxReorderQuantityDisplay = value.maxReorderQuantity;
              maxReorderQuantity = stockRequestDetail?.requestType === Constants.outlet ? value.maxReorderQuantity :  Math.trunc(value.maxReorderQuantity/value.unitConvertionRate); 
              adviseOrderQuantity = stockRequestDetail?.requestType === Constants.outlet ? value.adviseOrderQuantity :  Math.trunc(value.adviseOrderQuantity/value.unitConvertionRate); 
              unitConvertionRate = valueStockRequestDetail.unitConvertionRate;
              measurementId = valueStockRequestDetail.measurementId;
              measurementName = valueStockRequestDetail.measurementName;
            }
          })
          
          return ({
            productId: value.productId,
            productName: value.productName,
            adviseOrderQuantity: productIdFound ? adviseOrderQuantity : Math.trunc(value.adviseOrderQuantity/value.unitConvertionRate),
            currentQuantity: productIdFound ? currentQuantity : value.currentQuantity,
            processingOrderQuantity: productIdFound ? processingOrderQuantity : value.processingOrderQuantity, 
            orderQuantity: productIdFound ? orderQuantity : Math.trunc(value.adviseOrderQuantity/value.unitConvertionRate),
            minimumThreshold: productIdFound ? minimumThreshold : value.minimumThreshold,
            maxReorderQuantityDisplay: productIdFound ? maxReorderQuantityDisplay : value.maxReorderQuantity,
            maxReorderQuantity: productIdFound ? maxReorderQuantity : Math.trunc(value.maxReorderQuantity/value.unitConvertionRate),
            remark: value.remark,
            measurementName: `${measurementName} ${measurementName !== Constants.unit ? `(${value.unitConvertionRate} Unit)` : ""}`,
            measurementId: measurementId,
            availableMeasurements: value.availableMeasurements,
            unitConvertionRate: unitConvertionRate,
            disabled: productIdFound
          })
        });
        setProductStockRequestlist(stockRequestProductListTemp);
      }
    }
  }, [stockRequestProductList, stockRequestDetail])

  const onChangeRequestType = async (value) => {
    // let blnHQTemp = value === Constants.hq;
    // await getProductWithLoading(getBranchId(), !blnHQTemp, blnHQTemp, value);
  }

  const getProductWithLoading = async (id: string, removeData: boolean = true, blnHq: boolean = false, requestType, productTypes: any) => {
    setStockRequestLoading(true);
    let stockRequestProductListTemp = await getStockRequestProductList(id, requestType, productTypes);
    if (stockRequestProductListTemp) {
      let stockRequestProductListContent = stockRequestProductListTemp.map((valueProductList) => {
        return ({
          productId: valueProductList.productId,
          productName: valueProductList.productName,
          adviseOrderQuantity: requestType === Constants.outlet ? valueProductList.adviseOrderQuantity :  Math.trunc(valueProductList.adviseOrderQuantity/valueProductList.unitConvertionRate),
          currentQuantity: valueProductList.currentQuantity,
          processingOrderQuantity: valueProductList.processingOrderQuantity, 
          orderQuantity: requestType === Constants.outlet ? valueProductList.adviseOrderQuantity : Math.trunc(valueProductList.adviseOrderQuantity/valueProductList.unitConvertionRate), //This field is to use advice quantity,
          minimumThreshold: valueProductList.minimumThreshold,
          maxReorderQuantityDisplay: valueProductList.maxReorderQuantity,
          maxReorderQuantity: requestType === Constants.outlet ? valueProductList.maxReorderQuantity : Math.trunc(valueProductList.maxReorderQuantity/valueProductList.unitConvertionRate),
          measurementName: requestType === Constants.outlet && valueProductList.availableMeasurements.length > 0 ? valueProductList.availableMeasurements[0].name : `${valueProductList.measurementName} ${valueProductList.measurementName !== Constants.unit ? `(${valueProductList.unitConvertionRate} Unit)` : ""}`,
          measurementId: requestType === Constants.outlet && valueProductList.availableMeasurements.length > 0 ? valueProductList.availableMeasurements[0].id : valueProductList.measurementId,
          availableMeasurements: valueProductList.availableMeasurements,
          unitConvertionRate: requestType === Constants.outlet ? 1 : valueProductList.unitConvertionRate,
          remark: valueProductList.remark,
          disabled: blnHq
        })
      })
      validation.setFieldValue("stockRequestDetails", stockRequestProductListContent)
      setProductStockRequestlist(stockRequestProductListContent);
    }
    if (removeData) {
      validation.setFieldValue("stockRequestDetails", [])
    }
    setStockRequestLoading(false);
  };

  useEffect(() => {
    if (blnHQRequestType) {
      validation.setFieldValue("fromBranchId", undefined);
    }
    else {
      validation.setFieldValue("fromSupplierId", undefined);
    }
  }, [blnHQRequestType, blnOutletRequestType]);

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 2) {
        setActiveTab(tab)
      }
    }
  }

  const stockRequestDetailsValidation = () => {
    let blnEmptyData = false;
    let blnOrderQuantityOverAdviseQuantity = false;

    let stockRequestDetailsTemp : StockRequestForProductDetailsObject[] = _.cloneDeep(validation.values.stockRequestDetails);
    if (stockRequestDetailsTemp.length > 0) {
      stockRequestDetailsTemp = stockRequestDetailsTemp.map((valueStockRequestDetailsTemp: StockRequestForProductDetailsObject) => {
        if (!valueStockRequestDetailsTemp.orderQuantity) {
          blnEmptyData = true;
          return ({...valueStockRequestDetailsTemp, backgroundColor: Constants.lightorange})
        }
        if (valueStockRequestDetailsTemp.orderQuantity > valueStockRequestDetailsTemp.adviseOrderQuantity && !removeAllSpace(valueStockRequestDetailsTemp.remark)) {
          blnOrderQuantityOverAdviseQuantity = true;
          return ({...valueStockRequestDetailsTemp, backgroundColor: blnRemarkMandatoryColor})
        }
        return ({...valueStockRequestDetailsTemp, backgroundColor: ""})
      })
    }
    else {
      blnEmptyData = true;
    }

    if (blnEmptyData || blnOrderQuantityOverAdviseQuantity) {
      validation.setFieldValue("stockRequestDetails", stockRequestDetailsTemp)
      if (blnEmptyData) {
        setErrorMessage(`${intl.formatMessage({ id: "EmptyQuantity" }, {field: "Order"})}`) 
      }
      if (blnOrderQuantityOverAdviseQuantity) {
        setErrorMessage(`${intl.formatMessage({ id: "OrderQuantityOverAdviceQuantityErrorMessage" })}`) 
      }
    }
    
    return blnEmptyData || blnOrderQuantityOverAdviseQuantity;
  }

  const nextButtonDisable = () => {
    if (!validation.values.requestType) {
      return true;
    }
    else if (blnOutletRequestType && !validation.values.fromBranchId) {
      return true;
    }
    else if (blnStockTransferType) {
      return false;
    }
    else if (validation.values.productTypes.length === 0) {
      return true;
    }
    return false;
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <div className="wizard clearfix">
              <div className="steps clearfix">
                <ul>
                  <NavItem
                    className={classNames({ current: blnTab1 })}
                  >
                    <NavLink
                      className={classNames({ current: blnTab1 })}
                      onClick={() => {
                        setActiveTab(1)
                      }}
                      disabled={true}
                    >
                      <span className="number">1.</span> {intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "StockRequest" }) })}
                    </NavLink>
                  </NavItem>
                  <NavItem
                    className={classNames({ current: blnTab2 })}
                  >
                    <NavLink
                      className={classNames({ active: blnTab2 })}
                      onClick={() => {
                        setActiveTab(2)
                      }}
                      disabled={true}
                    >
                      <span className="number">2.</span> {intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Products" }) })}
                    </NavLink>
                  </NavItem>
                </ul>
              </div>
              <div className="content clearfix">
                <TabContent
                  activeTab={activeTab}
                  className="body"
                >
                  <TabPane tabId={1}>
                    <Row form={+true}>
                      {" "}
                      <Col xl={12}>
                        <LineBreakWithTittle
                          paddingBottom="0px"
                          title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "StockRequest" }) })}
                          h4Title
                        />
                        {
                          !addAction
                          &&
                          <SingleColumnRowParent>
                            <GeneralInput
                              title={intl.formatMessage({ id: "RequestedBranch" })}
                              name="requestBranchName"
                              className="mb-3 mt-4"
                              type="text"
                              disabled={true}
                              validationRequired={false}
                              validation={validation}
                            />
                          </SingleColumnRowParent>
                        }
                        <SingleColumnRowParent >
                          <DropDownWithTitle
                            name={"requestType"}
                            className={addAction ? "mb-3 mt-4" : ""}
                            title={intl.formatMessage({ id: "RequestType" })}
                            specifyReturnFieldName={[
                              {
                                field: "requestType",
                                value: "displayValue",
                              },
                            ]}
                            labelField={"displayValue"}
                            valueField={"displayValue"}
                            options={localStockRequestPurchaserType}
                            disabled={disabledFieldInput || viewAction || !addAction || isStockRequestPreloadProductRedemption}
                            onChangeFunction={onChangeRequestType}
                            blnValueWithNewSpace={isStockRequestPreloadProductRedemption}
                            initialValue={!addAction || isRecreateStockRequest || isStockRequestPreloadProductRedemption ? validation.values.requestType : undefined}
                            validationRequired={true}
                            validation={validation}
                          />
                        </SingleColumnRowParent>
                        {
                          blnOutletRequestType && (
                          <SingleColumnRowParent>
                            <DropDownWithTitle
                              name={"fromBranchId"}
                              title={intl.formatMessage({ id: "FromBranch" })}
                              specifyReturnFieldName={[
                                {
                                  field: "fromBranchId",
                                  value: "id",
                                }
                              ]}
                              labelField={"name"}
                              valueField={"id"}
                              options={branchDropDownList}
                              blnValueWithNewSpace={true}
                              disabled={disabledFieldInput || viewAction || !addAction || isStockRequestPreloadProductRedemption}
                              initialLabel={!addAction || isRecreateStockRequest || isStockRequestPreloadProductRedemption ? validation.values.fromBranchName : undefined}
                              initialValue={!addAction || isRecreateStockRequest || isStockRequestPreloadProductRedemption ? validation.values.fromBranchId : undefined}
                              validationRequired={true}
                              validation={validation}
                            />
                          </SingleColumnRowParent>
                        )}
                        {
                          !isStockRequestPreloadProductRedemption
                          &&
                          <SingleColumnRowParent>
                            <DropDownWithTitleMultiSelect
                              name={"productTypes"}
                              title={intl.formatMessage({ id: "ProductTypes" })}
                              specifyReturnFieldName={[{ "field": "productTypes", "value": "key" }]}
                              returnFieldWithLabel={false}
                              labelField={"displayValue"}
                              valueField={"key"}
                              options={productType}
                              disabled={disabledFieldInput || viewAction || !addAction || isStockRequestPreloadProductRedemption}
                              blnValueWithNewSpace={true}
                              initialValue={selectedInitialProductTypes}
                              onChangeFunction={()=> {
                                setIsRecreateWithProductTypeChanges(true);
                              }}
                              validationRequired={true}
                              validation={validation} />
                          </SingleColumnRowParent>
                        }
                        <SingleColumnRowParent>
                          <GeneralTextArea
                            title={intl.formatMessage({ id: "Note" })}
                            name="note"
                            row={5}
                            disabled={disabledFieldInput || viewAction}
                            validation={validation}
                          />
                        </SingleColumnRowParent>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId={2}>
                    {
                      !isStockRequestPreloadProductRedemption
                      &&
                      <Row className="margin-bottom-16">
                        <Col xl="6" md="12" xs="12" className="flex-align-items-center-without-height">
                          <div className="margin-left--16px">
                            {displayTypeWithColor(Constants.lightorange, intl.formatMessage({id: "EmptyQuantityDisplay"}))}
                          </div>
                          {displayTypeWithColor(Constants.lightpink, intl.formatMessage({id: "OrderQuantiyPlusCurrentQuantityExceedIdealQuantity"}))}
                        </Col>
                        <Col xl="6" md="12" xs="12">
                          <div className="flex-1 flex-right">
                            <MyButton
                              type="button"
                              class={`btn btn-warning margin-right-8`}
                              content={intl.formatMessage({ id: "ClearQuantity" })}
                              disable={
                                validation.isSubmitting
                              }
                              loading={
                                validation.isSubmitting
                              }
                              onClick={() => {
                                let stockRequestDetailsTemp : any = _.cloneDeep(validation.values.stockRequestDetails);
                                stockRequestDetailsTemp = stockRequestDetailsTemp.map((valueStockRequestDetailsTemp)=> ({
                                  ...valueStockRequestDetailsTemp,
                                  orderQuantity: 0
                                }));
                                validation.setFieldValue("stockRequestDetails", stockRequestDetailsTemp);
                              }}
                            />
                            <MyButton
                              type="button"
                              class={`btn btn-warning margin-right-8`}
                              content={intl.formatMessage({ id: "ClearProduct" })}
                              disable={
                                validation.isSubmitting
                              }
                              loading={
                                validation.isSubmitting
                              }
                              onClick={() => {
                                let productStockRequestlistTemp : any = _.cloneDeep(productStockRequestlist);
                                productStockRequestlistTemp = productStockRequestlistTemp.map((valueProductStockRequestlistTemp)=> ({
                                  ...valueProductStockRequestlistTemp,
                                  disabled: false
                                }));
                                setProductStockRequestlist(productStockRequestlistTemp);
                                validation.setFieldValue("stockRequestDetails", []);
                              }}
                            />
                            <MyButton
                              type="button"
                              class={`btn btn-warning margin-right-8`}
                              content={intl.formatMessage({ id: "ClearWarningProduct" })}
                              disable={
                                validation.isSubmitting
                              }
                              loading={
                                validation.isSubmitting
                              }
                              onClick={() => {
                                setStockRequestLoading(true);
                                let productStockRequestlistTemp : any = _.cloneDeep(productStockRequestlist);
                                let productStockRequestDetailslistTemp : any = _.cloneDeep(validation.values.stockRequestDetails);
                                let productStockRequestlistFinalTemp : any = [];
                                productStockRequestlistTemp = productStockRequestlistTemp.map((valueProductStockRequestlistTemp)=> {
                                  let blnDisabled = false;
                                  let indexProduct = _.findIndex(productStockRequestDetailslistTemp, {productId: valueProductStockRequestlistTemp.productId})
                                  if (indexProduct > -1) {
                                    if (!productStockRequestDetailslistTemp[indexProduct].backgroundColor || productStockRequestDetailslistTemp[indexProduct].backgroundColor === blnRemarkMandatoryColor) {
                                      productStockRequestlistFinalTemp.push(productStockRequestDetailslistTemp[indexProduct])
                                      blnDisabled = true;
                                    }
                                  }

                                  return ({
                                    ...valueProductStockRequestlistTemp,
                                    disabled: blnDisabled,
                                    backgroundColor: ""
                                  })
                                });
                                setProductStockRequestlist(productStockRequestlistTemp);
                                validation.setFieldValue("stockRequestDetails", productStockRequestlistFinalTemp);
                                setTimeout(()=> {
                                  setStockRequestLoading(false);
                                }, 10)
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    }
                    <Col>
                      <LineBreakWithTittle
                        paddingBottom="0px"
                        title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Products" }) })}
                        h4Title
                      />
                      <TableWithEditableInputFields
                        name="stockRequestDetails"
                        title=""
                        buttonTitle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Product" }) })}
                        blnEditable={!viewAction}
                        blnSupportWithModal={!isStockRequestPreloadProductRedemption}
                        blnBtnModalBottom={!isStockRequestPreloadProductRedemption}
                        hideAddButton={isStockRequestPreloadProductRedemption}
                        hideDeleteButton={isStockRequestPreloadProductRedemption}
                        fixedHeader={true}
                        windowSizeHeight={windowSize.innerHeight/2.5}
                        supportBackgroundColor={true}
                        options={productStockRequestlist}
                        optionsId={"productId"}
                        setOptionsFunction={setProductStockRequestlist}
                        addButtonFunction={() => setProductModal(true)}
                        aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTab}
                        data={validation.values.stockRequestDetails}
                        validation={validation}
                        loading={loadingStockRequest}
                        disabled={disabledFieldInput} />
                    </Col>
                  </TabPane>
                </TabContent>
              </div>
              <div className="actions clearfix">
                <ul>
                  <li>
                    {blnTab2 && !Boolean(successMessage) &&
                      <MyButton
                        type="button"
                        class="btn btn-primary"
                        style={{ width: '100%' }}
                        content={intl.formatMessage({ id: "Previous" })}
                        disable={
                          nextButtonDisable()
                        }
                        loading={
                          validation.isSubmitting
                        }
                        onClick={() => {
                          toggleTab(activeTab - 1)
                        }}
                      />
                    }
                  </li>
                  <li>
                    {blnTab1 &&
                      <MyButton
                        type="button"
                        class="btn btn-primary"
                        style={{ width: '100%' }}
                        content={intl.formatMessage({ id: "Next" })}
                        disable={
                          nextButtonDisable()
                        }
                        loading={
                          validation.isSubmitting || loadingStockRequest
                        }
                        onClick={async () => {
                          try {
                            if (addAction && (!isRecreateStockRequest || (isRecreateStockRequest && isRecreateWithProductTypeChanges))) {
                              await getProductWithLoading(getBranchId(), !blnHQRequestType, blnHQRequestType, validation.values.requestType, validation.values.productTypes);
                            }
                          }
                          finally {
                            toggleTab(activeTab + 1)
                          }
                        }}
                      />
                    }
                  </li>
                </ul>
              </div>

            </div>
            <div className="mt-3" />
            <div className="flex-direction-row d-print-none">
              {/* {!Boolean(successMessage) && blnStatusPending && blnTab2 &&
                <MyButton
                  type="button"
                  class={`btn btn-warning margin-right-8`}
                  content={intl.formatMessage({ id: "Cancel" })}
                  disable={
                    validation.isSubmitting
                  }
                  loading={
                    validation.isSubmitting
                  }
                  onClick={() => {
                    setShowCancelPrompt(true);
                  }}
                />
              } */}
              <GeneralSubmitAndCancelBtn
                successMessage={successMessage}
                viewAction={viewAction}
                validation={validation}
                dirtyDisabled={true}
                showExtraLeftButton={!isStockRequestPreloadProductRedemption}
                extraLeftButtonTitle={intl.formatMessage({ id: "Save" })}
                extraLeftButtonColor="btn-primary"
                extraLeftButtonFunction={() => {
                  validation.setFieldValue("workflowAction", 'Save')
                }}
                hidePrimaryButton={blnStatusProcessing || blnTab1}
                submitButtonTitle={intl.formatMessage({ id: "Submit" })}
                onClickFunction={() => {
                  validation.setFieldValue("workflowAction", 'Submit')
                }}
                extraRightButtonColor="btn-warning"
                extraRightButtonTitle={intl.formatMessage({ id: "Cancel" })}
                extraRightButtonFunction={() => {setShowCancelPrompt(true)}}
                extraRightDirtyDisabled={true}
                extraRightButtonWithoutForm={true}
                showExtraRightButton={blnStatusPending && blnTab2}
              />
            </div>
          </Form>
        )}
        <SelectDropDownModal
          blnShow={productModal}
          setModal={setProductModal}
          title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "Product" }) })}
          buttonTittle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Product" }) })}
          placeholder={"- Please Select -"}
          labelField={"productName"}
          valueField={"productId"}
          options={AddOptionsValueField(productStockRequestlist, "name", "id")}
          setOptionsFunction={setProductStockRequestlist}
          fieldInput={validation.values.stockRequestDetails}
          fieldName={"stockRequestDetails"}
          validation={validation} />
        <CancelModal
          onCancelClick={async () => {
            validation.setFieldValue("workflowAction", Constants.cancel);
            validation.submitForm();
          }} />
      </DetailViewLayout>
    </div >
  );
};

export default observer(StockRequestDetail);
