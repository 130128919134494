import { useEffect, useRef, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Label,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { arraySpliceInAllValue, checkPermission, firstDayOfMonthToCurrentDay, getBranchId, getBranchName, getBranchUser, newSpaceBeforeCapitalLetter, returnPriceWithCurrency, returnSubBrandList, returnSwitchTabButtonUI } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { DateRangeInterface, ObjectForDropdown } from "../../../app/models/common";
import Loading from "../../../app/components/loading/Loading";
import _ from "lodash";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import MyButton from "../../../app/components/form/MyButton";
import CardInfo from "../../../app/components/form/CardInfo";
import CashCheckIcon from "mdi-react/CashCheckIcon";
import CashMultipleIcon from "mdi-react/CashMultipleIcon";
import { Link } from 'react-router-dom';
import { BranchListObject } from "../../../app/models/branch";
import moment from "moment";
import PaginationTableWithoutApi from "../../../app/components/table/PaginationTableWithoutApi";
import { SubBrandObject } from "../../../app/models/brand";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import GeneralInput from "../../../app/components/form/GeneralInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { OrderByPropertiesObject } from "../../../app/models/pagination";
import SortCaret from "../../../app/components/table/SortCaret";
import { useLocation } from 'react-router-dom';
import { history } from "../../../";
import GeneralInputWithCallAPI from "../../../app/components/form/GeneralInputWithCallAPI";

const PaymentCollection = () => {
  const intl = useIntl();
  //Use Store
  const { reportAccountStore, branchStore, settingsStore, paymentMethodStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { paymentCollection, paymentCollectionDetailList, getPaymentCollection, getPaymentCollectionDetailList, exportPaymentCollection, setPaymentCollection, setPaymentCollectionDetailList } = reportAccountStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { generalSettings, getGeneralSettings } = settingsStore;
  const { getPaymentMethodDropdown } = paymentMethodStore;

  //Data List
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [branchDropDownListFinal, setBranchDropDownListFinal] = useState<BranchListObject[]>([]);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const AllSubBrand = `${intl.formatMessage({ id: "AllSubBrand" })}`;
  const [selectedSubBrandName, setSelectedSubBrandName] = useState(AllSubBrand);
  const [selectedSubBrandId, setSelectedSubBrandId] = useState(" ");
  const [subBrandListFinal, setSubBrandListFinal] = useState<SubBrandObject[]>([]);
  const All = `${intl.formatMessage({ id: "All" })}`;
  const [paymentMethodListFinal, setPaymentMethodListFinal] = useState<ObjectForDropdown[]>([]);
  const [selectedPaymentMethodName, setSelectedPaymentMethodName] = useState(All);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(" ");
  const [selectedOrderByProperties, setSelectedOrderByProperties] = useState<OrderByPropertiesObject[]>(([]));
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingDropdownRefresh, setLoadingDropdownRefresh] = useState(false);
  const [localLoadingPaymentCollectionDetail, setLocalLoadingPaymentCollectionDetail] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const blnPermissionExportAccountReport = checkPermission([PermissionConstants.ExportAccountReport]);
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionManageCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);
  const blnPermissionManageAppolousSales = checkPermission([PermissionConstants.ManageAppolousSales]);
  const [blnAppolousPayment, setBlnAppolousPayment] = useState(false);
  const [blnAllActive, setBlnAllActive] = useState(true);
  const [blnDisplayTotalPaymentReceived, setBlnDisplayTotalPaymentReceived] = useState(false);
  const refTablePaymentCollectionDetail : any = useRef(null);
  const blnShowAppolous = generalSettings?.isShowAppolous;
  const paramsSearch = useLocation().search;

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      isIncludeCreditPayment: true,
      searchValue: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });
  
  const paymentCollectionDetailListRequestBody = { 
    pageNumber: 1, 
    pageSize: pageSize, 
    branchId: selectedBranchId === " " ? undefined : selectedBranchId, 
    dateRange: selectedDateRange, 
    subBrandId: selectedSubBrandId === " " ? undefined : selectedSubBrandId, 
    isIncludeCreditPayment: validation.values.isIncludeCreditPayment, 
    ...!blnAllActive && {isAppolousPayment: blnAppolousPayment}, 
    paymentMethodId: selectedPaymentMethodId === " " ? undefined : selectedPaymentMethodId, 
  }

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchPaymentCollection() {
      setLocalLoading(true);
      setLoadingDropdownRefresh(true);

      let aryAPI: any = [
        getBranchDropDown(),
        getGeneralSettings(),
        getPaymentMethodDropdown()
      ];

      let resultAPI = await Promise.all(aryAPI);
      if (returnSubBrandList().length > 0) {
        setSubBrandListFinal(arraySpliceInAllValue(returnSubBrandList(), AllSubBrand, false, "name", "id"));
      }
      setBranchDropDownListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))
      setPaymentMethodListFinal(arraySpliceInAllValue(resultAPI[2], All, false, "name", "id"));

      const branchIdParams = new URLSearchParams(paramsSearch).get('branchId');
      const fromDateParams = new URLSearchParams(paramsSearch).get('fromDate');
      const toDateParams = new URLSearchParams(paramsSearch).get('toDate');
      const subBrandIdParams = new URLSearchParams(paramsSearch).get('subBrandId');

      if (fromDateParams && toDateParams) {
        let fromDateTemp = moment(fromDateParams, Constants.defaultDateFormat).format(Constants.displayDateFormat);
        let toDateTemp = moment(toDateParams, Constants.defaultDateFormat).format(Constants.displayDateFormat);

        let indexBranch = _.findIndex(resultAPI[0], { id: branchIdParams });
        if (indexBranch > -1) {
          setSelectedBranchId(resultAPI[0][indexBranch].id);
          setSelectedBranchName(resultAPI[0][indexBranch].name);
        }

        if(subBrandIdParams && subBrandIdParams !== " "){
          let indexSubBrandId = _.findIndex(returnSubBrandList(), { id: subBrandIdParams})
          if (indexSubBrandId > -1) {
            let subBrandListTemp: any = _.cloneDeep(returnSubBrandList());
            setSelectedSubBrandId(subBrandListTemp[indexSubBrandId].id);
            setSelectedSubBrandName(subBrandListTemp[indexSubBrandId].name);
          }
        }

        setSelectedDateRange({fromDate: fromDateTemp, toDate: toDateTemp});

        validation.setFieldValue("isIncludeCreditPayment", false);
        history.replace({ pathname: `/${RoutesList.report}/${RoutesList.paymentCollection}` });
        let aryAPI = [
          getPaymentCollection({ pageNumber: 1, pageSize: pageSize, branchId: branchIdParams || selectedBranchId, dateRange: {fromDate: fromDateTemp, toDate: toDateTemp}, subBrandId: subBrandIdParams || selectedSubBrandId, isIncludeCreditPayment: false, orderByProperties: selectedOrderByProperties}),
          getPaymentCollectionDetailList({ pageNumber: 1, pageSize: pageSize, branchId: (branchIdParams || selectedBranchId) === " " ? undefined : branchIdParams || selectedBranchId, dateRange: {fromDate: fromDateTemp, toDate: toDateTemp}, subBrandId: (subBrandIdParams || selectedSubBrandId) === " " ? undefined : subBrandIdParams || selectedSubBrandId, isIncludeCreditPayment: false, orderByProperties: selectedOrderByProperties})
        ]
        await Promise.all(aryAPI);
      }

      setLocalLoading(false);
      setLoadingDropdownRefresh(false);
    }

    if (!checkPermission([PermissionConstants.ManageAccountReport], true)) {
      return;
    }

    fetchPaymentCollection();

    return (() => {
      setPaymentCollection(undefined);
    })
  }, [])

  const fetchPaymentCollectionWithLoading = async (blnExport: boolean, orderByPropertiesTemp: OrderByPropertiesObject[] | undefined = undefined) => {
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }

    setLocalLoading(true);
    if (blnExport) {
      await exportPaymentCollection({ branchId: selectedBranchId === " " ? undefined : selectedBranchId, fromDate: decodeURIComponent(selectedDateRange.fromDate), toDate: decodeURIComponent(selectedDateRange.toDate), subBrandId: selectedSubBrandId, isIncludeCreditPayment: validation.values.isIncludeCreditPayment, ...!blnAllActive && {isAppolousPayment: blnAppolousPayment}, orderByProperties: orderByPropertiesTemp || selectedOrderByProperties, paymentMethodId: selectedPaymentMethodId})
    }
    else {
      setBlnDisplayTotalPaymentReceived(validation.values.isIncludeCreditPayment);
      setCurrentPage(1);
      validation.setFieldValue("searchValue", "");
      let aryFetchAPI = [
        getPaymentCollection({ pageNumber: 1, pageSize: pageSize, branchId: selectedBranchId, dateRange: selectedDateRange, subBrandId: selectedSubBrandId, isIncludeCreditPayment: validation.values.isIncludeCreditPayment, ...!blnAllActive && {isAppolousPayment: blnAppolousPayment}, orderByProperties: orderByPropertiesTemp || selectedOrderByProperties, paymentMethodId: selectedPaymentMethodId}),
        getPaymentCollectionDetailList({...paymentCollectionDetailListRequestBody, orderByProperties: orderByPropertiesTemp || selectedOrderByProperties})
      ]
      await Promise.all(aryFetchAPI);
    }
    setLocalLoading(false);
    if (orderByPropertiesTemp && refTablePaymentCollectionDetail) {
      refTablePaymentCollectionDetail?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  //Table Content UI
  const collectionsSummaryDetailsColumns = [
    {
      dataField: "paymentMethodName",
      text: intl.formatMessage({ id: "PaymentMethod" }).toUpperCase(),
    },
    {
      dataField: "totalAmount",
      text: intl.formatMessage({ id: "TotalAmount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {returnPriceWithCurrency(row.totalAmount)}
        </>
      ),
    }
  ];

  const returnSortOrder = (fieldName) => {
    return selectedOrderByProperties.length > 0 ? selectedOrderByProperties[0].orderByPropertyName === fieldName ? selectedOrderByProperties[0].orderByDirection : "" : "";
  }

  const sortOrderFunction = (fieldName, valueDirection) => {
    let aryOrderByPropertiesTemp = [{
      orderByPropertyName: fieldName,
      orderByDirection: valueDirection
    }]
    setSelectedOrderByProperties(aryOrderByPropertiesTemp)
    setLocalLoadingPaymentCollectionDetail(true);
    fetchPaymentCollectionDetailListWithLoading(validation.values.searchValue, aryOrderByPropertiesTemp);
  }

  const fetchPaymentCollectionDetailListWithLoading = async (searchValueTemp?: string, aryOrderByPropertiesTemp?: OrderByPropertiesObject[]) => {
    setCurrentPage(1);
    let resultPaymentCollectionDetailList = await getPaymentCollectionDetailList({...paymentCollectionDetailListRequestBody, orderByProperties: aryOrderByPropertiesTemp || selectedOrderByProperties, searchValue: searchValueTemp})
    if (resultPaymentCollectionDetailList?.status) {
      setLocalLoadingPaymentCollectionDetail(false);
    }
  }

  const paymentCollectionColumns = [
    {
      dataField: "paymentDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      style: {minWidth: "120px"},
      headerFormatter: (cellContent, row) => {
        return (
          <SortCaret 
            title={intl.formatMessage({ id: "Date" }).toUpperCase()}
            order={returnSortOrder("PaymentDate")} 
            onClickFunction={(valueDirection)=> {
              sortOrderFunction("PaymentDate", valueDirection);
            }}/>
        )
      }
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
      headerFormatter: (cellContent, row) => {
        return (
          <SortCaret 
            title={intl.formatMessage({ id: "Branch" }).toUpperCase()}
            order={returnSortOrder("BranchName")} 
            onClickFunction={(valueDirection)=> {
              sortOrderFunction("BranchName", valueDirection);
            }}/>
        )
      },
      formatter: (cellContent, row) => (
        <div>
          {row.branchName || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      headerFormatter: (cellContent, row) => {
        return (
          <SortCaret 
            title={intl.formatMessage({ id: "Name" }).toUpperCase()}
            order={returnSortOrder("CustomerName")} 
            onClickFunction={(valueDirection)=> {
              sortOrderFunction("CustomerName", valueDirection);
            }}/>
        )
      },
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionViewCustomer
            ?
            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target="_blank" rel="noopener noreferrer">
              <Label className="margin-bottom-0 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.customerName || Constants.emptyData}</div>
          }          
        </>
      ),
    },
    {
      dataField: "paidAmount",
      text: intl.formatMessage({ id: "PaidAmount" }).toUpperCase(),
      headerFormatter: (cellContent, row) => {
        return (
          <SortCaret 
            title={intl.formatMessage({ id: "PaidAmount" }).toUpperCase()}
            order={returnSortOrder("PaidAmount")} 
            onClickFunction={(valueDirection)=> {
              sortOrderFunction("PaidAmount", valueDirection);
            }}/>
        )
      },
      formatter: (cellContent, row) => (
        <div>
          {returnPriceWithCurrency(row.paidAmount) || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "paymentMethodName",
      text: intl.formatMessage({ id: "PaymentMethod" }).toUpperCase(),
      headerFormatter: (cellContent, row) => {
        return (
          <SortCaret 
            title={intl.formatMessage({ id: "PaymentMethod" }).toUpperCase()}
            order={returnSortOrder("PaymentMethodName")} 
            onClickFunction={(valueDirection)=> {
              sortOrderFunction("PaymentMethodName", valueDirection);
            }}/>
        )
      },
      formatter: (cellContent, row) => (
        <div>
          {newSpaceBeforeCapitalLetter(row.paymentMethodName) || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "optionName",
      text: intl.formatMessage({ id: "PaymentMethodOption" }).toUpperCase(),
      headerFormatter: (cellContent, row) => {
        return (
          <SortCaret 
            title={intl.formatMessage({ id: "PaymentMethodOption" }).toUpperCase()}
            order={returnSortOrder("OptionName")} 
            onClickFunction={(valueDirection)=> {
              sortOrderFunction("OptionName", valueDirection);
            }}/>
        )
      },
      formatter: (cellContent, row) => (
        <div>
          {row.optionName || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "cardType",
      text: intl.formatMessage({ id: "CardType" }).toUpperCase(),
      headerFormatter: (cellContent, row) => {
        return (
          <SortCaret 
            title={intl.formatMessage({ id: "CardType" }).toUpperCase()}
            order={returnSortOrder("CardType")} 
            onClickFunction={(valueDirection)=> {
              sortOrderFunction("CardType", valueDirection);
            }}/>
        )
      },
      formatter: (cellContent, row) => (
        <div>
          {row.cardType || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "last4CardDigit",
      text: intl.formatMessage({ id: "Last4CardDigit" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.last4CardDigit || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "issuerBankName",
      text: intl.formatMessage({ id: "IssuerBank" }).toUpperCase(),
      headerFormatter: (cellContent, row) => {
        return (
          <SortCaret 
            title={intl.formatMessage({ id: "IssuerBank" }).toUpperCase()}
            order={returnSortOrder("IssuerBankName")} 
            onClickFunction={(valueDirection)=> {
              sortOrderFunction("IssuerBankName", valueDirection);
            }}/>
        )
      },
      formatter: (cellContent, row) => (
        <div>
          {row.issuerBankName || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "isEPP",
      text: intl.formatMessage({ id: "IsEPP" }).toUpperCase(),
      headerFormatter: (cellContent, row) => {
        return (
          <SortCaret 
            title={intl.formatMessage({ id: "IsEPP" }).toUpperCase()}
            order={returnSortOrder("IsEPP")} 
            onClickFunction={(valueDirection)=> {
              sortOrderFunction("IsEPP", valueDirection);
            }}/>
        )
      },
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isEPP ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "eppMonth",
      text: intl.formatMessage({ id: "EPPMonths" }).toUpperCase(),
      headerFormatter: (cellContent, row) => {
        return (
          <SortCaret 
            title={intl.formatMessage({ id: "EPPMonths" }).toUpperCase()}
            order={returnSortOrder("EPPMonth")} 
            onClickFunction={(valueDirection)=> {
              sortOrderFunction("EPPMonth", valueDirection);
            }}/>
        )
      },
      formatter: (cellContent, row) => (
        <div>
          {row.eppMonth || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "paymentTerminalName",
      text: intl.formatMessage({ id: "Terminal" }).toUpperCase(),
      headerFormatter: (cellContent, row) => {
        return (
          <SortCaret 
            title={intl.formatMessage({ id: "Terminal" }).toUpperCase()}
            order={returnSortOrder("PaymentTerminalName")} 
            onClickFunction={(valueDirection)=> {
              sortOrderFunction("PaymentTerminalName", valueDirection);
            }}/>
        )
      },
      formatter: (cellContent, row) => (
        <div>
          {row.paymentTerminalName || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "paymentDetails",
      text: intl.formatMessage({ id: "Details" }).toUpperCase(),
      headerFormatter: (cellContent, row) => {
        return (
          <SortCaret 
            title={intl.formatMessage({ id: "Details" }).toUpperCase()}
            order={returnSortOrder("PaymentDetails")} 
            onClickFunction={(valueDirection)=> {
              sortOrderFunction("PaymentDetails", valueDirection);
            }}/>
        )
      },
      formatter: (cellContent, row) => (
        <div>
          {row.paymentDetails || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "documentNos",
      text: `${intl.formatMessage({ id: "DocumentNo" }).toUpperCase()}.`,
      headerFormatter: (cellContent, row) => {
        return (
          <SortCaret 
            title={intl.formatMessage({ id: "DocumentNo" }).toUpperCase()}
            order={returnSortOrder("DocumentNos")} 
            onClickFunction={(valueDirection)=> {
              sortOrderFunction("DocumentNos", valueDirection);
            }}/>
        )
      }
    }
  ];

  const blnViewAllFunction = () => {
    setBlnAllActive(true);
  }

  const blnERPOnlyFunction = () => {
    setBlnAllActive(false);
    setBlnAppolousPayment(false);
  }

  const blnAppolousOnlyFunction = () => {
    setBlnAllActive(false);
    setBlnAppolousPayment(true);
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "PaymentCollection" }) })}
        title={intl.formatMessage({ id: "PaymentCollection" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportAccountReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchPaymentCollectionWithLoading(true)
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Payment-Collection.aspx`}>
        {
          !loadingDropdownRefresh
          &&
          <>
            <Row>
              {
                subBrandListFinal.length > 0
                &&
                <Col xl="3">
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "SubBrand" })}
                    name="subBrandName"
                    options={subBrandListFinal}
                    labelField="name"
                    valueField="id"
                    initialLabel={selectedSubBrandName}
                    initialValue={selectedSubBrandId}
                    disabled={localLoading || loading}
                    setFieldLabel={setSelectedSubBrandName}
                    setFieldValue={setSelectedSubBrandId}
                    validationRequired={true}
                  />
                </Col>
              }
              <Col xl="3">
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Branch" })}
                  name="branchId"
                  className={`${windowSize.innerWidth > Constants.innerWidthCompare ? "mb-0" : ""}`}
                  options={branchDropDownListFinal}
                  initialLabel={selectedBranchName}
                  initialValue={selectedBranchId}
                  disabled={localLoading || loading || getBranchUser()}
                  setFieldLabel={setSelectedBranchName}
                  setFieldValue={setSelectedBranchId}
                  validationRequired={true}
                />
              </Col>
              <Col xl="3">
                <GeneralDateRange
                  title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "PaymentDate"})})}
                  name={"DateRange"}
                  disabled={localLoading || loading}
                  onChangeFunction={onSelectDateRange}
                  validationRequired={true}
                  initialValue={selectedDateRange} />
              </Col>
              <Col xl="3">
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "PaymentMethod" })}
                  name="paymentMethodId"
                  options={paymentMethodListFinal}
                  labelField="name"
                  valueField="id"
                  initialLabel={selectedPaymentMethodName}
                  initialValue={selectedPaymentMethodId}
                  setFieldLabel={setSelectedPaymentMethodName}
                  setFieldValue={setSelectedPaymentMethodId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                />
              </Col>
            </Row>
            <Row>
              {
                blnShowAppolous
                &&
                <DoubleColumnRowParent>
                  <div className="btn-group" role="group">
                    {returnSwitchTabButtonUI("blnViewAll", "blnViewAll", blnAllActive, loading || localLoading, intl.formatMessage({ id: "All" }), blnViewAllFunction, 1790 > windowSize.innerWidth && windowSize.innerWidth > 1200 ? "70px" : "")}
                    {returnSwitchTabButtonUI("blnNotAppolous", "blnNotAppolousTrue", !blnAppolousPayment && !blnAllActive, loading || localLoading, intl.formatMessage({ id: "ERP" }), blnERPOnlyFunction, 1790 > windowSize.innerWidth && windowSize.innerWidth > 1200 ? "70px" : "")}
                    {returnSwitchTabButtonUI("blnAppolous", "blnAppolousTrue", blnAppolousPayment && !blnAllActive, loading || localLoading, intl.formatMessage({ id: "Appolous" }), blnAppolousOnlyFunction, 1790 > windowSize.innerWidth && windowSize.innerWidth > 1200 ? "90px" : "")}
                  </div>
                </DoubleColumnRowParent>
              }
              <DoubleColumnRowParent>
                <GeneralInput
                  title={intl.formatMessage({ id: "IncludeCreditPayment" })}
                  name="isIncludeCreditPayment"
                  className=""
                  type="checkbox"
                  disabled={localLoading || loading}
                  validation={validation}
                  childrenUI={
                    <Label className="margin-bottom-0 margin-left-4">
                      Yes
                    </Label>
                  }
                />
              </DoubleColumnRowParent>
              <Col xl="2">
                <MyButton
                  type="button"
                  class={`btn btn-primary margin-bottom-18 `}
                  content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                  loading={localLoading || loading}
                  disable={localLoading || loading}
                  onClick={() => {
                    fetchPaymentCollectionWithLoading(false)
                  }}
                />
              </Col>
            </Row>
          </>
        }
        {
          localLoading
          ?
          <Loading />
          :
          <>
            {
              <Row >
                <Col xl={paymentCollection ? "7" : "12"}>
                  <div className={`standard-layout`}>
                    <PaginationTableWithoutApi
                      title={intl.formatMessage({ id: "PaymentCollection" })}
                      blnDisplayForMainListing={true}
                      keyField={"paymentMethodName"}
                      columns={collectionsSummaryDetailsColumns}
                      options={paymentCollection ? paymentCollection.paymentCollectionSummaryDetails : []} />
                  </div>
                </Col>
                {
                  paymentCollection
                  &&
                  <Col xl="3" lg="6" sm="12" xs="12" className={windowSize.innerWidth > Constants.innerWidthCompare ? "" : "padding-top-10"}>
                    <CardInfo
                      icon={() => (<CashMultipleIcon className="h2 text-success mb-0" />)}
                      title={intl.formatMessage({ id: "TotalActualPayments" }).toUpperCase()}
                      value={returnPriceWithCurrency(paymentCollection.totalActualPayments)}
                      valueColor={"text-green-color"} />
                    {
                      blnDisplayTotalPaymentReceived
                      &&
                      <CardInfo
                        icon={() => (<CashCheckIcon className="h2 text-success mb-0" />)}
                        title={intl.formatMessage({ id: "TotalPaymentsReceived" }).toUpperCase()}
                        value={returnPriceWithCurrency(paymentCollection.totalPayments)}
                        valueColor={"text-green-color"} />
                    }
                  </Col>
                }
              </Row>
            }
            {
              paymentCollection &&
              <div 
                className={`${windowSize.innerWidth > Constants.innerWidthCompare ? `margin-top-36` : ""} standard-layout`}
                ref={refTablePaymentCollectionDetail}>
                <Row className="flex-right">
                  <Col xl="2">
                    <GeneralInputWithCallAPI
                      name="searchValue"
                      className={"mb-0"}
                      onChangeCustomSearch={async (searchValueTemp)=> {
                        validation.setFieldValue("searchValue", searchValueTemp);
                        fetchPaymentCollectionDetailListWithLoading(searchValueTemp);                          
                      }}
                      loadingCustomSearch={localLoadingPaymentCollectionDetail}
                      setLoadingCustomSearch={setLocalLoadingPaymentCollectionDetail}/>
                  </Col>
                </Row>
                {
                  localLoadingPaymentCollectionDetail
                  ?
                  <Loading/>
                  :
                  <PaginationTable
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    pageSize={pageSize}
                    totalItem={totalItem}
                    tableColumns={paymentCollectionColumns}
                    data={paymentCollectionDetailList || []}
                    emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "PaymentCollection" }) })}
                    objSorted={defaultSorted}
                    keyField={"randomId"}
                    branchId={selectedBranchId === " " ? undefined : selectedBranchId}
                    dateRange={selectedDateRange}
                    subBrandId={selectedSubBrandId === " " ? undefined : selectedSubBrandId}
                    isAppolousPayment={!blnAllActive ? blnAppolousPayment : undefined}
                    isIncludeCreditPayment={validation.values.isIncludeCreditPayment}
                    paymentMethodId={selectedPaymentMethodId === " " ? undefined : selectedPaymentMethodId}
                    orderByProperties={selectedOrderByProperties}
                    searchValue={validation.values.searchValue}
                    requestAPI={getPaymentCollectionDetailList}
                    hideSearchBar={true}
                    blnRemoveBackground={true} />
                }
              </div>
            }
          </>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(PaymentCollection);
