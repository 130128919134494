import React, { useEffect, useState } from 'react'
import { observer } from "mobx-react-lite";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { contructValidationErrorMessage, returnSuccessMarkedIcon } from "../../app/common/function/function";
import { Col, Form, Row } from 'reactstrap';
import _ from "lodash";
import { useStore } from '../../app/stores/store';
import { Constants } from '../../app/constants/Constants';
import GeneralInput from '../../app/components/form/GeneralInput';
import GeneralTextArea from '../../app/components/form/GeneralTextArea';
import DropDownWithTitle from '../../app/components/dropdown/DropDownWithTitle';
import LineBreakWithTittle from '../../app/components/form/LineBreakWithTittle';
import Loading from '../../app/components/loading/Loading';
import MyButton from "../../app/components/form/MyButton";
import ActionPromptModal from '../../app/components/modal/ActionPromptModal';
import AlertCircleOutlineIcon from 'mdi-react/AlertCircleOutlineIcon';

const TaxEntityWeb = () => {
    //Use Paramsf
    const { id } = useParams();

    //Use INTL
    const intl = useIntl();

    const { commonStore, taxEntityStore } = useStore();
    const { errorMessage, successMessage, loading, setErrorMessage, setSuccessMessage, setLoading } = commonStore;
    const { taxEntityBusinessType, taxEntityIdentityType, taxEntityDetail, addTaxEntityWithWebForm, setTaxEntityDetail, getEInvoiceCodeWithType, validateTaxEntityWebForm, getTaxEntityStaticReferenceWithType } = taxEntityStore;
    const [eInvMiscList, setEInvMiscList] = useState([]);
    const [eInvStateList, setEInvStateList] = useState([]);
    const [eInvStateFilteredList, setEInvStateFilteredList] = useState([]);
    const [eInvMiscFilteredList, setEInvMiscFilteredList] = useState([{code:"",values:""}]);
    const [eInvCountryList, setEInvCountryList] = useState([]);
    const [validateList, setValidateList] = useState({ status: "", message: "" });
    const [viewModeAfterSubmit, setViewModeAfterSubmit] = useState(false);
    const [prompSuccessModal, setPrompSuccessModal] = useState(false);
    const [displayStateDropdown, setDisplayStateDropdown] = useState(true);
    const [displayFieldValue, setDisplayFieldValue] = useState(true);
    const [blnSubmitting, setBlnSubmitting] = useState(false);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: taxEntityDetail || {
            id: "",
            tinNo: "",
            customerId: "",
            customerName: "",
            businessType: "",
            identityType: "",
            identityNo: "",
            businessRegistrationNo: "",
            msicCode: "",
            msicName: "",
            sstRegisterNo: "",
            businessActivityDesc: "",
            tourismTaxRegisterNo: "",
            prevGstRegisterNo: "",
            registeredName: "",
            tradeName: "",
            phone: "",
            email: "",
            address: "",
            postCode: "",
            city: "",
            countryCode: "",
            countryName: "",
            stateCode: "",
            stateName: "",
        },
        validationSchema: Yup.object({
            tinNo: Yup.string().max(20).required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "TinNo" }) })),
            businessType: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "BusinessType" }) })),
            identityType: Yup.string()
                .max(10)
                .when("businessType", {
                    is: (value) => [Constants.individual].includes(value),
                    then: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "IdentityType" }) }))
                        .nullable()
                }),
            identityNo: Yup.string()
                .max(30)
                .when(["countryName", "businessType"], {
                    is: (countryName, businessType) =>
                        countryName === Constants.malaysia.toUpperCase() &&
                        businessType === Constants.individual,
                    then: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "IdentityNo" }) }))
                        .nullable()
                }),
            businessRegistrationNo: Yup.string()
                .when("businessType", {
                    is: (value) => [Constants.government, Constants.business].includes(value),
                    then: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "BusinessRegistrationNo" }) }))
                        .nullable()
                }),
            msicCode: Yup.string()
                .max(5)
                .when("businessType", {
                    is: (value) => [Constants.government, Constants.business].includes(value),
                    then: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "MsicCode" }) }))
                        .nullable()
                }),
            sstRegisterNo: Yup.string().max(20),
            businessActivityDesc: Yup.string()
                .max(100)
                .when("businessType", {
                    is: (value) => [Constants.government, Constants.business].includes(value),
                    then: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "BusinessActivityDesc" }) }))
                        .nullable()
                }),
            tourismTaxRegisterNo: Yup.string().max(20).nullable(),
            prevGstRegisterNo: Yup.string().max(20).nullable(),
            registeredName: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "RegisterName" }) })),
            tradeName: Yup.string().max(100).nullable(),
            phone: Yup.string().max(25).required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Phone" }) })),
            email: Yup.string().email().max(200).required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "EmailAddress" }) })),
            address: Yup.string().max(200).required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Address" }) })),
            postCode: Yup.string().max(10).required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "PostCode" }) })),
            city: Yup.string().max(50).required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "City" }) })),
            countryCode: Yup.string().max(3).required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Country" }) })),
            stateCode: Yup.string().max(3).required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "State" }) })),
        }),
        onSubmit: async (values) => {
            try {
                const addTaxEntity = await addTaxEntityWithWebForm({ ...values, webFormHistoryId: id });
                if (addTaxEntity?.status === Constants.success) {
                    setViewModeAfterSubmit(true);
                    setPrompSuccessModal(true);
                }
            } finally {
                validation.setSubmitting(false);
            }
        },
    });

    const disabledFieldInput = validation.isSubmitting || Boolean(successMessage) || viewModeAfterSubmit;

    const removeState = async () => {
        setDisplayStateDropdown(false)
        validation.setFieldValue("stateCode", "")
        validation.setFieldValue("stateName", "")
        setTimeout(() => {
            setDisplayStateDropdown(true)
        }, 100)
    }

    const removeHiddenFieldValue = async (valueBusinessType) => {
        setDisplayFieldValue(false)
        if (valueBusinessType === Constants.individual) {
            validation.setFieldValue("businessRegistrationNo", "")
            validation.setFieldValue("msicCode", "")
            validation.setFieldValue("msicName", "")
            validation.setFieldValue("sstRegisterNo", "")
            validation.setFieldValue("businessActivityDesc", "")
            validation.setFieldValue("tourismTaxRegisterNo", "")
            validation.setFieldValue("prevGstRegisterNo", "")
        } else {
            validation.setFieldValue("identityType", "")
            validation.setFieldValue("identityNo", "")
            validation.setFieldValue("msicCode", eInvMiscFilteredList[0].code)
            validation.setFieldValue("msicName", eInvMiscFilteredList[0].values)
        }
        setTimeout(() => {
            setDisplayFieldValue(true)
        }, 100)
    }

    useEffect(() => {
        async function fetchTaxEntityDetailAPI() {
            setLoading(true);
            let aryAPI: any = [
                getTaxEntityStaticReferenceWithType(Constants.taxEntityBusinessType),
                getTaxEntityStaticReferenceWithType(Constants.taxEntityIdentityType),
                getEInvoiceCodeWithType(Constants.msic),
                getEInvoiceCodeWithType(Constants.state),
                getEInvoiceCodeWithType(Constants.country),
                validateTaxEntityWebForm(id)
            ];
            let resultAPI = await Promise.all(aryAPI);
            if (resultAPI[2]) {
                setEInvMiscList(resultAPI[2])
                setEInvMiscFilteredList(resultAPI[2].filter(s => s.code === '00000'))
            }
            if (resultAPI[3]) {
                setEInvStateList(resultAPI[3])
                setEInvStateFilteredList(resultAPI[3].filter(s => s.code === '00' || s.code === '17'))
            }
            if (resultAPI[4]) {
                setEInvCountryList(resultAPI[4])
            }
            if (resultAPI[5]) {
                setValidateList({ status: resultAPI[5].status, message: resultAPI[5].data })
                if (resultAPI[5].status === Constants.success) {
                    validation.setFieldValue("customerName", resultAPI[5].data)
                }
            }
            setLoading(false);
        }
        fetchTaxEntityDetailAPI();
        validation.setFieldValue("id", id)

        return (() => {
            setTaxEntityDetail(undefined);
        })
    }, [])

    useEffect(() => {
        if (validation.isSubmitting) {
            setBlnSubmitting(true);
        }

        if (!validation.isSubmitting && blnSubmitting) {
            setBlnSubmitting(false);
            if (Object.keys(validation.errors).length > 0) {
                if (Constants.validationError) {
                    console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
                }
                setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
            }
        }
    }, [validation.isSubmitting, validation.errors])

    return (
        <div className={"padding-1rem"}>
            {loading ? (
                <Loading />
            ) : (
                <>
                    {
                        validateList.status === Constants.failed
                            ?
                            <div className="my-5 pt-5">
                                <Row>
                                    <Col lg="12">
                                        <div className="text-center mb-5">
                                            <AlertCircleOutlineIcon size={"8em"} color={"orange"} className={"margin-bottom-8"} />
                                            <h4 className="text-uppercase">{validateList.message}</h4>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            :
                            <Form
                                className="standard-layout"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                                onBlur={() => {
                                    if (errorMessage || successMessage) {
                                        setErrorMessage("");
                                        setSuccessMessage("");
                                    }
                                }}
                            >
                                <Row form={+true} >
                                    {" "}
                                    {/* +true = Convert boolean to numbers*/}
                                    <Col xl={12} >
                                        {
                                            <>
                                                <LineBreakWithTittle
                                                    paddingBottom="0px"
                                                    title="Tax Entity"
                                                    h4Title />
                                            </>
                                        }
                                        <Row style={{ justifyContent: 'center' }}>
                                            <Col xl={6}>
                                                <GeneralInput
                                                    title={intl.formatMessage({ id: "CustomerName" }).toUpperCase()}
                                                    name="customerName"
                                                    className="mb-3 mt-4"
                                                    type="text"
                                                    disabled={true}
                                                    validationRequired={true}
                                                    validation={validation}
                                                />
                                                <GeneralInput
                                                    title={intl.formatMessage({ id: "Tin" }).toUpperCase()}
                                                    name="tinNo"
                                                    className="mb-3 mt-4"
                                                    type="text"
                                                    disabled={disabledFieldInput}
                                                    validationRequired={true}
                                                    validation={validation}
                                                />
                                                <DropDownWithTitle
                                                    name="businessType"
                                                    title={intl.formatMessage({ id: "BusinessType" })}
                                                    specifyReturnFieldName={[{ "field": "businessType", "value": "displayValue" }]}
                                                    labelField={"displayValue"}
                                                    valueField={"displayValue"}
                                                    options={taxEntityBusinessType}
                                                    disabled={disabledFieldInput}
                                                    initialValue={validation.values.businessType}
                                                    onChangeFunction={removeHiddenFieldValue}
                                                    validationRequired={true}
                                                    validation={validation} />
                                                {
                                                    displayFieldValue
                                                    &&
                                                    validation.values.businessType === Constants.individual &&
                                                    <>
                                                        <DropDownWithTitle
                                                            name="identityType"
                                                            title={intl.formatMessage({ id: "IdentityType" })}
                                                            specifyReturnFieldName={[{ "field": "identityType", "value": "displayValue" }]}
                                                            labelField={"displayValue"}
                                                            valueField={"displayValue"}
                                                            options={taxEntityIdentityType}
                                                            disabled={disabledFieldInput}
                                                            initialValue={validation.values.identityType}
                                                            validationRequired={true}
                                                            validation={validation} />
                                                        <GeneralInput
                                                            title={intl.formatMessage({ id: "IdentityNo" })}
                                                            name="identityNo"
                                                            type="text"
                                                            disabled={disabledFieldInput}
                                                            validationRequired={true}
                                                            validation={validation}
                                                        />
                                                    </>
                                                }
                                                {
                                                    displayFieldValue
                                                    &&
                                                    (validation.values.businessType === Constants.government ||
                                                        validation.values.businessType === Constants.business) &&
                                                    <>
                                                        <GeneralInput
                                                            title={intl.formatMessage({ id: "BusinessRegistrationNo" })}
                                                            name="businessRegistrationNo"
                                                            type="text"
                                                            disabled={disabledFieldInput}
                                                            validationRequired={true}
                                                            validation={validation}
                                                        />
                                                        <DropDownWithTitle
                                                            name={"msicCode"}
                                                            title={intl.formatMessage({ id: "MsicCode" })}
                                                            specifyReturnFieldName={[
                                                                {
                                                                    field: "msicCode",
                                                                    value: "code",
                                                                },
                                                                {
                                                                    field: "msicName",
                                                                    value: "values",
                                                                },
                                                            ]}
                                                            labelField={"values"}
                                                            valueField={"code"}
                                                            options={eInvMiscList}
                                                            disabled={disabledFieldInput}
                                                            initialLabel={validation.values.msicName}
                                                            initialValue={validation.values.msicCode}
                                                            validationRequired={true}
                                                            validation={validation}
                                                        />
                                                        <GeneralInput
                                                            title={intl.formatMessage({ id: "SstRegisterNo" })}
                                                            name="sstRegisterNo"
                                                            type="text"
                                                            disabled={disabledFieldInput}
                                                            validation={validation}
                                                        />
                                                        <GeneralInput
                                                            title={intl.formatMessage({ id: "BusinessActivityDesc" })}
                                                            name="businessActivityDesc"
                                                            type="text"
                                                            disabled={disabledFieldInput}
                                                            validationRequired={true}
                                                            validation={validation}
                                                        />
                                                        <GeneralInput
                                                            title={intl.formatMessage({ id: "TourismTaxRegisterNo" })}
                                                            name="tourismTaxRegisterNo"
                                                            type="text"
                                                            disabled={disabledFieldInput}
                                                            validation={validation}
                                                        />
                                                        <GeneralInput
                                                            title={intl.formatMessage({ id: "PrevGstRegisterNo" })}
                                                            name="prevGstRegisterNo"
                                                            type="text"
                                                            disabled={disabledFieldInput}
                                                            validation={validation}
                                                        />
                                                    </>
                                                }
                                                <GeneralInput
                                                    title={intl.formatMessage({ id: "RegisterName" })}
                                                    name="registeredName"
                                                    type="text"
                                                    disabled={disabledFieldInput}
                                                    validationRequired={true}
                                                    validation={validation}
                                                />
                                                <GeneralInput
                                                    title={intl.formatMessage({ id: "TradeName" })}
                                                    name="tradeName"
                                                    type="text"
                                                    disabled={disabledFieldInput}
                                                    validation={validation}
                                                />
                                                <GeneralInput
                                                    title={intl.formatMessage({ id: "Phone" })}
                                                    name="phone"
                                                    type="text"
                                                    blnNumberOnly={true}
                                                    disabled={disabledFieldInput}
                                                    validationRequired={true}
                                                    validation={validation}
                                                />
                                                <GeneralInput
                                                    title={intl.formatMessage({ id: "EmailAddress" })}
                                                    name="email"
                                                    type="text"
                                                    disabled={disabledFieldInput}
                                                    validationRequired={true}
                                                    validation={validation}
                                                />
                                                <GeneralTextArea
                                                    title={intl.formatMessage({ id: "Address" })}
                                                    name="address"
                                                    row={5}
                                                    disabled={disabledFieldInput}
                                                    validationRequired={true}
                                                    validation={validation}
                                                />
                                                <GeneralInput
                                                    title={intl.formatMessage({ id: "PostCode" })}
                                                    name="postCode"
                                                    type="text"
                                                    disabled={disabledFieldInput}
                                                    validationRequired={true}
                                                    validation={validation}
                                                />
                                                <GeneralInput
                                                    title={intl.formatMessage({ id: "City" })}
                                                    name="city"
                                                    type="text"
                                                    disabled={disabledFieldInput}
                                                    validationRequired={true}
                                                    validation={validation}
                                                />
                                                <DropDownWithTitle
                                                    name={"countryCode"}
                                                    title={intl.formatMessage({ id: "Country" })}
                                                    specifyReturnFieldName={[
                                                        {
                                                            field: "countryCode",
                                                            value: "code",
                                                        },
                                                        {
                                                            field: "countryName",
                                                            value: "values",
                                                        },
                                                    ]}
                                                    labelField={"values"}
                                                    valueField={"code"}
                                                    options={eInvCountryList}
                                                    disabled={disabledFieldInput}
                                                    onChangeFunction={removeState}
                                                    initialLabel={validation.values.countryName}
                                                    initialValue={validation.values.countryCode}
                                                    validationRequired={true}
                                                    validation={validation}
                                                />
                                                {
                                                    displayStateDropdown
                                                    &&
                                                    <DropDownWithTitle
                                                        name={"stateCode"}
                                                        title={intl.formatMessage({ id: "State" })}
                                                        specifyReturnFieldName={[
                                                            {
                                                                field: "stateCode",
                                                                value: "code",
                                                            },
                                                            {
                                                                field: "stateName",
                                                                value: "values",
                                                            },
                                                        ]}
                                                        labelField={"values"}
                                                        valueField={"code"}
                                                        options={validation.values.countryName === Constants.malaysia.toUpperCase() ? eInvStateList : eInvStateFilteredList}
                                                        disabled={disabledFieldInput}
                                                        initialLabel={validation.values.stateName}
                                                        initialValue={validation.values.stateCode}
                                                        validationRequired={true}
                                                        validation={validation}
                                                    />
                                                }
                                                {
                                                    !viewModeAfterSubmit &&
                                                    <div className="flex-left mt-4 mb-2">
                                                        <MyButton
                                                            type="submit"
                                                            class="btn btn-success"
                                                            content={intl.formatMessage({ id: "Submit" }).toUpperCase()} />
                                                    </div>
                                                }
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>
                            </Form>
                    }
                </>
            )}
            {
                prompSuccessModal
                &&
                <ActionPromptModal
                    title={`${intl.formatMessage({ id: "TaxEntityAddSuccess" })}`}
                    replaceIcon={returnSuccessMarkedIcon}
                    showPrompt={prompSuccessModal}
                    setShowPrompt={setPrompSuccessModal}
                    blnHideCancelButton={true}
                    onPrimaryClick={() => { }}
                />
            }
        </div >
    )
}

export default observer(TaxEntityWeb);