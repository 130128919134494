import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Label,
  Row,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { arraySpliceInAllValue, checkPermission, firstDayOfMonthToCurrentDay, getBranchId, getBranchName, getBranchUser, newSpaceBeforeCapitalLetter, returnTableCellUIForMultiLayer, stockReceiveStatusColor } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { DateRangeInterface, ObjectForDropdown } from "../../../app/models/common";
import Loading from "../../../app/components/loading/Loading";
import _ from "lodash";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import MyButton from "../../../app/components/form/MyButton";
import moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../../app/components/form/GeneralInput";
import { StaticReferenceDataObject } from "../../../app/models/staticReference";
import GeneralStatus from "../../../app/components/form/GeneralStatus";
import { ProductListObject } from "../../../app/models/product";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import { StockReceiveSummaryExportObject } from "../../../app/models/reportStock";
import DropDownWithTitleMultiSelect from "../../../app/components/dropdown/DropDownWithTitleMultiSelect";

const StockReceiveSummary = () => {
  const intl = useIntl();
  //Use Store
  const { reportStockStore, branchStore, staticReferenceStore, productStore, commonStore } = useStore();
  const { loading, setLoading, windowSize, setErrorMessage } = commonStore;
  const { stockReceiveSummaryList, getStockReceiveSummaryList, setStockReceiveSummaryList, exportStockReceiveSummary } = reportStockStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { getStaticReferenceWithType } = staticReferenceStore;
  const { productDropdownList, getProductDropdown } = productStore;

  //Data List
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedStockReceiveStatus, setSelectedStockReceiveStatus] = useState<string>(AllStatus);
  const [selectedStockReceiveStatusId, setSelectedStockReceiveStatusId] = useState<number | string | undefined>(" ");
  const [stockReceiveStatusList, setStockReceiveStatusList] = useState<StaticReferenceDataObject[]>([]);
  const AllProducts = `${intl.formatMessage({ id: "AllProducts" })}`;
  const [productList, setProductList] = useState<ProductListObject[]>([]);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.maxPageSize;
  const blnPermissionManageStockReceive = checkPermission([PermissionConstants.ManageStockReceive]);
  const blnPermissionExportInventoryReport = checkPermission([PermissionConstants.ExportInventoryReport]);
  const tableColumnWidth = "200px";

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      documentNo: "",
      productIds: []
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  let stockReceiveRequestBody : StockReceiveSummaryExportObject = {
    branchId: selectedBranchId,
    fromDate: selectedDateRange.fromDate,
    toDate: selectedDateRange.toDate,
    status: selectedStockReceiveStatusId,
    productIds: validation.values.productIds,
    documentNo: validation.values.documentNo
  }

  useEffect(() => {
    async function fetchStockReceiveSummaryListApi() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
        getStaticReferenceWithType(Constants.stockReceiveStatus, AllStatus),
        getProductDropdown()
      ];
      let resultAPI = await Promise.all(aryAPI);

      setBranchListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))
      setStockReceiveStatusList(resultAPI[1]);
      setProductList(arraySpliceInAllValue(resultAPI[2], AllProducts, false, "name", "id"))
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageInventoryReport], true)) {
      return;
    }

    fetchStockReceiveSummaryListApi();

    return (() => {
      setStockReceiveSummaryList([]);
    })
  }, [])

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  async function fetchStockReceiveSummaryApi(blnDropDown: boolean = false) {
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }

    blnDropDown ? setLocalLoading(true) : setLoading(true);
    setCurrentPage(1);
    await getStockReceiveSummaryList(stockReceiveRequestBody);
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  async function exportStockReceiveSummaryApi() {
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }

    setLocalLoading(true)
    await exportStockReceiveSummary(stockReceiveRequestBody);
    setLocalLoading(false)
  }

  //Table Content UI
  const StockReceiveSummaryListColumns = [
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
      style: { alignContent: 'baseline', minWidth: tableColumnWidth }
    },
    {
      dataField: "documentNo",
      text: intl.formatMessage({ id: "DocumentNo" }).toUpperCase(),
      formatter: (cellContent, row, index) => {
        let aryViews : any = [];
        row.stockReceiveSummaryDetails.forEach((valueStockReceiveSummaryDetailTemp, indexStockReceiveSummaryDetailTemp) => {
          let heightTemp = (50*valueStockReceiveSummaryDetailTemp.stockReceiveProductDetails.length);
          aryViews.push(
            <div 
              style={{ 
                minWidth: tableColumnWidth
              }} 
              key={`div_stock_receive_summary_detail_document_no_${index}_${indexStockReceiveSummaryDetailTemp}`}>
              {returnTableCellUIForMultiLayer(`document_no_${index}_${indexStockReceiveSummaryDetailTemp}`, indexStockReceiveSummaryDetailTemp, row.stockReceiveSummaryDetails.length, 
                  blnPermissionManageStockReceive
                  ?
                  <Link to={valueStockReceiveSummaryDetailTemp.documentId ? `/${RoutesList.stockReceive}/view/${valueStockReceiveSummaryDetailTemp.documentId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
                    <Label className={`margin-bottom-0 pointer-clickable ${indexStockReceiveSummaryDetailTemp !== 0 ? "mt-3" : ""}`}>{valueStockReceiveSummaryDetailTemp.documentNo}</Label>
                  </Link>
                  :
                  <div className={indexStockReceiveSummaryDetailTemp !== 0 ? "mt-3" : ""}>
                    {valueStockReceiveSummaryDetailTemp.documentNo}
                  </div>
                , `${heightTemp}px`, true)}
            </div>
          )
        })
        return aryViews;
      }
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      formatter: (cellContent, row, index) => {
        let aryViews : any = [];
        row.stockReceiveSummaryDetails.forEach((valueStockReceiveSummaryDetailTemp, indexStockReceiveSummaryDetailTemp) => {
          let heightTemp = (50*valueStockReceiveSummaryDetailTemp.stockReceiveProductDetails.length);
          aryViews.push(
            <div 
              style={{ 
                minWidth: tableColumnWidth
              }} 
              key={`div_stock_receive_summary_detail_status_${index}_${indexStockReceiveSummaryDetailTemp}`}>
              {returnTableCellUIForMultiLayer(`status_${index}_${indexStockReceiveSummaryDetailTemp}`, indexStockReceiveSummaryDetailTemp, row.stockReceiveSummaryDetails.length, 
                <GeneralStatus
                  status={valueStockReceiveSummaryDetailTemp.status}
                  className={indexStockReceiveSummaryDetailTemp !== 0 ? "mt-3" : ""}
                  statusColorFunction={stockReceiveStatusColor}
                  blnDisableCenter={true} />, `${heightTemp}px`, true)}
            </div>
          )
        })
        return aryViews;
      }
    },
    {
      dataField: "date",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row, index) => {
        let aryViews : any = [];
        row.stockReceiveSummaryDetails.forEach((valueStockReceiveSummaryDetailTemp, indexStockReceiveSummaryDetailTemp) => {
          let heightTemp = (50*valueStockReceiveSummaryDetailTemp.stockReceiveProductDetails.length);
          aryViews.push(
            <div 
              style={{ 
                minWidth: tableColumnWidth
              }} 
              key={`div_stock_receive_summary_detail_date_${index}_${indexStockReceiveSummaryDetailTemp}`}>
              {returnTableCellUIForMultiLayer(`date_${index}_${indexStockReceiveSummaryDetailTemp}`, indexStockReceiveSummaryDetailTemp, row.stockReceiveSummaryDetails.length, 
                <div className={indexStockReceiveSummaryDetailTemp !== 0 ? "mt-3" : ""}>
                  {moment(valueStockReceiveSummaryDetailTemp.date).format(Constants.displayDateAndTimeFormat)}
                </div>, `${heightTemp}px`, true)}
            </div>
          )
        })
        return aryViews;
      }
    },
    {
      dataField: "productName",
      text: intl.formatMessage({ id: "ProductName" }).toUpperCase(),
      formatter: (cellContent, row, index) => {
        return returnStockReceiveProductSubView(row, index, "productName");
      },
    },
    {
      dataField: "measurementName",
      text: intl.formatMessage({ id: "UOMAndQuantity" }).toUpperCase(),
      formatter: (cellContent, row, index) => {
        return returnStockReceiveProductSubView(row, index, "measurementName");
      },
    },
    {
      dataField: "requestedQuantity",
      text: intl.formatMessage({ id: "RequestedQuantity" }).toUpperCase(),
      formatter: (cellContent, row, index) => {
        return returnStockReceiveProductSubView(row, index, "requestedQuantity");
      },
    },
    {
      dataField: "approvedQuantity",
      text: intl.formatMessage({ id: "ApprovedQty" }).toUpperCase(),
      formatter: (cellContent, row, index) => {
        return returnStockReceiveProductSubView(row, index, "approvedQuantity");
      },
    },
    {
      dataField: "receivedQuantity",
      text: intl.formatMessage({ id: "ReceivedQuantity" }).toUpperCase(),
      formatter: (cellContent, row, index) => {
        return returnStockReceiveProductSubView(row, index, "receivedQuantity");
      },
    },
    {
      dataField: "remark",
      text: intl.formatMessage({ id: "Remarks" }).toUpperCase(),
      formatter: (cellContent, row, index) => {
        return returnStockReceiveProductSubView(row, index, "remark");
      },
    },
    {
      dataField: "note",
      text: intl.formatMessage({ id: "Note" }).toUpperCase(),
      formatter: (cellContent, row, index) => {
        let aryViews : any = [];
        row.stockReceiveSummaryDetails.forEach((valueStockReceiveSummaryDetailTemp, indexStockReceiveSummaryDetailTemp) => {
          let heightTemp = (50*valueStockReceiveSummaryDetailTemp.stockReceiveProductDetails.length);
          aryViews.push(
            <div 
              style={{ 
                minWidth: tableColumnWidth
              }} 
              key={`div_stock_receive_summary_detail_note_${index}_${indexStockReceiveSummaryDetailTemp}`}>
              {returnTableCellUIForMultiLayer(`note_${index}_${indexStockReceiveSummaryDetailTemp}`, indexStockReceiveSummaryDetailTemp, row.stockReceiveSummaryDetails.length, 
                <div className={indexStockReceiveSummaryDetailTemp !== 0 ? "mt-3" : ""}>
                  {valueStockReceiveSummaryDetailTemp.note}
                </div>, `${heightTemp}px`, true)}
            </div>
          )
        })
        return aryViews;
      }
    }
  ];

  const returnStockReceiveProductSubView = (row, index, fieldName) => {
    let aryViews : any = [];
    let blnRemoveFirstRowMargin = true;
    row.stockReceiveSummaryDetails.forEach((valueStockReceiveSummaryDetailTemp, indexStockReceiveSummaryDetailTemp) => {
      let arySubViews : any = [];
      valueStockReceiveSummaryDetailTemp.stockReceiveProductDetails.forEach((valueStockReceiveProductDetailTemp, indexStockReceiveProductDetailTemp) => {
        let aryLengthTemp = valueStockReceiveSummaryDetailTemp.stockReceiveProductDetails.length + (((row.stockReceiveSummaryDetails.length -1) === indexStockReceiveSummaryDetailTemp) ? 0 : 1);         
        arySubViews.push(
          <div style={{ minWidth: tableColumnWidth }} key={`${fieldName}_${index}_${indexStockReceiveSummaryDetailTemp}_${indexStockReceiveProductDetailTemp}`}>
            {returnTableCellUIForMultiLayer(`${fieldName}_${index}_${indexStockReceiveSummaryDetailTemp}_${indexStockReceiveProductDetailTemp}`, indexStockReceiveProductDetailTemp, aryLengthTemp, 
              <div
                style={{
                  marginTop: blnRemoveFirstRowMargin ? "-12px" : "",
                  marginBottom: stockReceiveSummaryList.length-1 === index && row.stockReceiveSummaryDetails.length-1 === indexStockReceiveSummaryDetailTemp && valueStockReceiveSummaryDetailTemp.stockReceiveProductDetails.length-1 === indexStockReceiveProductDetailTemp ? "-12px" : ""
                }}>
                {newSpaceBeforeCapitalLetter(valueStockReceiveProductDetailTemp[fieldName])}
              </div>
            , "50px")}
          </div>
        )
        blnRemoveFirstRowMargin = false
      })

      aryViews.push(
        <div key={`${fieldName}_${index}_${indexStockReceiveSummaryDetailTemp}_parent`}>
          {arySubViews}
        </div>
      )
    })
    return aryViews
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "StockReceiveSummary" }) })}
        title={intl.formatMessage({ id: "StockReceiveSummary" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportInventoryReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              exportStockReceiveSummaryApi()
            }}
          />
        }
        hyperlink={``}>
        <Row>
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              options={branchListFinal}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              validationRequired={true}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralDateRange
              title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "ReceivedDate"})})}
              name={"DateRange"}
              disabled={localLoading || loading}
              onChangeFunction={onSelectDateRange}
              validationRequired={true}
              initialValue={selectedDateRange} />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "StockReceive" }) })}
              name="stockReceiveStatus"
              options={stockReceiveStatusList}
              blnValueWithNewSpace={true}
              labelField="displayValue"
              valueField="key"
              initialLabel={selectedStockReceiveStatus}
              initialValue={selectedStockReceiveStatusId}
              setFieldLabel={setSelectedStockReceiveStatus}
              setFieldValue={setSelectedStockReceiveStatusId}
              disabled={localLoading || loading}
              validationRequired={true}
            />
          </DoubleColumnRowParent>
        </Row>
        <Row>
          <DoubleColumnRowParent>
            <DropDownWithTitleMultiSelect
                name={"productIds"}
                className={"flex-1"}
                title={intl.formatMessage({ id: "Product" })}
                specifyReturnFieldName={[{ "field": "productIds", "value": "id" }]}
                returnFieldWithLabel={false}
                labelField={"name"}
                valueField={"id"}
                options={productDropdownList}
                disabled={localLoading || loading}
                initialValue={validation.values.productIds}
                validationRequired={false}
                validation={validation} />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "DocumentNo" })}
              name="documentNo"
              type="text"
              disabled={localLoading || loading}
              toolTipMessage={intl.formatMessage({ id: "StockReceiveSummaryDocumentNoToolTip" })}
              validation={validation}
              placeholder={intl.formatMessage({ id: "DocumentNo" })}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading}
              disable={localLoading || loading}
              onClick={() => {
                fetchStockReceiveSummaryApi(true)
              }}
            />
          </DoubleColumnRowParent>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <>
              <PaginationTable
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                totalItem={stockReceiveSummaryList.length}
                tableColumns={StockReceiveSummaryListColumns}
                bordered={true}
                data={stockReceiveSummaryList}
                emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "StockReceiveSummary" }) })}
                objSorted={[]}
                keyField={"branchId"}
                requestAPI={getStockReceiveSummaryList}
                hideSearchBar={true} />
            </>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(StockReceiveSummary);
