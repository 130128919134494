import { PaginationRequestBody } from "../models/pagination";
import { StockAdjustmentAddObject, StockAdjustmentDetailObject, StockAdjustmentListObject, StockAdjustmentPreloadObject, StockAdjustmentReviewRequestBody, StockAdjustmentUpdateObject } from "../models/stockAdjustment";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { downloadExcel } from "../common/function/function";
import { Constants } from "../constants/Constants";

export default class StockAdjustmentStore {
  stockAdjustmentList: StockAdjustmentListObject[] = [];
  stockAdjustmentDetail: StockAdjustmentDetailObject | undefined = undefined;
  stockAdjustmentPreloadDetail: StockAdjustmentPreloadObject | undefined = undefined;
  stockAdjustmentPaginationParams: PaginationRequestBody | undefined = undefined;
  stockAdjustmentPrintAllList: StockAdjustmentDetailObject[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.stockAdjustmentList = [];
    this.stockAdjustmentDetail = undefined;
    this.stockAdjustmentPreloadDetail = undefined;
    this.stockAdjustmentPaginationParams = undefined;
    this.stockAdjustmentPrintAllList = [];
  }

  setStockAdjustmentDetail = (stockAdjustmentDetail: StockAdjustmentDetailObject | undefined) => {
    this.stockAdjustmentDetail = stockAdjustmentDetail;
  }

  setStockAdjustmentPreloadDetail = (stockAdjustmentPreloadDetail: StockAdjustmentPreloadObject | undefined) => {
    this.stockAdjustmentPreloadDetail = stockAdjustmentPreloadDetail;
  } 

  setStockAdjustmentPaginationParams = (stockAdjustmentPaginationParams: PaginationRequestBody | undefined) => {
    this.stockAdjustmentPaginationParams = stockAdjustmentPaginationParams;
  }

  setStockAdjustmentPrintAllList = (stockAdjustmentPrintAllList: StockAdjustmentDetailObject[]) => {
    this.stockAdjustmentPrintAllList = stockAdjustmentPrintAllList;
  }

  getStockAdjustment = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setStockAdjustmentPaginationParams(PaginationRequestBody);
    try {
      const resultStockAdjustments = await agent.StockAdjustment.stockAdjustmentList(PaginationRequestBody);
      runInAction(() => {
        this.stockAdjustmentList = resultStockAdjustments.data;
        store.commonStore.setTotalItem(resultStockAdjustments.pagination.totalItems);
      });
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.stockAdjustmentList = [];
    }
  };

  getStockAdjustmentWithId = async (id: string) => {
    try{
      const resultStockAdjustmentDetail = await agent.StockAdjustment.stockAdjustmentDetail(id);
      runInAction(() => {
        this.stockAdjustmentDetail = resultStockAdjustmentDetail;
      });
      return Promise.resolve(resultStockAdjustmentDetail)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.stockAdjustmentDetail = undefined;
    }
  }

  addStockAdjustment = async (stockAdjustmentRequestBody: StockAdjustmentAddObject) => {
    try{
      let resultAddStockAdjustment = await agent.StockAdjustment.addStockAdjustment(stockAdjustmentRequestBody);
      if (resultAddStockAdjustment.negativeStockLevelProducts) {
        if (resultAddStockAdjustment.negativeStockLevelProducts.length > 0) {
          return Promise.resolve(resultAddStockAdjustment.negativeStockLevelProducts);
        }
      }
      store.commonStore.setSuccessMessage(`StockAdjustmentAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateStockAdjustment = async (stockAdjustmentRequestBody: StockAdjustmentUpdateObject) => {
    try{
      let resultUpdateStockAdjustment = await agent.StockAdjustment.updateStockAdjustment(stockAdjustmentRequestBody);
      if (resultUpdateStockAdjustment.negativeStockLevelProducts) {
        if (resultUpdateStockAdjustment.negativeStockLevelProducts.length > 0) {
          return Promise.resolve(resultUpdateStockAdjustment.negativeStockLevelProducts);
        }
      }
      store.commonStore.setSuccessMessage(`StockAdjustmentUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteStockAdjustment  = async (id: string, name: string) => {
    try {
      await agent.StockAdjustment.deleteStockAdjustment(id);
      store.commonStore.setSuccessMessage(`StockAdjustmentDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  getStockAdjustmentPreload = async (stockTakeId: string) => {
    try{
      const resultStockAdjustmentPreloadDetail = await agent.StockAdjustment.stockAdjustmentPreload(stockTakeId);
      runInAction(() => {
        this.stockAdjustmentPreloadDetail = resultStockAdjustmentPreloadDetail;
      });
      return Promise.resolve(resultStockAdjustmentPreloadDetail)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.stockAdjustmentDetail = undefined;
    }
  }

  getStockAdjustmentPrintAll = async (PaginationRequestBody: PaginationRequestBody) => {
    try {
      const resultStockAdjustmentPrintAll = await agent.StockAdjustment.stockAdjustmentPrintAllList(PaginationRequestBody);
      runInAction(() => {
        this.stockAdjustmentPrintAllList = resultStockAdjustmentPrintAll;
      })
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.stockAdjustmentPrintAllList = [];
    }
  };

  firstReviewStockAdjustment = async (stockAdjustmentReviewRequestBody: StockAdjustmentReviewRequestBody) => {
    try{
      await agent.StockAdjustment.firstReviewStockAdjustment(stockAdjustmentReviewRequestBody);
      store.commonStore.setSuccessMessage(`StockAdjustmentReviewSuccess`)
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }

  secondReviewStockAdjustment = async (stockAdjustmentReviewRequestBody: StockAdjustmentReviewRequestBody) => {
    try{
      await agent.StockAdjustment.secondReviewStockAdjustment(stockAdjustmentReviewRequestBody);
      store.commonStore.setSuccessMessage(`StockAdjustmentReviewSuccess`)
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }

  finalReviewStockAdjustment = async (stockAdjustmentReviewRequestBody: StockAdjustmentReviewRequestBody) => {
    try{
      await agent.StockAdjustment.finalReviewStockAdjustment(stockAdjustmentReviewRequestBody);
      store.commonStore.setSuccessMessage(`StockAdjustmentReviewSuccess`)
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }

  exportStockAdjustment = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      await downloadExcel(`/stockadjustment/export`, PaginationRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}