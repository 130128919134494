import { observer } from 'mobx-react-lite';
import ActionPromptModal from "../../../app/components/modal/ActionPromptModal";
import { useEffect, useState } from 'react';

interface Props {
  blnShow: boolean;
  setModal: Function;
	onRequestClick: () => void;
}

export default observer(function StockAdjustmentRequestModal(props: Props) {
  const [countDown, setCountDown] = useState(3); // Countdown state
  
  useEffect(()=> {
    let timer;
    timer = setTimeout(() => {
      if (countDown > 0) {
       setCountDown((prevCountdown) => prevCountdown - 1);
      }
      else {
        props.onRequestClick()
      }
    }, 1000);
    return () => clearTimeout(timer); // Clean up the timer
  }, [countDown])

	return (
    <ActionPromptModal 
      title={`Stock Take created successfully,\nSome products is having discrepancies, will redirect to stock adjustment.`}
      primaryActionTitle={`Redirect in ${countDown} ${countDown > 1 ? "seconds" : "second"}`}
      showPrompt={props.blnShow}
      setShowPrompt={props.setModal}
      onPrimaryClick={()=> {props.onRequestClick()}}
      blnHideCancelButton={true}
      blnDisableToggle={true}/>
	);
})