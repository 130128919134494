import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { useFormik } from "formik";
import { arraySpliceInAllValue, checkPermission, contructValidationErrorMessage, getBranchId, getBranchName, getBranchUser, IncludesLocationPathName } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Form } from "reactstrap";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import TableWithEditableInputFields from "../../app/components/table/TableWithEditableInputFields";
import { ObjectForTableWithDynamicInputAndCheckBox } from "../../app/models/common";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";
import StockAdjustmentRequestModal from "./Modal/StockAdjustmentRequestModal";
import { CategoryListObject } from "../../app/models/category";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import { SalesCategoryListObject } from "../../app/models/salesCategory";
import MyButton from "../../app/components/form/MyButton";

const StockTakeDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { stockTakeStore, branchStore, staticReferenceStore, categoryStore, salesCategoryStore, commonStore, settingsStore } = useStore();
  const { errorMessage, successMessage, loading, setErrorMessage, setSuccessMessage, setLoading, } = commonStore;
  const { addStockTake, updateStockTake, stockTakeDetail, setStockTakeDetail, getStockTakeWithId, exportStockTake } = stockTakeStore;
  const { branchForHeaderDropDown, getStockTakeBranchProductList } = branchStore;
  const { getStaticReferenceWithType } = staticReferenceStore;
  const { getCategoryDropdown } = categoryStore;
  const { getSalesCategoryDropdown } = salesCategoryStore;
  const { getGeneralSettings } = settingsStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "StockTake" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "StockTake" }), urlPath: RoutesList.stockTake }];
  const [loadingStockTakeProduct, setLoadingStockTakeProduct] = useState(false);
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [stockTakeIdTemp, setStockTakeIdTemp] = useState<any>(undefined);
  const [showRequestStockAdjustmentModal, setShowRequestStockAdjustmentModal] = useState(false);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const AllType = `${intl.formatMessage({ id: "AllType" })}`;
  const [productTypeList, setProductTypeList] = useState<StaticReferenceDataObject[]>([]);
  const AllCategory = `${intl.formatMessage({ id: "AllCategory" })}`;
  const [categoryList, setCategoryList] = useState<CategoryListObject[]>([]);
  const AllSalesCategory = `${intl.formatMessage({ id: "AllSalesCategory" })}`;
  const [salesCategoryList, setSalesCategoryList] = useState<SalesCategoryListObject[]>([]);
  const [stockTakeProductFilter, setStockTakeProductFilter] = useState({
    branchId: getBranchId(),
    productType: " ",
    categoryId: " ",
    salesCategoryId: " ",
  })

  const [aryDynamicInputAndCheckBoxTabFinal, setAryDynamicInputAndCheckBoxTabFinal] = useState<ObjectForTableWithDynamicInputAndCheckBox[]>([]);
  const aryDynamicInputAndCheckBoxTab: ObjectForTableWithDynamicInputAndCheckBox[] =
  [
    {
      label: intl.formatMessage({ id: "Category" }).toUpperCase(),
      type: "label",
      xl: "2.4",
      inputName: "categoryName",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "ProductName" }).toUpperCase(),
      type: "label",
      xl: "2.4",
      inputName: "productName",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "CurrentQuantity" }).toUpperCase(),
      type: "label",
      xl: "2.4",
      inputName: "quantity",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "PhysicalQuantity" }).toUpperCase(),
      type: "input",
      xl: "2.4",
      inputName: "actualQuantity",
      inputType: "number",
    },
    {
      label: intl.formatMessage({ id: "Remarks" }).toUpperCase(),
      type: "input",
      xl: "2.4",
      inputName: "remark",
      inputType: "text",
    }
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: stockTakeDetail || {
      entityType: "",
      branchName: getBranchName(),
      branchId: getBranchId(),
      productType: " ",
      productTypeName: AllType,
      categoryId: " ",
      categoryName: AllCategory,
      salesCategoryId: " ",
      salesCategoryName: AllSalesCategory,
      note: "",
      stockTakeDetails: [],
      status: "",
      workflowAction: "Save",
    },
    validationSchema: Yup.object({
      branchId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) })),
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      if (valuesTemp.categoryId === " ") {
        valuesTemp.categoryId = "";
      }
      if (valuesTemp.salesCategoryId === " ") {
        valuesTemp.salesCategoryId = "";
      }
      if (valuesTemp.productType === " ") {
        valuesTemp.productType = "";
      }
      else { 
        valuesTemp.productType = String(valuesTemp.productType);
      }

      let result: any = [];
      try {
        if (addAction) {
          result = await addStockTake(valuesTemp);
        } else {
          result = await updateStockTake(valuesTemp);
        }
        if (result) {
          if (result.hasDiscrepancy) {
            setStockTakeIdTemp(result.stockTakeId);
            setShowRequestStockAdjustmentModal(true);
          }
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage) || loadingStockTakeProduct || showRequestStockAdjustmentModal;
  const blnHQStaff = !getBranchUser();
  const blnDisableApplyButton = false;

  useEffect(() => {
    async function fetchStockTakeDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getStaticReferenceWithType(Constants.productType, AllType),
        getCategoryDropdown(),
        getSalesCategoryDropdown(),
        getGeneralSettings()
      ];
      if(addAction){
        getProductWithLoading();
      }
      if (id && !addAction) {
        aryAPI.push(getStockTakeWithId(id));
      }
      
      let resultAPI = await Promise.all(aryAPI);

      setProductTypeList(resultAPI[0]);
      setCategoryList(arraySpliceInAllValue(resultAPI[1], AllCategory, false, "name", "id"))
      setSalesCategoryList(arraySpliceInAllValue(resultAPI[2], AllSalesCategory, false, "name", "id"))

      if (resultAPI.length > 4) {
        if (resultAPI[4]) {
          if (resultAPI[4].status !== Constants.pending) {
            history.push(`/${RoutesList.notFound}`)
          }
          setStockTakeProductFilter((stockTakeProductFilter)=> ({
            ...stockTakeProductFilter,
            branchId: resultAPI[4].branchId
          }))

          let productTypeTemp = " ";
          let productTypeNameTemp = AllType;
          let indexProductType = _.findIndex(resultAPI[0], { displayValue: resultAPI[4].productType })
          if (indexProductType > -1) {
            productTypeTemp = resultAPI[0][indexProductType].key
            productTypeNameTemp = resultAPI[0][indexProductType].displayValue
          }
          validation.setFieldValue("productType", productTypeTemp);
          validation.setFieldValue("productTypeName", productTypeNameTemp);
        }
      }
      displayTableContent(resultAPI[3].isEnableGlobalViewOfCurrentQuantityForOutlet);
      setLoading(false);
    }

    if (addAction) {
      setStockTakeDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateStockTake], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateStockTake], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageStockTake], true)) {
        return;
      }
    }

    fetchStockTakeDetailAPI();

    return (() => {
      if (id && !addAction) {
        setStockTakeDetail(undefined);
      }
    })
  }, []);

  const getProductWithLoading = async () => {
    setLoadingStockTakeProduct(true);
    let stockTakeBranchProductListTemp: any = await getStockTakeBranchProductList({
      branchId: validation.values.branchId,
      productType: validation.values.productType,
      categoryId: validation.values.categoryId,
      salesCategoryId: validation.values.salesCategoryId,
    });

    let stockTakeProductListContent = stockTakeBranchProductListTemp.data.map((valueProductTemp) => {
      let actualQuantityFinal = 0;
      let remarkFinal = "";
      let indexExistingTemp = _.findIndex(validation.values.stockTakeDetails, { productId: valueProductTemp.productId });
      if (indexExistingTemp > -1) {
        actualQuantityFinal = validation.values.stockTakeDetails[indexExistingTemp].actualQuantity;
        remarkFinal = validation.values.stockTakeDetails[indexExistingTemp].remark;
      }

      return ({
        productId: valueProductTemp.productId,
        productName: valueProductTemp.productName,
        categoryName: valueProductTemp.categoryName,
        quantity: valueProductTemp.quantity,
        actualQuantity: actualQuantityFinal,
        remark: remarkFinal
      })
    })

    validation.setFieldValue("stockTakeDetails", stockTakeProductListContent)
    setStockTakeProductFilter({
      branchId: validation.values.branchId,
      productType: validation.values.productType,
      categoryId: validation.values.categoryId,
      salesCategoryId: validation.values.salesCategoryId
    })
    setLoadingStockTakeProduct(false);
  };

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const displayTableContent = (blnIsEnableGlobalView: boolean) => {
    if (!blnHQStaff && blnIsEnableGlobalView === false) {
      let aryDynamicInputAndCheckBoxTabTemp: ObjectForTableWithDynamicInputAndCheckBox[] = []
      aryDynamicInputAndCheckBoxTab.map((value, index) => {
        if (value.inputName !== 'quantity') {
          aryDynamicInputAndCheckBoxTabTemp.push({
            ...value,
            xl: "4"
          })
        }
      })
      setAryDynamicInputAndCheckBoxTabFinal(aryDynamicInputAndCheckBoxTabTemp)
    }
    else {
      setAryDynamicInputAndCheckBoxTabFinal(aryDynamicInputAndCheckBoxTab)
    }
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <Row form={+true}>
              {" "}
              <Col xl={4} className="d-print-none">
                <LineBreakWithTittle
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "StockTake" }) })}
                  h4Title
                />
                <SingleColumnRowParent blnDoubleTab={true}>
                  <DropDownWithTitle
                    name={"branchId"}
                    title={intl.formatMessage({ id: "BranchName" })}
                    className="mb-3 mt-4"
                    specifyReturnFieldName={[
                      {
                        field: "branchId",
                        value: "id",
                      },
                      {
                        field: "branchName",
                        value: "name",
                      }
                    ]}
                    labelField={"name"}
                    valueField={"id"}
                    options={branchForHeaderDropDown}
                    disabled={disabledFieldInput || viewAction || !blnHQStaff}
                    initialLabel={validation.values.branchName}
                    initialValue={validation.values.branchId}
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent blnDoubleTab={true}>
                  <DropDownWithTitle
                    name={"productType"}
                    title={intl.formatMessage({ id: "ProductType" })}
                    specifyReturnFieldName={[
                      {
                        field: "productType",
                        value: "key",
                      },
                      {
                        field: "productTypeName",
                        value: "displayValue",
                      }
                    ]}
                    labelField="displayValue"
                    valueField="displayValue"
                    blnValueWithNewSpace={true}
                    options={productTypeList}
                    disabled={disabledFieldInput || viewAction}
                    initialLabel={validation.values.productTypeName}
                    initialValue={validation.values.productTypeName}
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent blnDoubleTab={true}>
                  <DropDownWithTitle
                    name={"categoryName"}
                    title={intl.formatMessage({ id: "Category" })}
                    specifyReturnFieldName={[
                      { 
                        "field": "categoryId", "value": "id"
                      },
                      { 
                        "field": "categoryName", "value": "name"
                      }
                    ]}
                    labelField={"name"}
                    valueField={"id"}
                    options={categoryList}
                    disabled={disabledFieldInput || viewAction}
                    initialLabel={validation.values.categoryName}
                    initialValue={validation.values.categoryId}
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent blnDoubleTab={true}>
                  <DropDownWithTitle
                    name={"salesCategoryName"}
                    title={intl.formatMessage({ id: "SalesCategory" })}
                    specifyReturnFieldName={[
                      { 
                        "field": "salesCategoryId", "value": "id"
                      },
                      { 
                        "field": "salesCategoryName", "value": "name"
                      }
                    ]}
                    labelField={"name"}
                    valueField={"id"}
                    options={salesCategoryList}
                    disabled={disabledFieldInput || viewAction}
                    initialLabel={validation.values.salesCategoryName}
                    initialValue={validation.values.salesCategoryId}
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent blnDoubleTab={true}>
                  <GeneralTextArea
                    title={intl.formatMessage({ id: "Note" })}
                    name="note"
                    row={5}
                    disabled={disabledFieldInput || viewAction}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent blnDoubleTab={true}>
                  {
                    !viewAction
                    &&
                    <MyButton
                      type="button"
                      class="btn btn-primary"
                      style={{minWidth: "60px"}}
                      content={intl.formatMessage({ id: "Apply" })}
                      disable={disabledFieldInput || blnDisableApplyButton}
                      loading={loadingStockTakeProduct || validation.isSubmitting}
                      onClick={() => {
                        getProductWithLoading();
                      }}
                    />
                  }
                  <MyButton
                    type="button"
                    class={`btn btn-success ${!viewAction && "margin-left-8"}`}
                    style={{minWidth: "60px"}}
                    content={intl.formatMessage({ id: "Print" })}
                    disable={disabledFieldInput}
                    loading={loadingStockTakeProduct || validation.isSubmitting}
                    onClick={() => {
                      window.print();
                    }}
                  />
                  <MyButton
                    type="button"
                    class={`btn btn-success margin-left-8`}
                    style={{minWidth: "60px"}}
                    content={intl.formatMessage({ id: "Export" })}
                    disable={disabledFieldInput}
                    loading={loadingStockTakeProduct || validation.isSubmitting}
                    onClick={async () => {
                      validation.setSubmitting(true);
                      await exportStockTake({
                        branchId: validation.values.branchId,
                        productType: validation.values.productType,
                        categoryId: validation.values.categoryId,
                        salesCategoryId: validation.values.salesCategoryId
                      });
                      validation.setSubmitting(false);
                    }}
                  />
                </SingleColumnRowParent>
              </Col>
              <Col xl={8}>
                <LineBreakWithTittle
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Products" }) })}
                  h4Title
                />
                {
                  loadingStockTakeProduct
                  ?
                  <Loading
                    className="mt-2"
                    fullHeightDisabled={true}/>
                  :
                  <TableWithEditableInputFields
                    name="stockTakeDetails"
                    className="transparent-input"
                    title=""
                    buttonTitle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Product" }) })}
                    blnEditable={!viewAction}
                    aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTabFinal}
                    data={validation.values.stockTakeDetails}
                    validation={validation}
                    disabled={disabledFieldInput}
                    hideAddButton={true}
                    hideDeleteButton={true} />
                }
              </Col>
            </Row>
            <div className="mt-3" />
            {
              !loadingStockTakeProduct
              &&
              !showRequestStockAdjustmentModal
              &&
              <GeneralSubmitAndCancelBtn
                className="d-print-none margin-top-48"
                successMessage={successMessage}
                viewAction={viewAction}
                validation={validation}
                dirtyDisabled={true}
                showExtraLeftButton={true}
                extraLeftButtonTitle={intl.formatMessage({ id: "Save" })}
                extraLeftButtonColor="btn-primary"
                extraLeftButtonFunction={() => {
                  validation.setFieldValue("workflowAction", 'Save')
                }}
                submitButtonTitle={intl.formatMessage({ id: "Submit" })}
                onClickFunction={() => {
                  validation.setFieldValue("workflowAction", 'Submit')
                }}
              />
            }
          </Form>
        )}
        {
          showRequestStockAdjustmentModal &&
          <StockAdjustmentRequestModal
            blnShow={showRequestStockAdjustmentModal}
            setModal={setShowRequestStockAdjustmentModal}
            onRequestClick={() => {
              if (stockTakeIdTemp) {
                history.push(`/${RoutesList.stockAdjustment}/add?stockTakeId=${stockTakeIdTemp}`)
              }
            }}
          />
        }
      </DetailViewLayout>
    </div >
  );
};

export default observer(StockTakeDetail);