import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { IncludesLocationPathName, stockAdjustmentStatusColor, checkPermission } from "../../app/common/function/function";
import { useFormik } from "formik";
import * as Yup from "yup";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Card, CardBody, Table, Form, Input } from "reactstrap";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import GeneralTextAreaForModal from "../../app/components/form/GeneralTextAreaForModal";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import MyButton from "../../app/components/form/MyButton";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import Borderline from "../../app/components/form/BorderLine";
import { history } from "../..";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import ActionPromptModal from "../../app/components/modal/ActionPromptModal";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import { StockAdjustmentReviewRequestBody } from "../../app/models/stockAdjustment";
import RejectOrCancelPromptModal from "../../app/components/modal/RejectOrCancelPromptModal";
import { GeneralAttachmentListInterface } from "../../app/models/common";
import GeneralAttachment from "../../app/components/form/GeneralAttachment";

const StockAdjustmentViewDetail = () => {
  //Use Params
  const { id } = useParams();
  //Use INTL
  const intl = useIntl();
  //Use Store
  const { stockAdjustmentStore, commonStore } = useStore();
  const {
    errorMessage,
    successMessage,
    loading,
    setLoading,
    setErrorMessage,
    setSuccessMessage,
  } = commonStore;
  const { stockAdjustmentDetail, setStockAdjustmentDetail, getStockAdjustmentWithId, firstReviewStockAdjustment, secondReviewStockAdjustment, finalReviewStockAdjustment } = stockAdjustmentStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view") || IncludesLocationPathName("print");
  const displayTitle =  `${addAction ? intl.formatMessage({id: "Add"}) : viewAction ? intl.formatMessage({id: "View"}) : intl.formatMessage({id: "Edit"})
} ${intl.formatMessage({id: "StockAdjustment"})}`;
  const breadCrumbList = [{ title: intl.formatMessage({id: "StockAdjustment"}), urlPath: RoutesList.stockAdjustment }];
  const [recreateStockAdjustmentModal, setRecreateStockAdjustmentModal] = useState(false);
  const [rejectStockAdjustmentModal, setRejectStockAdjustmentModal] = useState(false);
  const [reviewWorkflowAction, setReviewWorkflowAction] = useState("");
  const [localLoading, setLocalLoading] = useState(false);
  const stockAdjustmentFullDetail = stockAdjustmentDetail || {
    id: "",
    stockAdjustmentNo: "",
    stockAdjustDate: "",
    branchId: "",
    branchName: "",
    note: "",
    status: "",
    approverId: "",
    approverName: "",
    approvedDate: "",
    reviewRemark: "",
    firstReviewedBy: "",
    firstReviewedOn: "",
    secondReviewedBy: "",
    secondReviewedOn: "",
    finalReviewedBy: "",
    finalReviewedOn: "",
    stockAdjustmentDetails: [],
    supportingDocumentFileIds: [],
    supportingDocumentAttachments: []
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: stockAdjustmentDetail || {
      id: "",
      workflowAction: "",
      reviewRemark: "",
      stockAdjustmentDetails: [],
      arySupportingDocumentAttachments: []
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  const blnPermissionCreateStockAdjustment = checkPermission([PermissionConstants.CreateStockAdjustment]);
  const blnPermissionReviewStockAdjustmentFirstApproval = checkPermission([PermissionConstants.ReviewStockAdjustmentFirstApproval]);
  const blnPermissionReviewStockAdjustmentSecondApproval = checkPermission([PermissionConstants.ReviewStockAdjustmentSecondApproval]);
  const blnPermissionReviewStockAdjustmentFinalApproval = checkPermission([PermissionConstants.ReviewStockAdjustmentFinalApproval]);
  const blnPendingFirstApprovalStatus = stockAdjustmentFullDetail.status === Constants.pendingFirstApproval;
  const blnPendingSecondApprovalStatus = stockAdjustmentFullDetail.status === Constants.pendingSecondApproval;
  const blnPendingFinalApprovalStatus = stockAdjustmentFullDetail.status === Constants.pendingFinalApproval;
  const blnRejectedStatus = stockAdjustmentFullDetail.status === Constants.rejected;
  const blnCompletedStatus = stockAdjustmentFullDetail.status === Constants.completed;
  const blnReviseRequiredStatus = stockAdjustmentFullDetail.status === Constants.reviseRequired;
  const blnPendingStatus = stockAdjustmentFullDetail.status === Constants.pending;
  const blnAllowFirstApprovalAction = blnPermissionReviewStockAdjustmentFirstApproval && blnPendingFirstApprovalStatus;
  const blnAllowSecondApprovalAction = blnPermissionReviewStockAdjustmentSecondApproval && blnPendingSecondApprovalStatus;
  const blnAllowFinalApprovalAction = blnPermissionReviewStockAdjustmentFinalApproval && blnPendingFinalApprovalStatus;
  const blnAllowRecreateStockAdjustmentAction = blnPermissionCreateStockAdjustment && blnRejectedStatus;
  const displayActionButton = blnAllowFirstApprovalAction || blnAllowSecondApprovalAction || blnAllowFinalApprovalAction;
  const disabledFieldInput =  blnRejectedStatus || blnCompletedStatus || !displayActionButton;

  async function fetchStockAdjustmentDetailAPI() {
    setLoading(true);
    if (id && !addAction) {
      let resultAPI = await getStockAdjustmentWithId(id);
      if (resultAPI) {
        if (resultAPI.supportingDocumentAttachments) {
          let arySupportingDocumentAttachmentsTemp : GeneralAttachmentListInterface[]  = [];
          resultAPI.supportingDocumentAttachments.map((valueSupportingDocumentAttachmentTemp)=> {
            arySupportingDocumentAttachmentsTemp.push({
              id: valueSupportingDocumentAttachmentTemp.attachmentFileId,
              preview: "",
              path: valueSupportingDocumentAttachmentTemp.attachmentFileName,
              webUrl: valueSupportingDocumentAttachmentTemp.attachmentWebUrl,
            })
          })
          validation.setFieldValue("arySupportingDocumentAttachments", arySupportingDocumentAttachmentsTemp);
        }
        else {
          validation.setFieldValue("arySupportingDocumentAttachments", []);
        }
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!checkPermission([PermissionConstants.ManageStockAdjustment], true)) {
      return;
    }
    
    fetchStockAdjustmentDetailAPI();

    return (() => {
      if (id && !addAction) {
        setStockAdjustmentDetail(undefined);
      }
    })
  }, []);

  const returnStockAdjustmentDetailsForRequestBody = () => {
    let stockAdjustmentDetailsTemp = _.cloneDeep(validation.values.stockAdjustmentDetails.map((valueStockAdjustmentDetail)=> ({
      id: valueStockAdjustmentDetail.id,
      reviewRemark: valueStockAdjustmentDetail.reviewRemark || ""
    })));

    return stockAdjustmentDetailsTemp;
  }

  const displayHeaderRowData = (label, value) => {
    return (
      <Row>
        <h6 className="m-0 text-muted">
          {label}
        </h6>
        <h6 className="text-muted" style={{whiteSpace: "pre-wrap"}}>
          {value}
        </h6>
      </Row>
    )
  }

  return (
    <div>
       <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        blnShowPrint={true}>
          {loading ? (
            <Loading />
          ) : (
            <Form
              className=""
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              onBlur={() => {
                if (errorMessage || successMessage) {
                  setErrorMessage("");
                  setSuccessMessage("");
                }
              }}>
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <div className="invoice-title">
                        <div className="logo-lg" style={{ lineHeight: '20px', marginBottom: '16px' }}>
                          <span style={{ fontSize: Constants.documentTitleFontSize, fontWeight: 'bold', lineHeight: '20px' }}>
                            {intl.formatMessage({ id: "StockAdjustment" })}
                          </span>
                        </div>
                        <Row>
                          <Col>
                            {displayHeaderRowData(`${intl.formatMessage({id: "StockAdjustmentNo"})}:`, stockAdjustmentFullDetail.stockAdjustmentNo)}
                            {displayHeaderRowData(`${intl.formatMessage({id: "BranchName"})}:`, stockAdjustmentFullDetail.branchName)}
                            {
                              stockAdjustmentFullDetail.approverName
                              &&
                              !stockAdjustmentFullDetail.finalReviewedBy 
                              &&
                              <>
                                {displayHeaderRowData(`${intl.formatMessage({id: "ApprovedName"})}:`, stockAdjustmentFullDetail.approverName)}
                                {displayHeaderRowData(`${intl.formatMessage({id: "ApprovedDate"})}:`, moment(stockAdjustmentFullDetail.approvedDate).format(Constants.displayDateFormat))}
                              </>
                            }
                          </Col>
                          <Col className="text-end">
                            {
                              stockAdjustmentFullDetail.firstReviewedBy
                              &&
                              <div>
                                {displayHeaderRowData(`${intl.formatMessage({id: "FirstReviewedBy"})}:`, `${stockAdjustmentFullDetail.firstReviewedBy}\n${moment(stockAdjustmentFullDetail.firstReviewedOn).format(Constants.displayDateAndTimeFormat)}`)}
                              </div>
                            }
                            {
                              stockAdjustmentFullDetail.secondReviewedBy
                              &&
                              <div>
                                <br/>
                                {displayHeaderRowData(`${intl.formatMessage({id: "SecondReviewedBy"})}:`, `${stockAdjustmentFullDetail.secondReviewedBy}\n${moment(stockAdjustmentFullDetail.secondReviewedOn).format(Constants.displayDateAndTimeFormat)}`)}
                              </div>
                            }
                            {
                              stockAdjustmentFullDetail.finalReviewedBy
                              &&
                              <div>
                                <br/>
                                {displayHeaderRowData(`${intl.formatMessage({id: "FinalReviewedBy"})}:`, `${stockAdjustmentFullDetail.finalReviewedBy}\n${moment(stockAdjustmentFullDetail.finalReviewedOn).format(Constants.displayDateAndTimeFormat)}`)}
                              </div>
                            }
                          </Col>
                        </Row>
                      </div>
                      <Borderline />
                      <address>
                        <strong>{intl.formatMessage({id: "Status"})}:</strong>
                        <br />
                        <div style={{ marginTop: '8px' }}>
                          <GeneralStatus
                            status={stockAdjustmentFullDetail.status}
                            statusColorFunction={stockAdjustmentStatusColor}
                            blnDisableCenter/>
                        </div>
                      </address>
                      <div className="py-2 mt-3">
                        <h3 className="font-size-15 font-weight-bold">
                          {intl.formatMessage({id: "Product(s)"})}
                        </h3>
                      </div>
                      <div className="table-responsive">
                        <Table className="table-fixed" style={{ width: '100%' }}>
                          <thead className="table-light">
                            <tr>
                              <th>{intl.formatMessage({id: "Name"})}</th>
                              {/* <th className="text-center">{intl.formatMessage({id: "SupplierReferenceNo"})}</th> */}
                              {/* <th className="text-center">{intl.formatMessage({id: "CostPrice"})}</th> */}
                              <th className="text-center">{intl.formatMessage({id: "AdjustQuantity"})}</th>
                              {/* <th className="text-center">{intl.formatMessage({id: "ExpiryDate"})}</th> */}
                              <th className="text">{intl.formatMessage({id: "Remarks"})}</th>
                              <th className="text">{intl.formatMessage({id: "ItemReview"})}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              stockAdjustmentFullDetail.stockAdjustmentDetails.map((valueStockAdjustment, indexStockAdjustment) => {
                                return (
                                  <tr key={indexStockAdjustment} style={{verticalAlign: "baseline"}}>
                                    <td>{`${valueStockAdjustment.productName}`}</td>
                                    {/* <td className="text-center">{item.supplierReferenceNo}</td> */}
                                    {/* <td className="text-center">{returnPriceWithCurrency(item.costPrice)}</td> */}
                                    <td className="text-center">{valueStockAdjustment.adjustQuantity}</td>
                                    {/* <td className="text-center">{moment(item.expiryDate).format(Constants.displayDateFormat)}</td> */}
                                    <td className="">{valueStockAdjustment.remark || Constants.emptyData}</td>
                                    <td>
                                      {
                                        (blnCompletedStatus || blnReviseRequiredStatus || blnPendingStatus)
                                        ?
                                        valueStockAdjustment.reviewRemark || Constants.emptyData
                                        :
                                        <Input
                                          name="reviewRemark"
                                          type="text"
                                          defaultValue={valueStockAdjustment.reviewRemark || ""}
                                          onChange={(event)=> {
                                            validation.values.stockAdjustmentDetails[indexStockAdjustment].reviewRemark = event.target.value;
                                          }}
                                          disabled={localLoading || Boolean(successMessage)}
                                        />
                                      }
                                    </td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </Table>
                      </div>
                      <div className={`py-2 mt-3`}>
                        <h3 className="font-size-15 font-weight-bold">
                        {intl.formatMessage({id: "Note"})} :
                        </h3>
                      </div>
                      <GeneralTextAreaForModal
                        title=""
                        name="note"
                        className="margin-top-0"
                        row={5}
                        disabled={true}
                        field={stockAdjustmentFullDetail}
                        setFieldValue={() => { }} />
                      {
                        validation.values.arySupportingDocumentAttachments && validation.values.arySupportingDocumentAttachments.length > 0
                        &&
                        <>
                          <div className={`py-2 mt-3 margin-bottom--8`}>
                            <h3 className="font-size-15 font-weight-bold">
                              {intl.formatMessage({id: "SupportingDocuments"})} :
                            </h3>
                          </div>
                          <GeneralAttachment
                            title={""}
                            name="arySupportingDocumentAttachments"
                            validation={validation}
                            disabled={true}/>
                        </>
                      }
                      <>
                        <div className={`py-2 mt-3`}>
                          <h3 className="font-size-15 font-weight-bold">
                          {intl.formatMessage({id: "ReviewRemark"})} :
                          </h3>
                        </div>
                        <GeneralTextArea
                          title=""
                          name="reviewRemark"
                          className="margin-top-0"
                          row={5}
                          disabled={validation.isSubmitting || Boolean(successMessage) || disabledFieldInput}
                          validation={validation}/>
                      </>
                      <div className="margin-top-24" />
                      <div className="d-print-none flex-direction-row">
                        {
                          blnAllowRecreateStockAdjustmentAction
                          &&
                          <MyButton
                            type="button"
                            class="btn btn-warning margin-right-8"
                            style={{ width: '300px' }}
                            content={intl.formatMessage({ id: "RecreateStockAdjustment" }).toUpperCase()}
                            onClick={async () => { setRecreateStockAdjustmentModal(true) }}
                          />
                        }
                        {
                          displayActionButton
                          &&
                          <>
                            <MyButton
                              type="button"
                              class="btn btn-success"
                              content={intl.formatMessage({ id: "Approve" }).toUpperCase()}
                              disable={localLoading || Boolean(successMessage)}
                              loading={localLoading}
                              onClick={async () => {    
                                let valuesFinal : StockAdjustmentReviewRequestBody = {
                                  id: id,
                                  workflowAction: Constants.approve,
                                  reviewRemark: validation.values.reviewRemark,
                                  stockAdjustmentDetails: returnStockAdjustmentDetailsForRequestBody()
                                }

                                try {
                                  setLocalLoading(true);
                                  if (blnPendingFirstApprovalStatus) {
                                    await firstReviewStockAdjustment(valuesFinal);
                                  }
                                  else if (blnPendingSecondApprovalStatus) {
                                    await secondReviewStockAdjustment(valuesFinal);
                                  }
                                  else if (blnPendingFinalApprovalStatus) {
                                    await finalReviewStockAdjustment(valuesFinal);
                                  }
                                } 
                                finally {
                                  setLocalLoading(false);
                                }
                              }}
                            />
                            <MyButton
                              type="button"
                              class="btn btn-back margin-left-8"
                              style={{ width: blnAllowFinalApprovalAction ? '200px' : '180px' }}
                              content={intl.formatMessage({ id: "RejectAndRevise" }).toUpperCase()}
                              disable={localLoading || Boolean(successMessage)}
                              loading={localLoading}
                              onClick={async () => {
                                setReviewWorkflowAction(Constants.rejectAndRevise)
                                setRejectStockAdjustmentModal(true);
                              }}
                            />
                          </>
                        }
                        {
                          blnAllowFinalApprovalAction
                          &&
                          <MyButton
                            type="button"
                            class="btn btn-danger margin-left-8"
                            style={{ width: '150px' }}
                            content={intl.formatMessage({ id: "RejectOnly" }).toUpperCase()}
                            disable={localLoading || Boolean(successMessage)}
                            loading={localLoading}
                            onClick={async () => {
                              setReviewWorkflowAction(Constants.rejectOnly)
                              setRejectStockAdjustmentModal(true);
                            }}
                          />
                        }
                        <GeneralSubmitAndCancelBtn
                          className="width-100-percentage"
                          successMessage={successMessage}
                          viewAction={false}
                          hidePrimaryButton={true}
                          showPrint={true}
                          cancelButtonBesidePrint={(blnAllowFirstApprovalAction || blnAllowSecondApprovalAction || blnAllowFinalApprovalAction || blnAllowRecreateStockAdjustmentAction) && !Boolean(successMessage)}
                          cancelButtonTitle={intl.formatMessage({ id: "Back" })}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Form>
          )}
        {
          recreateStockAdjustmentModal
          &&
          <ActionPromptModal
            title={`${intl.formatMessage({ id: "RecreateStockAdjustmentAlert" })}`}
            primaryActionTitle={`Yes`}
            showPrompt={recreateStockAdjustmentModal}
            setShowPrompt={setRecreateStockAdjustmentModal}
            onPrimaryClick={() => { 
              history.push(`/${RoutesList.stockAdjustment}/add?recreateStockAdjustmentId=${stockAdjustmentFullDetail.id}`); 
            }}
          />
        }
        {
          rejectStockAdjustmentModal
          &&
          <RejectOrCancelPromptModal
            showPrompt={rejectStockAdjustmentModal}
            setShowPrompt={setRejectStockAdjustmentModal}
            title={intl.formatMessage({ id: reviewWorkflowAction === Constants.rejectOnly ? "RejectStockAdjustmentAlert" : "RejectAndReviseStockAdjustmentAlert"}, { stockAdjustmentNo: stockAdjustmentFullDetail.stockAdjustmentNo})}
            buttonTitle={intl.formatMessage({ id: reviewWorkflowAction === Constants.rejectOnly ? "RejectOnly" :"RejectAndRevise" })}
            intl={intl}
            defaultRemark={validation.values.reviewRemark}
            blnValidationRequired={true}
            loading={localLoading}
            buttonFunction={async (remark)=> {
              try {
                setLocalLoading(true);
                let resultRejectStockAdjustment: any = undefined;
                let rejectAndReviseRequestBody = {
                  id: id, 
                  workflowAction: reviewWorkflowAction,
                  reviewRemark: remark,
                  stockAdjustmentDetails: returnStockAdjustmentDetailsForRequestBody()
                }
                
                if (blnPendingFirstApprovalStatus) {
                  resultRejectStockAdjustment = await firstReviewStockAdjustment(rejectAndReviseRequestBody);
                }
                else if (blnPendingSecondApprovalStatus) {
                  resultRejectStockAdjustment = await secondReviewStockAdjustment(rejectAndReviseRequestBody);
                }
                else if (blnPendingFinalApprovalStatus) {
                  resultRejectStockAdjustment = await finalReviewStockAdjustment(rejectAndReviseRequestBody);
                }
                
                if (resultRejectStockAdjustment) {
                  if (resultRejectStockAdjustment.status) {
                    if (resultRejectStockAdjustment.status === Constants.success) {
                      setRejectStockAdjustmentModal(false);
                    }
                  }
                }
              }
              finally
              {
                setLocalLoading(false);
              }
            }}/> 
        }
      </DetailViewLayout>
    </div>
  );
};

export default observer(StockAdjustmentViewDetail);
