import { useState } from "react";
import { Modal, FormFeedback } from "reactstrap";
import _ from 'lodash';
import MyButton from "../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import { useStore } from "../../../app/stores/store";
import GeneralTextAreaForModal from "../../../app/components/form/GeneralTextAreaForModal";
import Loading from "../../../app/components/loading/Loading";

interface Props {
  blnShow: boolean;
  setModal: Function;
  onPrimaryClick: Function;
  localLoading?: boolean;
}

export default function CustomerSalesOrderVoidModal(props: Props) {
  const intl = useIntl();
  const { commonStore } = useStore();
  const { successMessage, setErrorMessage } = commonStore;
  const [reviewRemarkDetail, setReviewRemarkDetail] = useState({reviewRemark: ""})
  const [errorState, setErrorState] = useState({reviewRemark: false})
  const disabledFieldInput = props.localLoading || Boolean(successMessage);

  const hideModal = () => {
    props.setModal(false);
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!disabledFieldInput) {
            hideModal();
          }
        }}
        size={"xl"}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title margin-top-0"> {intl.formatMessage({ id: "ReasonForVoidSalesAlert" })}</h5>
          {
            !disabledFieldInput
            &&
            <button
              type="button"
              onClick={() => {
                hideModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          }
        </div>
        <div className="modal-body">
          {
            props.localLoading
            ?
            <Loading />
            :
            <>
              <GeneralTextAreaForModal
                title={intl.formatMessage({ id: "Reason" })}
                name="reviewRemark"
                className={errorState.reviewRemark ? "mb-0" : "mb-3"}
                row={4}
                validationRequired={true}
                field={reviewRemarkDetail}
                setFieldValue={setReviewRemarkDetail}
                disabled={disabledFieldInput}
                maxLength={100}
                onChangeFunction={()=> {
                  if (errorState.reviewRemark) {
                    setErrorState((value) => ({...value, reviewRemark: false}))
                  }
                }} />
              {errorState.reviewRemark ? (
                <FormFeedback className="mb-3" type="invalid">{`${intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({id: "Reason"})})}`}</FormFeedback>
              ) : null}
              {
                !Boolean(successMessage)
                &&
                <div className={`float-end d-print-none`}>
                  <MyButton
                    type="button"
                    class="btn btn-danger margin-left-8"
                    content={intl.formatMessage({ id: "Cancel" })}
                    onClick={() => {
                      props.setModal(false)
                    }}
                    disable={disabledFieldInput}
                    loading={props.localLoading}
                  />
                  <MyButton
                    type="button"
                    class="btn btn-primary margin-left-8"
                    content={intl.formatMessage({ id: "Submit" })}
                    onClick={() => {
                      if (!reviewRemarkDetail.reviewRemark) {
                        setErrorMessage(`${intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Reason" }) })}`)
                        setErrorState({reviewRemark: true})
                      }
                      else {
                        props.onPrimaryClick(reviewRemarkDetail.reviewRemark);
                      }
                    }}
                    disable={disabledFieldInput}
                    loading={props.localLoading}
                  />
                </div>
              }
            </>
          }
        </div>
      </Modal>
  )
}