import { Col, Modal, ModalBody, Row } from "reactstrap";
import { observer } from 'mobx-react-lite';
import AlertCircleOutlineIcon from 'mdi-react/AlertCircleOutlineIcon';
import { useIntl } from "react-intl";
import PaginationTableWithoutApi from "../../../app/components/table/PaginationTableWithoutApi";
import { isTablet } from "react-device-detect";
import { NegativeStockLevelProduct } from "../../../app/models/stockAdjustment";

interface Props {
  blnShow: boolean;
  setModal: Function;
  negativeStockLevelProducts: NegativeStockLevelProduct[];
}

export default observer(function NegativeStockLevelModal(props: Props) {
  const intl = useIntl();

  const NegativeStockLevelColumns = [
    {
      dataField: "productName",
      text: intl.formatMessage({ id: "ProductName" }).toUpperCase()
    },
    {
      dataField: "currentStockLevel",
      text: intl.formatMessage({ id: "CurrentStockLevel" }).toUpperCase()
    }
  ];

	return (
		<div>
			<Modal 
        isOpen={props.blnShow} 
        toggle={() => {
          props.setModal(false);
        }} 
        size="lg"
        style={{...isTablet && {width: '100%', maxWidth: '75%'}}}
        centered={true}>
        <button
          type="button"
          onClick={() => { props.setModal(false)}}
          className="close"
          data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
				<ModalBody className="py-3 px-5">
					<Row>
						<Col lg={12}>
							<div className="text-center">
                <AlertCircleOutlineIcon size={"6em"} color={"orange"} className={"margin-bottom-8"}/>
								<h4 style={{whiteSpace:"pre-wrap"}}>
									{intl.formatMessage({ id: "NegativeStockLevelErrorMessage" })}
								</h4>
							</div>
              <div className="margin-top-32">
                <PaginationTableWithoutApi 
                  title={""}
                  headerClassName={"table-light"}
                  options={props.negativeStockLevelProducts}
                  columns={NegativeStockLevelColumns}
                  pageSize={5}
                  keyField={"productName"}/>
              </div>
						</Col>
					</Row>
				</ModalBody>
			</Modal>
		</div>
	);
})
