import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  Form
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import SortCaret from '../../app/components/table/SortCaret';
import * as Yup from "yup";
import BreadCrumb from '../../app/components/breadCrumb/BreadCrumb';
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import _ from "lodash";
import { history } from "../..";
import { useFormik } from "formik";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import Loading from "../../app/components/loading/Loading";
import MyButton from "../../app/components/form/MyButton";
import { CustomerOrderDetailObject } from "../../app/models/customerOrder";
import CustomerOrderDetailModal from "../CustomerOrder/Modal/CustomerOrderDetailModal";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import Borderline from "../../app/components/form/BorderLine";
import { calculatePOSTotalPrice, checkPermission, contructValidationErrorMessage, convertAdditionalFormDataRead, getBranchId, getBranchName, getPurchaseTypeIcon, onClickWhatsappWithDelay, returnPriceWithCurrency, returnSubBrandList } from "../../app/common/function/function";
import DropDownWithTitleMultiSelect from "../../app/components/dropdown/DropDownWithTitleMultiSelect";
import CustomerOrderCart from "../CustomerOrder/subView/CustomerOrderCart";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import CustomerOrderPendingSignModal from "../CustomerOrder/Modal/CustomerOrderPendingSignModal";
import { CloseCounterPendingObject } from "../../app/models/closeCounter";
import PendingCloseCounterModal from "../CustomerOrder/Modal/PendingCloseCounterModal";
import TodayCloseCounterSubmittedModal from "../CustomerOrder/Modal/TodayCloseCounterSubmittedModal";
import { PackageListObject } from "../../app/models/package";
import { TaxEntityDropdownListObject } from "../../app/models/taxEntity";
import ActionPromptModal from "../../app/components/modal/ActionPromptModal";

const TopUpCredit = () => {
  //Use INTL
  const intl = useIntl();

  //Use Store
  const { packageStore, staticReferenceStore, employeeStore, customerStore, additionalFormFieldStore, settingsStore, dashboardStore, closeCounterStore, taxEntityStore, stockTakeStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, successMessage, windowSize, setErrorMessage, setSuccessMessage } = commonStore;
  const { getPackageDropdown } = packageStore;
  const { purchaseType, discountType, getStaticReferenceWithType } = staticReferenceStore;
  const { employeeDropdownList, getEmployeeDropdown } = employeeStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const { additionalFormFieldFormTypeList, getAdditionalFormFieldDropdown } = additionalFormFieldStore;
  const { generalSettings, getGeneralSettings } = settingsStore;
  const { pendingSignOrdersReminder, getPendingSignOrdersReminder, setPendingSignOrdersReminder } = dashboardStore;
  const { getPendingCloseCounter } = closeCounterStore;
  const { getCustomerTaxEntityDropdown, addTaxEntityFormHistory } = taxEntityStore;
  const { getStockTakeReminder } = stockTakeStore;
  const packages = `${intl.formatMessage({ id: "Package" })}`;
  const [customerOrderDetailModal, setCustomerOrderDetailModal] = useState(false);
  const [selectedCustomerOrderDetail, setSelectedCustomerOrderDetail] = useState<CustomerOrderDetailObject | undefined>(undefined);
  const location = useLocation();
  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [displayTinDropdown, setDisplayTinDropdown] = useState(true);
  const [loadingReqCustomerTaxEntity, setLoadingReqCustomerTaxEntity] = useState(false);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const [blnShowPendingCloseCounterModal, setBlnShowPendingCloseCounterModal] = useState(false);
  const [blnShowTodayCloseCounterSubmittedModal, setBlnShowTodayCloseCounterSubmittedModal] = useState(false);
  const [blnShowStockTakeReminderModal, setBlnShowStockTakeReminderModal] = useState(false);
  const [pendingCloseCounterDetail, setPendingCloseCounterDetail] = useState<CloseCounterPendingObject | undefined>(undefined);
  const [localPackageList, setLocalPackageList] = useState<PackageListObject[]>([]);
  const [tinDropdownList, setTinDropdownList] = useState<TaxEntityDropdownListObject[]>([]);

  //Default Sorting Type
  const defaultSorted = [
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      tabBar: packages,
      branchId: "",
      customerId: "",
      customerName: "",
      customerNo: "",
      customerMobileNo: "",
      consultantIds: [],
      note: "",
      totalCount: 0,
      totalPrice: 0,
      totalSellingPrice: 0,
      totalTax: 0,
      paidNumber: undefined,
      salesOrderDetails: [],
      paymentDetails: [],
      subBrandName: "",
      subBrandId: "",
      originalBranchName: "",
      tinId: "",
      tinDisplayName: ""
    },
    validationSchema: Yup.object({
      subBrandId: Yup.string().test("subBrandName", intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "SubBrand" }) }), (value: any, context) => {
        if (returnSubBrandList().length > 0 ? true : false) {
          return value;
        }
        else {
          return true;
        }
			}).nullable(),
      customerId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Customer" }) })),
      salesOrderDetails: Yup.array().min(1, intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "Item" }).toLowerCase() })),
      consultantIds: Yup.array().min(1, intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "Consultant" }).toLowerCase() })),
      tinDisplayName: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Tin" }) }))
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      valuesTemp.branchId = getBranchId();
      
      if (valuesTemp.tinDisplayName === Constants.noTin) {
        valuesTemp.tinDisplayName = "";
        valuesTemp.tinId = "";
      }

      try {          
        history.push({
          pathname: `/${RoutesList.topUpCreditPayment}`,
          state: { detail: valuesTemp }
        });
      }
      catch (e: any) {
        setErrorMessage(e);
      }
      finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);

  //Table Content UI
  const CustomerOrderColumns = [
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      style: {
        width: '30%',
      },
      sortCaret: (order, column) => <SortCaret order={order} />,
      formatter: (cellContent, row) => (
        <>
        {row.promotionCode && getPurchaseTypeIcon(row.purchaseType)}{row.name}
        </>
      ),
    },
    {
      dataField: "sellingPrice",
      text: intl.formatMessage({ id: "Price" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{`${returnPriceWithCurrency(row.sellingPrice)}`}</div>
      ),
    },
    {
      dataField: "add",
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'right' },
      formatter: (cellContent, row) => (
        <div className="flex-right">
          <MyButton
            type="button"
            content={intl.formatMessage({ id: "Add" }).toUpperCase()}
            class="btn btn-success"
          />
        </div>
      ),
    }
  ];

  const rowEvents = {
    onClick: (e, row) => {
      if (String(e.target).includes(Constants.htmlButton) && !validation.isSubmitting) {
        let indexTemp = _.findIndex(purchaseType, { displayValue: validation.values.tabBar })
        let salesOrderDetailsTemp: any = _.cloneDeep(validation.values.salesOrderDetails);
        let indexCustomerOrderDetail = _.findIndex(salesOrderDetailsTemp, { itemId: row.id })
        if (indexCustomerOrderDetail > -1) {
          salesOrderDetailsTemp[indexCustomerOrderDetail].count += 1;
        }
        else {
          let objOrderDetails: CustomerOrderDetailObject = {
            itemName: row.name,
            purchaseType: indexTemp > -1 ? purchaseType[indexTemp].displayValue : "",
            count: 1,
            price: row.sellingPrice,
            floorPrice: row.floorPrice || 0,
            itemId: row.id,
            discountType: discountType.length > 0 ? discountType[0].displayValue : `${intl.formatMessage({ id: "Amount" })}`,
            discountValue: 0,
            tax: row.taxRate || 0,
            remark: ""
          }

          objOrderDetails = {
            ...objOrderDetails,
            isPrepaidPackage: row.isPrepaidPackage,
            isCreditPackage: row.isCreditPackage,
          }

          setSelectedCustomerOrderDetail(objOrderDetails.additionalData ? _.cloneDeep(convertAdditionalFormDataRead(objOrderDetails.additionalData, additionalFormFieldFormTypeList)) : _.cloneDeep(objOrderDetails))
          setCustomerOrderDetailModal(true);
        }

        setTimeout(()=> {
          validation.setFieldValue("salesOrderDetails", salesOrderDetailsTemp);
        }, 10)
      }
    }
  };

  useEffect(() => {
    async function fetchTopUpCreditAPI() {
      setLoading(true);
      let aryAPI: any = [
        getPackageDropdown({ branchId: getBranchId(), isActive: true }, true),
        getStaticReferenceWithType(Constants.purchaseType),
        getAdditionalFormFieldDropdown(Constants.serviceRecord),
        getGeneralSettings(),
        getEmployeeDropdown({ branchId: getBranchId(), isBranchStaff: true, isActive: true })
      ];

      let resultAPI = await Promise.all(aryAPI);
      if (resultAPI[0]) {
        let packageDropdownTemp = _.cloneDeep(resultAPI[0]);
        packageDropdownTemp = _.filter(packageDropdownTemp, { isCreditPackage: true });
        setLocalPackageList(packageDropdownTemp);
      }

      let blnCustomerFound = false
      if (location) {
        if (location.state) {
          if (location.state.detail) {
            if (location.state.detail.customerId) {
              prefillCustomerInfo(location.state.detail.customerId)
              blnCustomerFound = true;
            }
            if (location.state.detail.consultantIds) {
              validation.setFieldValue("consultantIds", location.state.detail.consultantIds)
            }

          }
        }
      }

      if (!blnCustomerFound) {
        history.push(`/${RoutesList.notFound}`)
      }

      let aryGeneralSettingAPI : any = [
        !resultAPI[3]?.isAllowCreateSalesOrderWithPendingSign ? getPendingSignOrdersReminder({ branchId: getBranchId(), isFilterOutTodayOrder: true }) : null,
        resultAPI[3]?.isEnableCloseCounter ? getPendingCloseCounter(getBranchId()) : null,
        resultAPI[3]?.isEnableStockTakeDeadline ? getStockTakeReminder(getBranchId()) : null
      ];
      let resultGeneralSettingAPI = await Promise.all(aryGeneralSettingAPI);
      if (resultGeneralSettingAPI[1]) {
        if (resultGeneralSettingAPI[1].isInvalidCashOut || resultGeneralSettingAPI[1].isInvalidCloseCounter) {
          setPendingCloseCounterDetail(resultGeneralSettingAPI[1]);
          setBlnShowPendingCloseCounterModal(true);
        }
        else if (resultGeneralSettingAPI[1].isTodayClosingSubmitted) {
          setBlnShowTodayCloseCounterSubmittedModal(true);
        }
      }
      if (resultGeneralSettingAPI[2]) {
        if (resultGeneralSettingAPI[2].isConductStockTakeRequired) {
          setBlnShowStockTakeReminderModal(true);
        }
      }

      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.CreateCreditRedemptionNoteRequest], true)) {
      return;
    }

    fetchTopUpCreditAPI();

    return (()=> {
      setPendingSignOrdersReminder(undefined);
    })
  }, [])

  useEffect(() => {
    let countTemp = 0;
    let priceTemp = 0;
    let taxTemp = 0;

    validation.values.salesOrderDetails.map((valueTemp: CustomerOrderDetailObject) => {
      countTemp += Number(valueTemp.count);
      let finalPrice = calculatePOSTotalPrice(valueTemp.count, valueTemp.price, valueTemp.discountType, valueTemp.discountValue)
      priceTemp += finalPrice
      let finalTax = calculatePOSTotalPrice(1, finalPrice, "discount", valueTemp.tax, true)
      taxTemp += finalTax;
    })

    validation.setFieldValue("totalCount", countTemp)
    validation.setFieldValue("totalSellingPrice", priceTemp.toFixed(2))
    validation.setFieldValue("totalPrice", (priceTemp + taxTemp).toFixed(2))
    validation.setFieldValue("totalTax", taxTemp.toFixed(2))
  }, [validation.values.salesOrderDetails])

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const deleteCustomerOrder = (index) => {
    let salesOrderDetailsTemp: any = _.cloneDeep(validation.values.salesOrderDetails);
    salesOrderDetailsTemp.splice(index, 1);
    validation.setFieldValue("salesOrderDetails", salesOrderDetailsTemp);
  }

  const selectCustomerOrder = (index) => {
    let objOrderDetails: CustomerOrderDetailObject = validation.values.salesOrderDetails[index];
    setSelectedCustomerOrderDetail({ ...objOrderDetails, ...objOrderDetails.additionalData ? _.cloneDeep(convertAdditionalFormDataRead(objOrderDetails.additionalData, additionalFormFieldFormTypeList)) : _.cloneDeep(objOrderDetails) })
    setCustomerOrderDetailModal(true);
  }

  const prefillCustomerInfo = async (id: string) => {
    setDisplayCustomerDropdown(false);
    setDisplayTinDropdown(false);
    let customerDropdownListTemp = await getCustomerDropdown("", {customerId: id});
        
    let indexCustomer = _.findIndex(customerDropdownListTemp, { id: id });
    if (indexCustomer > -1 && customerDropdownListTemp) {
      validation.setFieldValue("customerId", customerDropdownListTemp[indexCustomer].id);
      validation.setFieldValue("customerName", customerDropdownListTemp[indexCustomer].preferredName);
      validation.setFieldValue("customerNo", customerDropdownListTemp[indexCustomer].customerNo);
      validation.setFieldValue("customerMobileNo", customerDropdownListTemp[indexCustomer].mobileNo);
      validation.setFieldValue("originalBranchName", customerDropdownListTemp[indexCustomer].branchName)
    }

    //Tin
    setTinDropdownList(await getCustomerTaxEntityDropdown(id));
    validation.setFieldValue("tinId", "");
    validation.setFieldValue("tinDisplayName", "");

    setTimeout(() => {
      clearCustomerDropdownList();
      setDisplayCustomerDropdown(true);
      setDisplayTinDropdown(true);
    }, 100)
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  async function addCustomerTaxEntityWebFormHistory() {
    const result = await addTaxEntityFormHistory({customerId: validation.values.customerId})
    if (result?.data.webFormHistoryId) {
      return result?.data.webFormHistoryId; 
    }
  }

  const displayTableBySalesCategory = (baseProps: any) => {
    let aryDataTemp = _.cloneDeep(baseProps?.data || []);

    if (aryDataTemp.length === 0) {
      return (
        <BootstrapTable
          responsive
          bordered={false}
          striped={false}
          defaultSorted={defaultSorted}
          classes={"table align-middle"}
          keyField="id"
          rowEvents={rowEvents}
          noDataIndication={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "Packages" })})}
          {...baseProps}/>
      )
    }

    const uniqueSalesCategory = Array.from(new Set(aryDataTemp.map(item => item.salesCategoryId)))
    .map(uniqueId => {
      const matchingObject = aryDataTemp.find(item => item.salesCategoryId === uniqueId);
      return { id: matchingObject.salesCategoryId, name: matchingObject.salesCategoryName };
    });
    let finalViews: any = [];
    
    uniqueSalesCategory.map((valueSalesCategoryOnlyTemp)=> {
      let finalData = _.filter(aryDataTemp, {salesCategoryId: valueSalesCategoryOnlyTemp.id});
      if (finalData.length > 0) {
        finalViews.push(
          <div key={`table_key_${valueSalesCategoryOnlyTemp.name}`}>
            <div 
              style={{padding: "0.75rem 0.75rem"}}>
              <span style={{color: Constants.lightpurple, fontSize: '16px', fontWeight: 'bold', borderBottom: '1px solid', marginLeft: '-8px'}}>
                {valueSalesCategoryOnlyTemp.name}
              </span>
            </div>
            <BootstrapTable
              responsive
              bordered={false}
              striped={false}
              defaultSorted={defaultSorted}
              classes={"table align-middle"}
              keyField="id"
              rowEvents={rowEvents}
              noDataIndication={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "Packages" })})}
              {...baseProps}
              data={finalData}
            />
          </div>
        )
      }
    })
    return finalViews;
  }
  
  return (
    <div>
      <div
        className="page-content">
        <MetaTags>
          <title>{intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "TopUpCredit" }) })}</title>
        </MetaTags>
        <Container fluid>
          <Row className="margin-bottom-4">
            <Col xl="9">
              <div className="h4">{intl.formatMessage({ id: "TopUpCredit" })}</div>
            </Col>
          </Row>
          <BreadCrumb activeBreadCrumbTitle={intl.formatMessage({ id: "TopUpCredit" })} />
          {loading && !successMessage ? (
            <Loading />
          ) : (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              onBlur={() => {
                if (errorMessage || successMessage) {
                  setErrorMessage("");
                  setSuccessMessage("");
                }
              }}>
              <Row>
                <Col xl="7" md="7" xs="12" sm="12">
                  <ToolkitProvider
                    keyField="id"
                    columns={CustomerOrderColumns}
                    data={localPackageList}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <div
                        className="standard-layout">
                        <Row>
                          <Col xl="6" md="12" xs="12">
                            {
                              !displayCustomerDropdown
                              ?
                              <Loading/>
                              :
                              <DropDownWithTitle
                                name={"customerId"}
                                title={intl.formatMessage({ id: "Customer" })}
                                placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                                specifyReturnFieldName={[
                                  {
                                    field: "customerId",
                                    value: "id",
                                  }
                                ]}
                                labelField={"preferredName"}
                                valueField={"id"}
                                options={customerDropdownList}
                                disabled={true}
                                initialLabel={validation.values.customerName}
                                initialValue={validation.values.customerId}
                                validationRequired={true}
                                validation={validation}
                              />
                            }
                            {
                              validation.values.originalBranchName !== "" && validation.values.originalBranchName !== getBranchName()
                              &&
                              <p className="text-danger margin-top--12">*{intl.formatMessage({ id: "OriginalBranch" })}: {validation.values.originalBranchName}</p>
                            }
                          </Col>
                          <Col xl="6" md="12" xs="12">
                            <Row>
                              <Col xl='8' md='8' xs='8'>
                                {
                                  !displayTinDropdown
                                  ?
                                  <Loading/>
                                  :
                                  <DropDownWithTitle
                                    name={"tinDisplayName"}
                                    title={intl.formatMessage({ id: "Tin" }).toUpperCase()}
                                    specifyReturnFieldName={[
                                      {
                                        field: "tinId",
                                        value: "tinId",
                                      },
                                      {
                                        field: "tinDisplayName",
                                        value: "tinDisplayName",
                                      }
                                    ]}
                                    labelField={"tinDisplayName"}
                                    valueField={"tinId"}
                                    options={tinDropdownList}
                                    disabled={disabledFieldInput || !validation.values.customerId}
                                    initialLabel={validation.values.tinDisplayName}
                                    initialValue={validation.values.tinId}
                                    validation={validation}
                                    validationRequired={true}
                                  />
                                }
                              </Col>
                              <Col xl='4' md='4' xs='4'>
                                <MyButton
                                  type="button"
                                  class={`btn btn-primary margin-top-28`}
                                  content={intl.formatMessage({ id: "ReqTin" })}
                                  loading={loading || loadingReqCustomerTaxEntity}
                                  disable={loading || loadingReqCustomerTaxEntity || !validation.values.customerId}
                                  onClick={async () => {
                                    setLoadingReqCustomerTaxEntity(true);
                                    let resultId = await addCustomerTaxEntityWebFormHistory()
                                    if (resultId) {
                                      const message = `${window.location.origin}/${RoutesList.taxEntityWeb}/add/${resultId}` ;
                                      const encodedId = encodeURIComponent(message);
                                      onClickWhatsappWithDelay(`https://web.whatsapp.com/send?phone=${validation.values.customerMobileNo}&text=${encodedId}`);
                                    }
                                    setLoadingReqCustomerTaxEntity(false);
                                  }}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12" md="12" xs="12">
                            <DropDownWithTitleMultiSelect
                              name={"consultantIds"}
                              keyDiv={"consultantIds"}
                              title={intl.formatMessage({ id: "Consultants" })}
                              specifyReturnFieldName={[{ "field": "consultantIds", "value": "id" }]}
                              returnFieldWithLabel={true}
                              returnAllField={true}
                              labelField={"preferredName"}
                              valueField={"id"}
                              options={employeeDropdownList}
                              disabled={disabledFieldInput}
                              initialValue={validation.values.consultantIds}
                              validationRequired={true}
                              validation={validation} />
                          </Col>
                        </Row>
                        <Borderline />
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              {displayTableBySalesCategory(toolkitProps.baseProps)}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </ToolkitProvider>
                </Col>
                <Col xl="5" md="5" xs="12" sm="12">
                  <div className={`standard-layout ${Constants.innerWidthCompare770>windowSize.innerWidth ? "margin-top-16" : ""}`}>
                    <CustomerOrderCart
                      validation={validation}
                      disabledCustomerOrder={disabledFieldInput}
                      disabledCustomerPayment={true}
                      disabledNote={disabledFieldInput}
                      disabledVoidedSalesOrderNo={disabledFieldInput}
                      selectCustomerOrder={selectCustomerOrder}
                      deleteCustomerOrder={deleteCustomerOrder}
                      loadingSubBrand={!displayCustomerDropdown}
                      blnAllowCrossMonthSales={generalSettings?.isAllowCrossMonthSales || false}
                    />
                    <MyButton
                      type="submit"
                      class="btn btn-primary"
                      style={{ width: '100%' }}
                      content={intl.formatMessage({ id: "Pay" }).toUpperCase()}
                      disable={
                        validation.isSubmitting
                      }
                      loading={
                        validation.isSubmitting
                      }
                    />
                    <MyButton
                      type="button"
                      class="btn btn-back mt-2"
                      style={{ width: '100%' }}
                      content={intl.formatMessage({ id: "Back" }).toUpperCase()}
                      disable={
                        validation.isSubmitting
                      }
                      loading={
                        validation.isSubmitting
                      }
                      onClick={() => {
                        history.goBack()
                      }}/>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
          {
            customerOrderDetailModal
            &&
            <CustomerOrderDetailModal
              blnShow={customerOrderDetailModal}
              setModal={setCustomerOrderDetailModal}
              selectedCustomerOrderDetail={selectedCustomerOrderDetail}
              setSelectedCustomerOrderDetail={setSelectedCustomerOrderDetail}
              discountType={discountType}
              employeeList={employeeDropdownList}
              calulateTotalPrice={calculatePOSTotalPrice}
              validation={validation}
              additionalFormFieldFormTypeList={additionalFormFieldFormTypeList}
              maxPrepaidPackageInSalesOrder={generalSettings?.maxPrepaidPackageInSalesOrder || 0} />
          }
          {
            blnShowStockTakeReminderModal
            &&
            <ActionPromptModal 
              title={`${intl.formatMessage({ id: "StockTakeReminderMessage" })}`}
              primaryActionTitle={`Close`}
              showPrompt={blnShowStockTakeReminderModal}
              setShowPrompt={setBlnShowStockTakeReminderModal}
              blnHideCancelButton={true}
              blnShowCloseButton={true}
              onPrimaryClick={()=> {}}
            />
          }
          {
            pendingSignOrdersReminder?.hasPendingSignOrder
            &&
            <CustomerOrderPendingSignModal
              blnShow={true}
              weeklyPendingOrdersChart={pendingSignOrdersReminder.pendingSignOrders}/>
          }
          {
            blnShowPendingCloseCounterModal
            &&
            <PendingCloseCounterModal 
              blnShow={blnShowPendingCloseCounterModal}
              setModal={setBlnShowPendingCloseCounterModal}
              pendingCloseCounterDetail={pendingCloseCounterDetail!}
              disabledCloseFunction={true}/>
          }
          {
            blnShowTodayCloseCounterSubmittedModal
            &&
            <TodayCloseCounterSubmittedModal 
              blnShow={blnShowTodayCloseCounterSubmittedModal}/>
          }
        </Container>
      </div>
    </div>
  );
}

export default observer(TopUpCredit);
