import { useState } from "react";
import { Modal } from "reactstrap";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import MyButton from "../../../app/components/form/MyButton";
import GeneralTextAreaForModal from "../../../app/components/form/GeneralTextAreaForModal";
import _ from "lodash";
import { Constants } from "../../../app/constants/Constants";

interface Props {
  showPrompt: boolean;
  setShowPrompt: Function;
  title: string;
  intl: any;
  refundNoteNo: string;
  loading: boolean;
  buttonFunction: any;
  buttonTitle: string;
  defaultRemark?: string;
}

export default observer(function RejectRefundNoteModal(props: Props) {
  const { commonStore } = useStore();
  const { successMessageWithoutNavigation, setErrorMessage } = commonStore;
  const [rejectRefundNoteDetail, setRejectRefundNoteDetail] = useState({remark: props.defaultRemark || ""});
  
  const hideModal = () => {
    props.setShowPrompt(false);
  }

	return (
		<Modal
      isOpen={props.showPrompt}
      toggle={() => {
        if (!props.loading || Boolean(successMessageWithoutNavigation)) {
          hideModal();
        }
      }}
      size="lg"
      centered>
      {
        <div>
          <div className="modal-header">
            <div>
              <h5 className="modal-title margin-top-0">{props.title}</h5>
            </div>
            {
              (!props.loading || Boolean(successMessageWithoutNavigation))
              && 
              <button
                type="button"
                onClick={() => { hideModal(); }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            }
          </div>
          <div className="modal-body">
            <GeneralTextAreaForModal
              title={props.intl.formatMessage({ id: "Remark" })}
              name="remark"
              row={5}
              validationRequired={true}
              field={rejectRefundNoteDetail}
              setFieldValue={setRejectRefundNoteDetail}
              disabled={props.loading} />
            {
              !Boolean(successMessageWithoutNavigation)
              &&
              <div className="flex-right flex-direction-row">
                <MyButton
                  type="button"
                  class={`btn ${props.buttonTitle === Constants.cancel ? "btn-warning" : "btn-danger"}`}
                  style={{ width: '20%' }}
                  content={props.buttonTitle}
                  onClick={async () => {
                    if (!rejectRefundNoteDetail.remark) {
                      setErrorMessage(`${props.intl.formatMessage({ id: "ValidationEnterOne" }, { field: props.intl.formatMessage({ id: "Remark" }) })}`)
                      return;
                    }
                    props.buttonFunction(rejectRefundNoteDetail.remark);
                  }}
                  disable={props.loading || Boolean(successMessageWithoutNavigation)}
                  loading={props.loading}
                />
              </div>
            }
          </div>
        </div>
      }
    </Modal>
	);
})
