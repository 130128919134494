import { PaginationRequestBody } from "../models/pagination";
import { BranchListObject, BranchAddObject, BranchUpdateObject, BranchDetailObject, BusinessHourForBranchObject, PaymentMethodForBranchObject, ProductForBranchObject, BranchProductBatchGetObject, BranchDropdownGetObject, StockTakeBranchProductsGetObject } from "../models/branch";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { getBranchId, getBranchName, getBranchUser } from "../common/function/function";
import _ from "lodash";
import { Constants } from "../constants/Constants";

export default class BranchStore {
  branchList: BranchListObject[] = [];
  branchDropDownList: BranchListObject[] = [];
  branchDetail: BranchDetailObject | undefined = undefined;
  branchBusinessHour: BusinessHourForBranchObject[] = [];
  branchPaymentMethod: PaymentMethodForBranchObject[] = [];
  branchForHeaderDropDown : BranchListObject[] = [];
  branchProductList: ProductForBranchObject[] = [];
  branchCurrency: string = 'RM';
  branchPaginationParams: PaginationRequestBody | undefined = undefined;
  isEligibleAllBranch: boolean = false;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.branchList = [];
    this.branchDropDownList = [];
    this.branchDetail = undefined;
    this.branchBusinessHour = [];
    this.branchPaymentMethod = [];
    this.branchForHeaderDropDown = [];
    this.branchProductList = [];
    this.branchPaginationParams= undefined;
    this.isEligibleAllBranch = false;
  }

  setBranchDetail = (branchDetail: BranchDetailObject | undefined) => {
    this.branchDetail = branchDetail;
  }
  
  setBranchList = (branchList: BranchListObject[]) => {
    this.branchList = branchList;
  }

  setBranchBusinessHour = (brachBusinessHour: BusinessHourForBranchObject[]) => {
    this.branchBusinessHour = brachBusinessHour;
  }

  setBranchPaymentMethod = (branchPaymentMethod: PaymentMethodForBranchObject[]) => {
    this.branchPaymentMethod = branchPaymentMethod;
  }

  setBranchPaginationParams = (branchPaginationParams: PaginationRequestBody | undefined) => {
    this.branchPaginationParams = branchPaginationParams;
  }

  getBranch = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setBranchPaginationParams(PaginationRequestBody);
    try{
      const resultBranches = await agent.Branch.branchList(PaginationRequestBody);
      runInAction(() => {
        this.branchList = resultBranches.data;
        store.commonStore.setTotalItem(resultBranches.pagination.totalItems);
      });

      return Promise.resolve(resultBranches.data)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.branchList = [];
    }
  }

  getBranchDropDown = async (branchDropdownRequestObject: BranchDropdownGetObject = {}, blnHeaderDropdown: boolean = false, blnExcludeCurrentBranch: boolean = false, proceedForOwner: boolean = false, blnFilterBranchAccess: boolean = true, skipSetVariable: boolean = false) => {
    try{
      if (!getBranchUser() || blnExcludeCurrentBranch || proceedForOwner) {
        const resultBranchesDropdownFinal = await agent.Branch.branchDropdown(branchDropdownRequestObject, getBranchUser() ? false : blnFilterBranchAccess);
        let resultBranchesDropdown = resultBranchesDropdownFinal.branches;
        runInAction(() => {
          if (!branchDropdownRequestObject.businessEntityId) {
            this.isEligibleAllBranch = resultBranchesDropdownFinal.isEligibleAllBranch;
          }
          if (!blnHeaderDropdown) {
            // if (blnExcludeCurrentBranch && getBranchUser()) {
            if (blnExcludeCurrentBranch) {
              let indexCurrentBranch = _.findIndex(resultBranchesDropdown, {id: getBranchId()} )
              if (indexCurrentBranch > -1) {
                resultBranchesDropdown.splice(indexCurrentBranch, 1);
              }
            }
            if (!skipSetVariable) {
              this.branchDropDownList = resultBranchesDropdown;
            }
          }
          else {
            this.branchForHeaderDropDown = resultBranchesDropdown;
            if (this.branchForHeaderDropDown.length > 0) {
              if (!window.localStorage.getItem('branchId') && !window.localStorage.getItem('branchName')) {
                window.localStorage.setItem("branchId", this.branchForHeaderDropDown[0].id);
                window.localStorage.setItem("branchName", this.branchForHeaderDropDown[0].name);
              }
            }
          }
        });

        return Promise.resolve(resultBranchesDropdown) 
      }
      else {
        let branchDropdownForBranchUser = [{
          id: getBranchId(),
          isDelete: false,
          name: getBranchName(),
          businessEntityName: "",
          branchCode: "",
          prefix: "",
          contactNumber: "",
          email: "",
          lastAppointmentSlot: "",
          displayOrder: 0,
        }]
        if (!skipSetVariable) {
          this.branchDropDownList = branchDropdownForBranchUser;
        }
        return Promise.resolve(branchDropdownForBranchUser)
      }
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)

      if (!blnHeaderDropdown) {
        this.branchDropDownList = [];
      }
      else {
        this.branchForHeaderDropDown = [];
      }
    }
  }

  getBranchDropDownWithoutValidation = async () => {
    try{
       const resultBranchesDropdownFinal = await agent.Branch.branchDropdown({}, false);
       let resultBranchesDropdown = resultBranchesDropdownFinal.branches
       return Promise.resolve(resultBranchesDropdown);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }    
  }

  getBranchWithId = async (id: string) => {
    try{
      const resultBranchDetail = await agent.Branch.branchDetail(id);
      runInAction(() => {
        this.branchDetail = resultBranchDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.branchDetail = undefined;
    }
  }

  addBranch = async (branchRequestBody: BranchAddObject) => {
    try{
      await agent.Branch.addBranch(branchRequestBody);
      store.commonStore.setSuccessMessage(`BranchAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateBranch = async (branchRequestBody: BranchUpdateObject) => {
    try{
      await agent.Branch.updateBranch(branchRequestBody);
      store.commonStore.setSuccessMessage(`BranchUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteBranch  = async (id: string, name: string) => {
    try {
      await agent.Branch.deleteBranch(id);
      //{intl.formatMessage({ id: "DeleteSuccessWithModuleName" }, { moduleName: intl.formatMessage({ id: `${name}` }) })}
      // store.commonStore.setSuccessMessage(`${name} (Branch) has deleted successfully.`)
      store.commonStore.setSuccessMessage(`BranchDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  getBranchBusinessHour = async (branchId: string) => {
    try{
      const resultBranchBusinessHour = await agent.Branch.branchBusinessHour(branchId);
      runInAction(() => {
        this.branchBusinessHour = resultBranchBusinessHour
      });
      return Promise.resolve(resultBranchBusinessHour);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.branchBusinessHour = [];
    }
  }

  getBranchPaymentMethod = async (id: string, blnExcludedTransferCreditPayment: boolean = false) => {
    try{
      let resultBranchPaymentMethod = await agent.Branch.branchPaymentMethod(id);
      if(blnExcludedTransferCreditPayment){
        resultBranchPaymentMethod = _.filter(resultBranchPaymentMethod, {isTransferCredit: false});
      }
      runInAction(() => {
        this.branchPaymentMethod = resultBranchPaymentMethod
      });
      return Promise.resolve(resultBranchPaymentMethod);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.branchPaymentMethod = [];
    }
  }

  getBranchProductList = async (id: string) => {
    try{
      const resultBranchProductList = await agent.Branch.branchProductList(id);
      runInAction(() => {
        this.branchProductList = resultBranchProductList
      });
      return Promise.resolve(resultBranchProductList);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.branchProductList = [];
    }
  }

  getBranchProductBatch = async (branchProductBatchRequestBody: BranchProductBatchGetObject) => {
    try{
      const resultBranchProductBatch = await agent.Branch.branchProductBatch(branchProductBatchRequestBody);
      return Promise.resolve(resultBranchProductBatch);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  getBranchProductBatchLegacy = async (branchProductBatchLegacyRequestBody: BranchProductBatchGetObject) => {
    try{
      const resultBranchProductBatchLegacy = await agent.Branch.branchProductBatchLegacy(branchProductBatchLegacyRequestBody);
      return Promise.resolve(resultBranchProductBatchLegacy);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  getStockTakeBranchProductList = async (stockTakeBranchProductRequestBody: StockTakeBranchProductsGetObject) => {
    try{
      const resultStockTakeBranchProductList = await agent.Branch.stockTakeBranchProductList(stockTakeBranchProductRequestBody);
      return Promise.resolve({status: Constants.success, data: resultStockTakeBranchProductList});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed, data: []})
    }
  }
}
