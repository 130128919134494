import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  AddOptionsValueField,
  checkPermission,
  contructValidationErrorMessage,
  IncludesLocationPathName,
  returnBlobImage,
  returnExpiredDate,
} from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Form, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import TableWithEditableInputFields from "../../app/components/table/TableWithEditableInputFields";
import SelectDropDownModal from "../../app/components/modal/SelectDropDownModal";
import { GeneralAttachmentListInterface, ObjectForTableWithDynamicInputAndCheckBox } from "../../app/models/common";
import MyButton from "../../app/components/form/MyButton";
import classNames from "classnames";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { NegativeStockLevelProduct, StockAdjustmentDetailInsideObject } from "../../app/models/stockAdjustment";
import { ProductForBranchObject } from "../../app/models/branch";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";
import moment from "moment";
import { useLocation } from "react-router-dom";
import NegativeStockLevelModal from "./Modal/NegativeStockLevelModal";
import GeneralAttachment from "../../app/components/form/GeneralAttachment";

const StockAdjustmentDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { stockAdjustmentStore, branchStore, commonStore, } = useStore();
  const { errorMessage, successMessage, loading, setErrorMessage, setSuccessMessage, setLoading, } = commonStore;
  const { addStockAdjustment, updateStockAdjustment, stockAdjustmentDetail, setStockAdjustmentDetail, getStockAdjustmentWithId, getStockAdjustmentPreload, stockAdjustmentPreloadDetail, setStockAdjustmentPreloadDetail } = stockAdjustmentStore;
  const { branchDropDownList, getBranchDropDown, branchProductList, getBranchProductList } = branchStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "StockAdjustment" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "StockAdjustment" }), urlPath: RoutesList.stockAdjustment }];
  const [activeTab, setActiveTab] = useState(1);
  const [productStockAdjustmentList, setProductStockAdjustmentList] = useState<StockAdjustmentDetailInsideObject[]>([]);
  const [productModal, setProductModal] = useState(false);
  const [loadingBranch, setLoadingBranch] = useState(false);
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const [isRecreateStockAdjustment, setIsRecreateStockAdjustment] = useState(false);
  const [blnNegativeStockLevelModal, setBlnNegativeStockLevelModal] = useState(false);
  const [negativeStockLevelProducts, setNegativeStockLevelProducts] = useState<NegativeStockLevelProduct[]>([]);
  const [localStockTakeId, setLocalStockTakeId] = useState("");
  const paramsSearch = useLocation().search;
  const [aryDynamicInputAndCheckBoxTab, setAryDynamicInputAndCheckBoxTab] = useState<ObjectForTableWithDynamicInputAndCheckBox[]>(
  [
    {
      label: intl.formatMessage({ id: "ProductName" }).toUpperCase(),
      type: "label",
      xl: "5",
      inputName: "productName",
      inputType: "text",
    },
    // {
    //   label: intl.formatMessage({ id: "SupplierReferenceNo" }).toUpperCase(),
    //   type: "input",
    //   xl: "2",
    //   inputName: "supplierReferenceNo",
    //   inputType: "text",
    //   validationRequired: true
    // },
    // {
    //   label: intl.formatMessage({ id: "CostPrice" }).toUpperCase(),
    //   type: "input",
    //   xl: "2",
    //   inputName: "costPrice",
    //   inputType: "number",
    //   inputGroup: true,
    //   inputGroupText: `${returnCurrency()} `,
    //   validationRequired: true
    // },
    {
      label: intl.formatMessage({ id: "AdjustQuantity" }).toUpperCase(),
      type: "input",
      xl: "3",
      inputName: "adjustQuantity",
      inputType: "number",
      validationRequired: true
    },
    // {
    //   label: intl.formatMessage({ id: "Quantity" }).toUpperCase(),
    //   type: "input",
    //   xl: "1.57",
    //   inputName: "quantity",
    //   inputType: "number",
    //   disabled: true,
    // },
    // {
    //   label: intl.formatMessage({ id: "ExpiryDate" }).toUpperCase(),
    //   type: "input",
    //   xl: "2",
    //   inputName: "expiryDate",
    //   inputType: "date",
    // },
    {
      label: intl.formatMessage({ id: "Remarks" }).toUpperCase(),
      type: "input",
      xl: "4",
      inputName: "remark",
      inputType: "text",
    },
  ]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: stockAdjustmentDetail || {
      branchId: "",
      branchName: "",
      note: "",
      workflowAction: "",
      stockAdjustmentDetails: [],
      supportingDocumentFileIds: [],
      supportingDocumentAttachments: [],
      aryAttachments: [],
      status: "",
      reviewRemark: "",
      stockTakeId: ""
    },
    validationSchema: Yup.object({
      // requestType: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "RequestType" }) })),
      // fromBranchId: Yup.string().test(
      //   "fromBranchId",
      //   intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) }),
      //   (value: any, context) => {
      //     if (!blnHQRequestType) {
      //       return value;
      //     }
      //     return true;
      //   }
      // ).nullable(),
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      try {
        if (stockAdjustmentDetailsValidation()) {
          return;
        }
        
        let supportingDocumentsTemp: any = [];
        let supportingDocumentFileIdsTemp: any = [];
        await Promise.all(valuesTemp.aryAttachments.map(async (valueAttachmentTemp) => {
          if (valueAttachmentTemp.preview !== "") {
            let fileTemp: any = await returnBlobImage(valueAttachmentTemp.preview);
            let fileWithFileName = new File([fileTemp], valueAttachmentTemp.path);
            supportingDocumentsTemp.push(fileWithFileName);
          }
          if (valueAttachmentTemp.id) {
            supportingDocumentFileIdsTemp.push(valueAttachmentTemp.id);
          }
        }))
        delete valuesTemp["aryAttachments"];
        valuesTemp.supportingDocuments = supportingDocumentsTemp;
        valuesTemp.supportingDocumentFileIds = supportingDocumentFileIdsTemp;
        
        if (localStockTakeId) {
          valuesTemp.stockTakeId = localStockTakeId;
        }

        let resultStockAdjustmentAPI : any = null;
        if (addAction) {
          if(isRecreateStockAdjustment){
            let valuesRecreateStockAdjustment ={
              branchId: valuesTemp.branchId,
              note: valuesTemp.note,
              workflowAction: valuesTemp.workflowAction,
              stockAdjustmentDetails: valuesTemp.stockAdjustmentDetails
            }
            resultStockAdjustmentAPI = await addStockAdjustment(valuesRecreateStockAdjustment);
          } 
          else{
            resultStockAdjustmentAPI = await addStockAdjustment(valuesTemp);
          }
        } else {
          resultStockAdjustmentAPI = await updateStockAdjustment(valuesTemp);
        }
        if (resultStockAdjustmentAPI) {
          if (resultStockAdjustmentAPI.length > 0) {
            setNegativeStockLevelProducts(resultStockAdjustmentAPI);
            setBlnNegativeStockLevelModal(true);
          }
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage) || localStockTakeId !== "";
  const blnStatusProcessing = (validation.values.status === Constants.processing);
  const blnTab1 = activeTab === 1;
  const blnTab2 = activeTab === 2;
  const blnPermissionUpdateStockAdjustment = checkPermission([PermissionConstants.UpdateStockAdjustment])

  useEffect(() => {
    async function fetchStockAdjustmentDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
      ];
      const stockTakeIdParams = new URLSearchParams(paramsSearch).get('stockTakeId');
      const recreateStockAdjustmentIdParams = new URLSearchParams(paramsSearch).get('recreateStockAdjustmentId');
      if (stockTakeIdParams) {
        aryAPI.push(getStockAdjustmentPreload(stockTakeIdParams));
        setLocalStockTakeId(stockTakeIdParams);
        history.replace({ pathname: `/${RoutesList.stockAdjustment}/add` });
      }
      else if(recreateStockAdjustmentIdParams){
        aryAPI.push(getStockAdjustmentWithId(recreateStockAdjustmentIdParams));
        setIsRecreateStockAdjustment(true);
        history.replace({ pathname: `/${RoutesList.stockAdjustment}/add` });
      }

      if (id && !addAction) {
        aryAPI.push(getStockAdjustmentWithId(id));
      }

      let aryAPIResult = await Promise.all(aryAPI);
      if (aryAPIResult.length > 1) {
        if (aryAPIResult[1]) {
          await getBranchProductList(aryAPIResult[1].branchId);

          if (aryAPIResult[1].status === Constants.reviseRequired) {
            let aryDynamicInputAndCheckBoxTabTemp = _.cloneDeep(aryDynamicInputAndCheckBoxTab);
            aryDynamicInputAndCheckBoxTabTemp.push({
              label: intl.formatMessage({ id: "ItemReview" }).toUpperCase(),
              type: "input",
              xl: "3",
              inputName: "reviewRemark",
              inputType: "text",
              disabled: true
            })

            aryDynamicInputAndCheckBoxTabTemp = aryDynamicInputAndCheckBoxTabTemp.map((valueDynamicInputAndCheckBoxTable) => ({
              ...valueDynamicInputAndCheckBoxTable,
              xl: "3"
            }))
            setAryDynamicInputAndCheckBoxTab(aryDynamicInputAndCheckBoxTabTemp);
          }

          if (aryAPIResult[1].supportingDocumentAttachments) {
            let aryAttachmentsTemp : GeneralAttachmentListInterface[]  = [];
            aryAPIResult[1].supportingDocumentAttachments.map((valueSupportingDocumentAttachmentTemp)=> {
              aryAttachmentsTemp.push({
                id: valueSupportingDocumentAttachmentTemp.attachmentFileId,
                preview: "",
                path: valueSupportingDocumentAttachmentTemp.attachmentFileName,
                webUrl: valueSupportingDocumentAttachmentTemp.attachmentWebUrl,
              })
            })
            validation.setFieldValue("aryAttachments", aryAttachmentsTemp);
          }
          else {
            validation.setFieldValue("aryAttachments", []);
          }

          if (addAction && aryAPIResult[1].stockTakeNo) {
            validation.setFieldValue("note", intl.formatMessage({ id: "StockAdjustmentAutoGeneratedMessage"}, { stockTakeNo: aryAPIResult[1].stockTakeNo}))            
          }
        }
      } 

      setLoading(false);
    }

    if (addAction) {
      setStockAdjustmentDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateStockAdjustment], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateStockAdjustment], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageStockAdjustment], true)) {
        return;
      }
    }

    if(viewAction && blnPermissionUpdateStockAdjustment){
      burgerMenuListContent.push({ label: `${intl.formatMessage({id: "EditAction"})}`, onFunction: () => { history.push(`/${RoutesList.stockAdjustment}/edit/${id}`)} })
    }

    fetchStockAdjustmentDetailAPI();

    return (() => {
      if (id && !addAction) {
        setStockAdjustmentDetail(undefined);
      }
      setStockAdjustmentPreloadDetail(undefined);
    })
  }, []);

  useEffect(() => {
    if ((id && !addAction) || isRecreateStockAdjustment) {
      let stockAdjustmentDetailTemp : StockAdjustmentDetailInsideObject[] = [];
      let productStockAdjustmentList: StockAdjustmentDetailInsideObject[] = branchProductList.map((value: ProductForBranchObject) => {
        let productIdFound = false;
        stockAdjustmentDetail?.stockAdjustmentDetails.map((valueStockAdjustmentDetail) => {
          if (valueStockAdjustmentDetail.productId === value.productId) {
            productIdFound = true;
            stockAdjustmentDetailTemp.push({
              ...valueStockAdjustmentDetail, 
              id: isRecreateStockAdjustment ? undefined : valueStockAdjustmentDetail.id,
              quantity: value.quantity,
              expiryDate: moment(valueStockAdjustmentDetail.expiryDate).format(Constants.displayDateFormat)
            })
          }
        })
        return ({
          id: undefined,
          productId: value.productId,
          productName: value.productName,
          quantity: value.quantity,
          adjustQuantity: 0,
          costPrice: 0,
          remark: "",
          supplierReferenceNo: "",
          expiryDate: returnExpiredDate(Constants.textInputDateFormat),
          disabled: productIdFound
        })
      });
      setProductStockAdjustmentList(productStockAdjustmentList);
      if (stockAdjustmentDetailTemp.length > 0) {
        validation.setFieldValue("stockAdjustmentDetails", stockAdjustmentDetailTemp)
      }
    }
  }, [branchProductList, stockAdjustmentDetail])

  useEffect(() => {
    if (stockAdjustmentPreloadDetail) {
      validation.setFieldValue("branchId", stockAdjustmentPreloadDetail.branchId)
      validation.setFieldValue("branchName", stockAdjustmentPreloadDetail.branchName)
      let stockAdjustmentDetailsTemp = _.cloneDeep(stockAdjustmentPreloadDetail.stockAdjustmentDetails);
      stockAdjustmentDetailsTemp = stockAdjustmentDetailsTemp.map((valueStockAdjustmentDetailsTemp)=> ({
        ...valueStockAdjustmentDetailsTemp,
        expiryDate: returnExpiredDate(Constants.textInputDateFormat),
      }))
      validation.setFieldValue("stockAdjustmentDetails", stockAdjustmentDetailsTemp)
    }
  }, [stockAdjustmentPreloadDetail])

  const onChangeBranch = async (value, removeData: boolean = true) => {
    setLoadingBranch(true);
    let productWithBranchIdTemp = await getBranchProductList(value) || [];
    setLoadingBranch(false);
    let productStockAdjustmentList : StockAdjustmentDetailInsideObject[] = [];
    productWithBranchIdTemp.map((valueProductList) => 
    {
      productStockAdjustmentList.push({
        id: undefined,
        productId: valueProductList.productId,
        productName: valueProductList.productName,
        quantity: valueProductList.quantity,
        adjustQuantity: 0,
        costPrice: 0,
        remark: "",
        supplierReferenceNo: "",
        expiryDate: returnExpiredDate(Constants.textInputDateFormat),
        disabled: false
      })
    })
    setProductStockAdjustmentList(productStockAdjustmentList);
    if (removeData) {
      validation.setFieldValue("stockAdjustmentDetails", []);
    }
  }

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])
  
  const stockAdjustmentDetailsValidation = () => {
    let blnEmptyStockAdjustmentDetails = false;
    let blnSupplierReferenceNo = false;
    let blnCostPrice = false;
    let blnAdjustQuantity = false;

    if (validation.values.stockAdjustmentDetails.length < 1) {
      blnEmptyStockAdjustmentDetails = true;
    }
    else {
      validation.values.stockAdjustmentDetails.map((valueStockAdjustmentDetailsTemp) => {
        // if (!valueStockAdjustmentDetailsTemp.supplierReferenceNo) {
        //   blnSupplierReferenceNo = true;
        // }
        // if (!valueStockAdjustmentDetailsTemp.costPrice) {
        //   blnCostPrice = true;
        // }
        // if (!valueStockAdjustmentDetailsTemp.adjustQuantity) {
        //   blnAdjustQuantity = true;
        // }
      })
    }

    if (blnEmptyStockAdjustmentDetails) {
      setErrorMessage("Please select at least one products")
    }
    else if (blnSupplierReferenceNo) {
      setErrorMessage("Please enter Supplier Reference No")
    }
    else if (blnCostPrice) {
      setErrorMessage("Please enter Cost Price")
    }
    else if (blnAdjustQuantity) {
      setErrorMessage("Please enter Adjust Quantity")
    }

    return blnEmptyStockAdjustmentDetails || blnSupplierReferenceNo || blnCostPrice || blnAdjustQuantity;
  }

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 2) {
        setActiveTab(tab)
      }
    }
  }

  const nextButtonDisable = () => {
    if (!validation.values.branchId) {
      return true;
    }
    return false;
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <div className="wizard clearfix">
              <div className="steps clearfix">
                <ul>
                  <NavItem
                    className={classNames({ current: blnTab1 })}
                  >
                    <NavLink
                      className={classNames({ current: blnTab1 })}
                      onClick={() => {
                        setActiveTab(1)
                      }}
                      disabled={true}
                    >
                      <span className="number">1.</span> {intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "StockAdjustment" }) })}
                    </NavLink>
                  </NavItem>
                  <NavItem
                    className={classNames({ current: blnTab2 })}
                  >
                    <NavLink
                      className={classNames({ active: blnTab2 })}
                      onClick={() => {
                        setActiveTab(2)
                      }}
                      disabled={true}
                    >
                      <span className="number">2.</span> {intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Products" }) })}
                    </NavLink>
                  </NavItem>
                </ul>
              </div>
              <div className="content clearfix">
                <TabContent
                  activeTab={activeTab}
                  className="body"
                >
                  <TabPane tabId={1}>
                    <Row form={+true}>
                      {" "}
                      <Col xl={12}>
                        <LineBreakWithTittle
                          paddingBottom="0px"
                          title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "StockAdjustment" }) })}
                          h4Title
                        />
                        <SingleColumnRowParent>
                          <DropDownWithTitle
                            name={"branchId"}
                            title={intl.formatMessage({ id: "Branch" })}
                            className="mb-3 mt-4"
                            specifyReturnFieldName={[
                              {
                                field: "branchId",
                                value: "id",
                              },
                              {
                                field: "branchName",
                                value: "name",
                              },
                            ]}
                            labelField={"name"}
                            valueField={"id"}
                            options={branchDropDownList}
                            loading={loadingBranch}
                            disabled={disabledFieldInput || viewAction}
                            onChangeFunction={onChangeBranch}
                            initialValue={(!addAction || stockAdjustmentPreloadDetail || isRecreateStockAdjustment) ? validation.values.branchId : undefined}
                            initialLabel={(!addAction || stockAdjustmentPreloadDetail || isRecreateStockAdjustment) ? validation.values.branchName : undefined}
                            validationRequired={true}
                            validation={validation}
                          />
                        </SingleColumnRowParent>
                        <SingleColumnRowParent>
                          <GeneralTextArea
                            title={intl.formatMessage({ id: "Note" })}
                            name="note"
                            row={5}
                            disabled={disabledFieldInput || viewAction}
                            validation={validation}
                          />
                        </SingleColumnRowParent>
                        <SingleColumnRowParent>
                          <GeneralAttachment
                            title={intl.formatMessage({ id: "SupportingDocuments" })}
                            name="aryAttachments"
                            validation={validation}
                            disabled={disabledFieldInput || viewAction}
                            blnMultipleFile={true}/>
                        </SingleColumnRowParent>
                        {
                          validation.values.reviewRemark
                          &&
                          <SingleColumnRowParent>
                            <GeneralTextArea
                              title={intl.formatMessage({ id: "Review" })}
                              name="reviewRemark"
                              row={5}
                              disabled={true}
                              validation={validation}
                            />
                          </SingleColumnRowParent>
                        }
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId={2}>
                    <Col>
                      <LineBreakWithTittle
                        paddingBottom="0px"
                        title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Products" }) })}
                        h4Title
                      />
                      <TableWithEditableInputFields
                        name="stockAdjustmentDetails"
                        title=""
                        buttonTitle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Product" }) })}
                        blnEditable={!viewAction}
                        blnSupportWithModal={true}
                        blnBtnModalBottom={true}
                        options={productStockAdjustmentList}
                        optionsId={"productId"}
                        setOptionsFunction={setProductStockAdjustmentList}
                        addButtonFunction={() => setProductModal(true)}
                        aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTab}
                        data={validation.values.stockAdjustmentDetails}
                        validation={validation}
                        disabled={disabledFieldInput} />
                    </Col>
                  </TabPane>
                </TabContent>
              </div>
              <div className="actions clearfix">
                <ul>
                  <li>
                    {blnTab2 && !Boolean(successMessage) &&
                      <MyButton
                        type="button"
                        class="btn btn-primary"
                        style={{ width: '100%' }}
                        content={intl.formatMessage({ id: "Previous" })}
                        disable={
                          nextButtonDisable()
                        }
                        loading={
                          validation.isSubmitting
                        }
                        onClick={() => {
                          toggleTab(activeTab - 1)
                        }}
                      />
                    }
                  </li>
                  <li>
                    {blnTab1 &&
                      <MyButton
                        type="button"
                        class="btn btn-primary"
                        style={{ width: '100%' }}
                        content={intl.formatMessage({ id: "Next" })}
                        disable={
                          nextButtonDisable()
                        }
                        loading={
                          validation.isSubmitting || loadingBranch
                        }
                        onClick={() => {
                          toggleTab(activeTab + 1)
                        }}
                      />
                    }
                  </li>
                </ul>
              </div>

            </div>
            <div className="mt-3" />
            <GeneralSubmitAndCancelBtn
              successMessage={successMessage}
              viewAction={viewAction}
              validation={validation}
              dirtyDisabled={true}
              showExtraLeftButton={!localStockTakeId}
              extraLeftButtonTitle={intl.formatMessage({ id: "Save" })}
              extraLeftButtonColor="btn-primary"
              extraLeftButtonFunction={() => {
                validation.setFieldValue("workflowAction", 'Save')
              }}
              extraLeftDirtyDisabled={isRecreateStockAdjustment}
              hidePrimaryButton={blnStatusProcessing || blnTab1}
              submitButtonTitle={intl.formatMessage({ id: "Submit" })}
              onClickFunction={() => {
                validation.setFieldValue("workflowAction", 'Submit')
              }}
            />
          </Form>
        )}
        <SelectDropDownModal
          blnShow={productModal}
          setModal={setProductModal}
          title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "Product" }) })}
          buttonTittle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Product" }) })}
          placeholder={"- Please Select -"}
          labelField={"productName"}
          valueField={"productId"}
          options={AddOptionsValueField(productStockAdjustmentList, "name", "id")}
          setOptionsFunction={setProductStockAdjustmentList}
          fieldInput={validation.values.stockAdjustmentDetails}
          fieldName={"stockAdjustmentDetails"}
          validation={validation} />
        {
          blnNegativeStockLevelModal
          &&
          <NegativeStockLevelModal 
            blnShow={blnNegativeStockLevelModal}
            setModal={setBlnNegativeStockLevelModal}
            negativeStockLevelProducts={negativeStockLevelProducts}/>
        }
      </DetailViewLayout>
    </div >
  );
};

export default observer(StockAdjustmentDetail);