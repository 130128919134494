import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { checkPermission, getBranchId, getBranchName, getBranchUser, newSpaceBeforeCapitalLetter, returnSwitchTabButtonUI, stockLevelStatusColor } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import Loading from "../../../app/components/loading/Loading";
import _ from "lodash";
import MyButton from "../../../app/components/form/MyButton";
import { StaticReferenceDataObject } from "../../../app/models/staticReference";
import GeneralStatus from "../../../app/components/form/GeneralStatus";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../../app/components/form/GeneralInput";
import DropDownWithTitleMultiSelect from "../../../app/components/dropdown/DropDownWithTitleMultiSelect";

const StockLevel = () => {
  const intl = useIntl();
  //Use Store
  const { reportStockStore, branchStore, staticReferenceStore, categoryStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { categoryDropdownList, getCategoryDropdown } = categoryStore;
  const { stockLevelDetailsList, getStockLevelDetailsList, setStockLevelDetailsList, exportStockLevel } = reportStockStore;
  const { branchDropDownList, getBranchDropDown } = branchStore;
  const { productType, getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() ? getBranchId() : "");
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() ? getBranchName() : "");
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedStockLevelStatus, setSelectedStockLevelStatus] = useState<number | string>(AllStatus);
  const [selectedStockLevelStatusId, setSelectedStockLevelStatusId] = useState<number | string | undefined>(" ");
  const [stockLevelStatusList, setStockLevelStatusList] = useState<StaticReferenceDataObject[]>([])
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [blnIsActive, setBlnIsActive] = useState(false);
  const [blnAllActive, setBlnAllActive] = useState(true);
  const blnPermissionExportInventoryReport = checkPermission([PermissionConstants.ExportInventoryReport]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: "",
      sKU: "",
      categoryIds: [],
      productTypes: []
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  useEffect(() => {
    async function fetchStockLevel() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
        getStaticReferenceWithType(Constants.stockLevelStatus, AllStatus),
        getCategoryDropdown(),
        getStaticReferenceWithType(Constants.productType)
      ];

      let resultAPI = await Promise.all(aryAPI);
      setStockLevelStatusList(resultAPI[1]);

      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageInventoryReport], true)) {
      return;
    }

    fetchStockLevel();

    return (() => {
      setStockLevelDetailsList([]);
    })
  }, [])

  const fetchStockLevelWithLoading = async (blnExport: boolean) => {
    if (!selectedBranchId) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) }))
      return;
    }

    setLocalLoading(true);
    if (blnExport) {
      await exportStockLevel({ 
        branchId:  selectedBranchId,
        ...(selectedStockLevelStatusId !== " ") && { status: selectedStockLevelStatusId },
        name: validation.values.name,
        sKU: validation.values.sKU,
        categoryIds: validation.values.categoryIds,
        productTypes: validation.values.productTypes,
        ...!blnAllActive && {isActive: blnIsActive} 
      });
    }
    else {
      setCurrentPage(1);
      await getStockLevelDetailsList({ 
        pageNumber: 1, 
        pageSize: pageSize, 
        branchId: selectedBranchId, 
        name: encodeURIComponent(validation.values.name),
        sKU: encodeURIComponent(validation.values.sKU),
        categoryIds: validation.values.categoryIds,
        productTypes: validation.values.productTypes,
        ...!blnAllActive && {isActive: blnIsActive},
        ...(selectedStockLevelStatusId !== " ") && { status: selectedStockLevelStatusId } });
    }
    setLocalLoading(false);
  };

  const blnViewAllFunction = () => {
    setBlnAllActive(true);
  }

  const blnActiveOnlyFunction = () => {
    setBlnIsActive(true);
    setBlnAllActive(false);
  }

  const blnInactiveOnlyFunction = () => {
    setBlnIsActive(false);
    setBlnAllActive(false);
  }

  //Table Content UI
  const StockLevelListColumns = [
    {
      dataField: "productId", //field name from array to display
      text: "productId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
    },
    {
      dataField: "productName",
      text: intl.formatMessage({ id: "Product" }).toUpperCase(),
    },
    {
      dataField: "sku",
      text: intl.formatMessage({ id: "SKU" }).toUpperCase(),
    },
    {
      dataField: "categoryName",
      text: intl.formatMessage({ id: "Category" }).toUpperCase(),
    },
    {
      dataField: "productTypes",
      text: intl.formatMessage({ id: "Type" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{newSpaceBeforeCapitalLetter(row.productTypes)}</div>
      ),
    },
    {
      dataField: "currentQuantity",
      text: intl.formatMessage({ id: "OnHandQuantity" }).toUpperCase(),

    },
    {
      dataField: "minimumThreshold",
      text: intl.formatMessage({ id: "MinimumThreshold" }).toUpperCase(),
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={stockLevelStatusColor} />
      ),
    },
    {
      dataField: "isActive",
      text: intl.formatMessage({ id: "Active" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isActive ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    }
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "StockLevel" }) })}
        title={intl.formatMessage({ id: "StockLevel" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportInventoryReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchStockLevelWithLoading(true)
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Stock-Level.aspx`}>
        <Row>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchId"
              options={branchDropDownList}
              labelField={"name"}
              valueField={"id"}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
              validationRequired={true}
            />
          </Col>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "StockLevel" }) })}
              name="stockLevelStatus"
              options={stockLevelStatusList}
              blnValueWithNewSpace={true}
              labelField="displayValue"
              valueField="key"
              initialLabel={selectedStockLevelStatus}
              initialValue={selectedStockLevelStatusId}
              setFieldLabel={setSelectedStockLevelStatus}
              setFieldValue={setSelectedStockLevelStatusId}
              disabled={localLoading || loading}
              validationRequired={true}
            />
          </Col>
          <Col xl="3">
            <DropDownWithTitleMultiSelect
              name="categoryIds"
              title={intl.formatMessage({ id: "Category" })}
              specifyReturnFieldName={[{ "field": "categoryIds", "value": "id" }]}
              returnFieldWithLabel={false}
              labelField={"name"}
              valueField={"id"}
              options={categoryDropdownList}
              disabled={localLoading || loading}
              validationRequired={false}
              validation={validation}
            />
          </Col>
          <Col xl="3">
            <DropDownWithTitleMultiSelect
              name="productTypes"
              title={intl.formatMessage({ id: "ProductType" })}
              specifyReturnFieldName={[{ "field": "productTypes", "value": "key" }]}
              returnFieldWithLabel={false}
              labelField={"displayValue"}
              valueField={"displayValue"}
              blnValueWithNewSpace={true}
              options={productType}
              disabled={localLoading || loading}
              validationRequired={false}
              validation={validation}
            />
          </Col>
        </Row>
        <Row>
        <Col xl="3">
              <GeneralInput
                title={intl.formatMessage({ id: "Name" })}
                name="name"
                type="text"
                disabled={localLoading || loading}
                validation={validation}
                placeholder={intl.formatMessage({ id: "EnterProductName" })}
              />
            </Col>
            <Col xl="3">
              <GeneralInput
                title={intl.formatMessage({ id: "SKU" })}
                name="sKU"
                type="text"
                disabled={localLoading || loading}
                validation={validation}
                placeholder={intl.formatMessage({ id: "EnterSKU" })}
              />
            </Col>
            <Col xl={'4'} md={'6'} xs={'12'} className={`${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28": ""} ${windowSize.innerWidth > Constants.innerWidthCompare770 ? "" : "margin-bottom-4"}`}>           
              <div className="btn-group" role="group">
                {returnSwitchTabButtonUI("blnViewAll", "blnViewAll", blnAllActive, loading || localLoading, intl.formatMessage({ id: "All" }), blnViewAllFunction, 1790 > windowSize.innerWidth && windowSize.innerWidth > 1200 ? "70px" : "")}
                {returnSwitchTabButtonUI("blnIsActive", "blnIsActiveTrue", blnIsActive && !blnAllActive, loading || localLoading, intl.formatMessage({ id: "Active" }), blnActiveOnlyFunction, 1790 > windowSize.innerWidth && windowSize.innerWidth > 1200 ? "70px" : "")}
                {returnSwitchTabButtonUI("blnIsActive", "blnIsActiveFalse", !blnIsActive && !blnAllActive, loading || localLoading, intl.formatMessage({ id: "Inactive" }), blnInactiveOnlyFunction, 1790 > windowSize.innerWidth && windowSize.innerWidth > 1200 ? "90px" : "")}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl="2">
              <MyButton
                type="button"
                class={`btn btn-primary margin-bottom-14 ${Constants.innerWidthCompare > windowSize.innerWidth ? "margin-top-14" : ""}`}
                content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                loading={localLoading || loading}
                disable={localLoading || loading}
                onClick={() => {
                  fetchStockLevelWithLoading(false)
                }}
              />
          </Col>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <>
              <PaginationTable
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                totalItem={totalItem}
                tableColumns={StockLevelListColumns}
                data={stockLevelDetailsList}
                emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "StockLevel" }) })}
                objSorted={defaultSorted}
                branchId={selectedBranchId}
                name={encodeURIComponent(validation.values.name)}
                sKU={encodeURIComponent(validation.values.sKU)}
                status={selectedStockLevelStatusId}
                categoryIds={validation.values.categoryIds}
                productTypes={validation.values.productTypes}
                isActive={!blnAllActive ? blnIsActive : undefined}
                keyField={"productId"}
                requestAPI={getStockLevelDetailsList}
                hideSearchBar={true} />
            </>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(StockLevel);
